import { convertToDate, isValidDate } from "@bankingright-dashboard/utils";
import { BoxProps, Heading, Text, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";

export enum DetailItemType {
    text = "text",
    date = "date",
}

export interface DetailItemProps extends BoxProps {
    title: string;
    value?: string | number | boolean;
    type?: DetailItemType;
    showTime?: boolean;
}

export const DetailItem: React.FC<DetailItemProps> = ({
    title,
    value,
    type = DetailItemType.text,
    showTime = false,
    ...props
}) => {
    var body = "";
    switch (type) {
        case DetailItemType.text:
            body = value?.toString() ?? "";
            break;
        case DetailItemType.date:
            if (!isValidDate(convertToDate(value as string))) {
                break;
            }
            body = format(
                convertToDate(value as string),
                showTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"
            );
            break;
    }

    return (
        <>
            {body && (
                <VStack align="flex-start" {...props}>
                    <Heading as="h5" size="sm">
                        {title}
                    </Heading>
                    <Text>{body}</Text>
                </VStack>
            )}
        </>
    );
};
