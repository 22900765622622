import {
    BaseKey,
    BaseRecord,
    MetaQuery,
    useDataProvider,
    useResource,
    userFriendlyResourceName,
} from "@refinedev/core";
import { generateCsv, ConfigOptions, download } from "export-to-csv";
import { useState } from "react";

type UseExportOneOptionsType<
    TData extends BaseRecord = BaseRecord,
    TVariables = any
> = {
    /**
     * Resource name for API data interactions
     * @default Resource name that it reads from route
     */
    resource?: string;
    /**
     * Id of the resource
     */
    id: BaseKey;
    /**
     * A mapping function that converts the raw data to an output for the export
     */
    convertData: (rawData: TData) => any[];
    /**
     *  Used for exporting options
     *  @type [Options](https://github.com/alexcaza/export-to-csv)
     */
    exportOptions?: ConfigOptions;
    /**
     *  Metadata query for `dataProvider`
     */
    meta?: MetaQuery;
    /**
     * If there is more than one `dataProvider`, you should use the `dataProviderName` that you will use.
     */
    dataProviderName?: string;
    /**
     *  Callback to handle error events of this hook
     */
    onError?: (error: any) => void;
};

type UseExportReturnType = {
    isLoading: boolean;
    triggerExport: () => Promise<void>;
};

/**
 * `useExport` hook allows you to make your resources exportable.
 *
 * @see {@link https://refine.dev/docs/core/hooks/import-export/useExport} for more details.
 *
 * @typeParam TData - Result data of the query extends {@link https://refine.dev/docs/api-references/interfaceReferences#baserecord `BaseRecord`}
 * @typeParam TVariables - Values for params.
 *
 */
export const useExportOne = <
    TData extends BaseRecord = BaseRecord,
    TVariables = any
>({
    resource: resourceFromProps,
    id,
    convertData = (item) => item as any,
    exportOptions,
    meta,
    dataProviderName,
    onError,
}: UseExportOneOptionsType<TData, TVariables>): UseExportReturnType => {
    const [isLoading, setIsLoading] = useState(false);

    const dataProvider = useDataProvider();

    const { resource } = useResource(resourceFromProps);

    const filename = `${userFriendlyResourceName(
        resource?.name,
        "plural"
    )}-${id}-${new Date().toISOString()}`;

    const { getOne } = dataProvider(dataProviderName);

    const triggerExport = async () => {
        setIsLoading(true);
        let rawData: TData;
        try {
            const { data } = await getOne<TData>({
                resource: resource?.name ?? "",
                id: id,
                meta: meta,
            });

            rawData = data;
        } catch (error) {
            setIsLoading(false);
            onError?.(error);
            return;
        }

        const config = {
            filename,
            useKeysAsHeaders: true,
            ...exportOptions,
        };
        
        const csv = generateCsv(config)(convertData(rawData));
        download(config)(csv);

        setIsLoading(false);
    };

    return {
        isLoading,
        triggerExport,
    };
};
