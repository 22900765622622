import { Box, Spinner, useColorModeValue } from "@chakra-ui/react";

interface LoadingIndicatorProps {
    isLoading: boolean
}

export const LoadingIndicator = ({isLoading}: LoadingIndicatorProps) => {

    return (
        <Box
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            position="absolute"
            display={isLoading ? "flex" : "none"}
            background={useColorModeValue("blackAlpha.200", "whiteAlpha.600")}
            rounded="lg"
            border="2px"
            borderColor={useColorModeValue("gray.200", "gray.100")}
            padding={3}
            alignItems="center"
            justifyContent="center"
        >
            <Spinner
                emptyColor={useColorModeValue("whiteAlpha.900", "gray.200")}
                color='blue.500'
                size="xl"
                thickness='4px'
                position="relative"
            />
        </Box>
    )
};