import {
    Badge,
    Button,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import {
    CrudFilters,
    useApiUrl,
    useCustomMutation,
    useNotification,
    useResource,
    useShow,
} from "@refinedev/core";

import { ResourceAction } from "@bankingright-dashboard/enums";
import {
    IDevice,
    IUser,
    ResetPasswordResponse,
} from "@bankingright-dashboard/interfaces";
import {
    PasswordInputFormControl,
    ResourceList,
} from "@bankingright-dashboard/table";
import { DetailItem, Show } from "@bankingright-dashboard/ui";
import { convertToDate } from "@bankingright-dashboard/utils";
import { UilRedo } from "@iconscout/react-unicons";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { useForm } from "react-hook-form";

type ResetPasswordFormValues = {
    password: string;
    repeatPassword: string;
};

export const UserShow: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { open } = useNotification();
    const { queryResult } = useShow<IUser>();
    const { data, isLoading } = queryResult;
    const user = data?.data;

    const { resource } = useResource();
    const apiUrl = useApiUrl();
    const url = `${apiUrl}/v1/${resource!.name}/${user?.id}/resetpassword`;

    const filters: CrudFilters = [
        {
            field: "user_id",
            operator: "eq",
            value: user?.id || "",
        },
    ];

    const { control, handleSubmit, reset } = useForm<ResetPasswordFormValues>({
        defaultValues: {
            password: "",
            repeatPassword: "",
        },
    });

    const { mutate, isLoading: isResettingPassword } =
        useCustomMutation<ResetPasswordResponse>();

    const onSubmit = async ({ password }: ResetPasswordFormValues) => {
        mutate(
            {
                url: url,
                method: "post",
                values: {
                    password: password,
                },
            },
            {
                onSuccess: () => {
                    open?.({
                        type: "success",
                        message: "Reset password successfully.",
                        description:
                            "The password of the user has been reset successfully.",
                    });
                    onCloseModal();
                },
            }
        );
    };

    const onCloseModal = () => {
        reset();
        onClose();
    };

    const columns = React.useMemo<ColumnDef<IDevice>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableSorting: false,
            },
            {
                id: "deviceName",
                header: "Device Name",
                accessorKey: "deviceName",
                enableSorting: false,
            },
            {
                id: "platform",
                header: "Platform",
                accessorKey: "platform",
                enableSorting: false,
            },
            {
                id: "dateModified",
                header: "Last used",
                accessorKey: "dateModified",
                enableSorting: false,
                cell: function render({ getValue }) {
                    return (
                        <HStack>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </HStack>
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <VStack align="stretch" spacing={5}>
                <Show isLoading={isLoading}>
                    <VStack align="flex-start" spacing={3}>
                        <DetailItem title="Id" value={user?.id} />
                        <DetailItem title="Username" value={user?.userName} />
                        <DetailItem title="Name" value={user?.fullName} />
                        <DetailItem
                            title="Status"
                            value={user?.status ?? "active"}
                        />
                        <DetailItem
                            title="Onboarding Status"
                            value={user?.onboardingStatus}
                        />
                        {user?.email && (
                            <VStack align="flex-start">
                                <Heading as="h5" size="sm">
                                    E-mail
                                </Heading>
                                <HStack spacing={3}>
                                    <Text>{user?.email}</Text>
                                    <Badge
                                        colorScheme={
                                            user?.emailVerified
                                                ? "green"
                                                : "red"
                                        }
                                    >
                                        {user?.emailVerified
                                            ? "verified"
                                            : "not verified"}
                                    </Badge>
                                </HStack>
                            </VStack>
                        )}
                        <VStack align="flex-start">
                            <Heading as="h5" size="sm">
                                Created at
                            </Heading>
                            <Text>
                                {convertToDate(
                                    user?.dateCreated
                                ).toLocaleDateString()}{" "}
                                {convertToDate(
                                    user?.dateCreated
                                ).toLocaleTimeString()}
                            </Text>
                        </VStack>

                        {user?.dateModified && (
                            <VStack align="flex-start">
                                <Heading as="h5" size="sm">
                                    Last modified at
                                </Heading>
                                <Text>
                                    {convertToDate(
                                        user?.dateModified
                                    ).toLocaleDateString()}{" "}
                                    {convertToDate(
                                        user?.dateModified
                                    ).toLocaleTimeString()}
                                </Text>
                            </VStack>
                        )}
                        <VStack align="flex-start">
                            <Heading as="h5" size="sm">
                                Actions
                            </Heading>
                            <VStack>
                                <Button
                                    variant="tertiary"
                                    leftIcon={<UilRedo size={18} />}
                                    onClick={onOpen}
                                    aria-label="Reset Password"
                                >
                                    Reset Password
                                </Button>
                            </VStack>
                        </VStack>
                    </VStack>
                </Show>
                <ResourceList
                    title="Linked Devices"
                    columns={columns}
                    actions={[ResourceAction.show, ResourceAction.delete]}
                    resourceName="devices"
                    refineCoreProps={{
                        permanentFilter: filters,
                        resource: "devices",
                        initialPageSize: 5,
                    }}
                    clearWhenLoading={true}
                    showPageSizeSelect={false}
                />
            </VStack>
            <Modal
                isOpen={isOpen}
                closeOnOverlayClick={false}
                onClose={onCloseModal}
            >
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalHeader>Reset Password</ModalHeader>
                        <ModalCloseButton isDisabled={isResettingPassword} />
                        <ModalBody>
                            <VStack align="flex-start" spacing={5}>
                                <Text>
                                    You are about to reset the password for user{" "}
                                    {user?.userName}. Please enter the new
                                    password:
                                </Text>
                                <PasswordInputFormControl
                                    control={control}
                                    name={"password"}
                                />
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant="primary"
                                mr={3}
                                type="submit"
                                isLoading={isResettingPassword}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="ghost"
                                onClick={onCloseModal}
                                isDisabled={isResettingPassword}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};
