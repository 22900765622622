import React, { useContext } from "react";
import {
    useCan,
    useNavigation,
    useTranslate,
    useResource,
    AccessControlContext,
} from "@refinedev/core";
import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface EditButtonProps extends ButtonProps {
    icon?: React.ReactElement;
    hideText?: boolean;
    recordItemId: number | string;
    resourceNameOrRouteName?: string;
    accessControl?: {
        enabled?: boolean;
        hideIfUnauthorized?: boolean;
    };
}

export const EditButton = ({
    icon = <FiEdit />,
    hideText = false,
    recordItemId,
    resourceNameOrRouteName,
    accessControl,
    children,
    ...props
}: EditButtonProps) => {
    const accessControlContext = useContext(AccessControlContext);

    const accessControlEnabled =
        accessControl?.enabled ??
        accessControlContext.options.buttons.enableAccessControl;

    const hideIfUnauthorized =
        accessControl?.hideIfUnauthorized ??
        accessControlContext.options.buttons.hideIfUnauthorized;

    const { id, resource } = useResource(resourceNameOrRouteName);
    const resourceId = id ?? recordItemId;

    const translate = useTranslate();
    const navigate = useNavigate();
    const { editUrl: generateEditUrl } = useNavigation();

    const { data } = useCan({
        resource: resource!.name,
        action: "edit",
        params: { resourceId, resource },
        queryOptions: {
            enabled: accessControlEnabled,
        },
    });

    const disabledTitle = () => {
        if (data?.can) return "";
        else if (data?.reason) return data.reason;
        else
            return translate(
                "buttons.notAccessTitle",
                "You don't have permission to access"
            );
    };

    const editUrl = generateEditUrl(resource!.name, resourceId);

    if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
        return null;
    }

    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        navigate(editUrl);
    };

    return (
        <>
            {hideText ? (
                <IconButton
                    variant="action"
                    aria-label={translate("buttons.edit", "Edit")}
                    title={disabledTitle()}
                    isDisabled={data?.can === false}
                    icon={icon}
                    onClick={onClick}
                    {...props}
                />
            ) : (
                <Button
                    variant="action"
                    isDisabled={data?.can === false}
                    leftIcon={icon}
                    title={disabledTitle()}
                    onClick={onClick}
                    {...props}
                >
                    {children ?? translate("buttons.edit", "Edit")}
                </Button>
            )}
        </>
    );
};
