import { IconButton } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import { useBack } from "@refinedev/core";

export const BackButton: React.FC = () => {
    const back = useBack();
    const hasHistory = window.history.length > 1;

    return (
        <>
            {hasHistory && (
                <IconButton
                    aria-label="back"
                    variant="ghost"
                    size="sm"
                    onClick={back}
                >
                    <FiArrowLeft size="25px" />
                </IconButton>
            )}
        </>
    );
};
