import { Image } from "@bankingright-dashboard/interfaces";
import { DetailItem, DetailItemType, Show } from "@bankingright-dashboard/ui";
import { formatBytes } from "@bankingright-dashboard/utils";
import { Box, Heading, VStack, Image as ChakraImage } from "@chakra-ui/react";
import { useShow, useTranslate } from "@refinedev/core";

export const ImageShow: React.FC = () => {
    const { queryResult } = useShow<Image>();
    const { data, isLoading } = queryResult;
    const image = data?.data;
    const translate = useTranslate();

    return (
        <VStack align="stretch" spacing={5}>
            <Show isLoading={isLoading}>
                <VStack align="flex-start" spacing={3}>
                    <DetailItem title="ID" value={image?.id} />
                    <DetailItem title="External ID" value={image?.externalId} />
                    <DetailItem title="Category" value={image?.category} />
                    <DetailItem title="Filename" value={image?.filename} />
                    <DetailItem title="Extension" value={image?.extension} />
                    {image?.imageDimensions && (
                        <DetailItem
                            title="Dimensions"
                            value={`${image.imageDimensions.width} x ${image.imageDimensions.height}`}
                        />
                    )}
                    <DetailItem title="Mime type" value={image?.mimeType} />
                    {image?.size && (
                        <DetailItem
                            title="Size"
                            value={formatBytes(image.size)}
                        />
                    )}
                    <DetailItem
                        title="Relative url"
                        value={image?.relativeUrl}
                    />
                    <DetailItem title="Direct url" value={image?.directUrl} />
                    <DetailItem title="Hash" value={image?.hash} />
                    <DetailItem
                        title="Dominant color"
                        value={image?.dominantColor}
                    />
                    <DetailItem
                        title="Date created"
                        value={image?.dateCreated}
                        type={DetailItemType.date}
                    />
                    <DetailItem
                        title="Date modified"
                        value={image?.dateModified}
                        type={DetailItemType.date}
                    />
                    <DetailItem
                        title="Meta data"
                        value={image?.customMetadata}
                    />
                    <VStack align="flex-start">
                        <Heading as="h5" size="sm">
                            Preview
                        </Heading>
                        <ChakraImage
                            objectFit="contain"
                            src={image?.directUrl}
                        />
                    </VStack>
                </VStack>
            </Show>
        </VStack>
    );
};
