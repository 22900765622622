import { ILog } from "@bankingright-dashboard/interfaces";
import { useParams, useSearchParams } from "react-router-dom";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import { LogType } from "@bankingright-dashboard/enums";
import { BackButton, LoadingIndicator } from "@bankingright-dashboard/ui";
import { convertToDate } from "@bankingright-dashboard/utils";
import {
    Badge, Box,
    Card,
    CardBody,
    CardHeader,
    Flex,
    HStack,
    Heading,
    List,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import { useList, useNavigation } from "@refinedev/core";

export const OperationDetails: React.FC = () => {
    const { showUrl: generateShowUrl, push } = useNavigation();
    const { id } = useParams();
    const [searchParams] = useSearchParams();

    const { data, isLoading, isError } = useList<ILog>({
        resource: "logs",
        filters: [
            {
                field: "operation_id",
                operator: "eq",
                value: id,
            },
            {
                field: "source",
                operator: "eq",
                value: searchParams.get("source"),
            },
        ],

        pagination: {
            mode: "off",
        },
    });

    const showRow = (rowId: string) => {
        const showUrl = generateShowUrl("logs", rowId!);
        push(showUrl);
    };

    const colors = ["blue.200", "blue.400", "blue.600", "blue.800", "blue.900"];

    var chartData: any[] = [];
    var logs: ILog[] = [];

    if (!isLoading) {
        logs =
            data?.data
                .filter(
                    (value) =>
                        value.type == LogType.request ||
                        value.type == LogType.dependency
                )
                .sort((value1, value2) => +value1.date - +value2.date) ?? [];

        chartData = logs?.map((value, index) => ({
            name: value.name,
            duration: value.duration,
            idle: +value.date * 1000 - +logs[0].date * 1000,
            id: value.id,
            customFill: `var(--chakra-colors-${colors[
                index % colors.length
            ].replace(".", "-")})`,
        }));
    }

    return (
        <Card bg="defaults.bg-light">
            <CardHeader>
                <Flex>
                    <HStack spacing={2}>
                        <BackButton />
                        <Heading size="lg">Operation details</Heading>
                    </HStack>
                    <Spacer />
                    {/* <RefreshButton /> */}
                </Flex>
            </CardHeader>
            <CardBody minH="800px">
                {!isLoading && (
                    <Box>
                        <Chart chartData={chartData} />
                        {logs && (
                            <LogList
                                logs={logs}
                                showRow={showRow}
                                chartData={chartData}
                            />
                        )}
                    </Box>
                )}
                <LoadingIndicator isLoading={isLoading} />
            </CardBody>
        </Card>
    );
};

const Chart: React.FC<{ chartData: any[] }> = ({ chartData }) => {
    return (
        <Flex
            direction="column"
            align="center"
            h={`${100 + chartData.length * 50}px`}
            mb={5}
        >
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={chartData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" hide={true} />
                    <Tooltip />
                    <Bar dataKey="idle" stackId="a" fill="transparent" />
                    <Bar
                        dataKey="duration"
                        stackId="a"
                        fill="var(--chakra-colors-blue-400)"
                    >
                        {chartData.map((item, index) => {
                            return <Cell key={index} fill={item.customFill} />;
                        })}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <Text>Duration (ms)</Text>
        </Flex>
    );
};

const LogList: React.FC<{
    logs: ILog[];
    showRow: (rowId: string) => void;
    chartData: any[];
}> = ({ logs, showRow, chartData }) => {
    const renderType = (log: ILog) => {
        switch (log.type) {
            case "request":
                return <Badge colorScheme="blue">{log.type}</Badge>;
            case "exception":
                return <Badge colorScheme="red">{log.type}</Badge>;
            default:
                return <Badge>{log.type}</Badge>;
        }
    };

    return (
        <List position="relative">
            <TableContainer whiteSpace="pre-line">
                <Table>
                    <Thead>
                        <Tr>
                            <Th></Th>
                            <Th>Type</Th>
                            <Th>Date</Th>
                            <Th>Name</Th>
                            <Th>Status</Th>
                            <Th>Duration</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {logs?.map((value) => (
                            <Tr
                                key={value.id}
                                onClick={() => {
                                    showRow(value.id!);
                                }}
                                _hover={{
                                    background: "chakra-subtle-bg",
                                    cursor: "pointer",
                                }}
                                _active={{
                                    background: useColorModeValue(
                                        "gray.200",
                                        "gray.600"
                                    ),
                                }}
                            >
                                <Td width={6}>
                                    <Box
                                        boxSize={5}
                                        background={
                                            chartData.find(
                                                (data) => value.id == data.id
                                            )?.customFill ?? "transparent"
                                        }
                                    />
                                </Td>
                                <Td>{renderType(value)}</Td>
                                <Td>
                                    <HStack align="flex-start">
                                        <Text>
                                            {convertToDate(
                                                value.date as string
                                            ).toLocaleDateString()}
                                        </Text>
                                        <Text>
                                            {convertToDate(
                                                value.date as string
                                            ).toLocaleTimeString()}
                                        </Text>
                                    </HStack>
                                </Td>
                                <Td width="50%">{value.endpoint}</Td>
                                <Td>
                                    {value.resultCode &&
                                    (value.resultCode < 200 ||
                                        value.resultCode >= 300) ? (
                                        <Badge
                                            colorScheme="red"
                                            variant="subtle"
                                        >
                                            {value.resultCode}
                                        </Badge>
                                    ) : (
                                        <Badge
                                            colorScheme="green"
                                            variant="subtle"
                                        >
                                            {value.resultCode}
                                        </Badge>
                                    )}
                                </Td>
                                <Td>
                                    {value.duration}{" "}
                                    {value.duration ? "ms" : ""}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </List>
    );
};
