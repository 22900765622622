import { ResourceAction } from "@bankingright-dashboard/enums";
import { useFileExport, useFileImport } from "@bankingright-dashboard/hooks";
import { IPositionEnrichment } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";
import React from "react";

export const PositionEnrichmentList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IPositionEnrichment>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: true,
            },
            {
                id: "isin",
                header: "ISIN",
                accessorKey: "isin",
                enableColumnFilter: true,
            },
            {
                id: "url",
                header: "URL",
                accessorKey: "url",
                enableColumnFilter: true,
            },
        ],
        []
    );

    const importProps = {
        meta: {
            parent: "positions",
        },
    };

    const exportProps = {
        meta: {
            parent: "positions",
        },
    };

    return (
        <ResourceList
            columns={columns}
            actions={[
                ResourceAction.show,
                ResourceAction.edit,
                ResourceAction.delete,
            ]}
            importOptions={importProps}
            exportOption={exportProps}
            hasSelection={true}
        />
    );
};
