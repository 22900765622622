import {
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Textarea,
    Button,
    useDisclosure,
    Image as ChakraImage,
    Flex,
    Box,
    IconButton,
} from "@chakra-ui/react";
import { HttpError, useOne, useTranslate } from "@refinedev/core";
import { Image } from "@bankingright-dashboard/interfaces";
import { ImageBrowserModal } from "@bankingright-dashboard/images";
import React, { useEffect, useState } from "react";
import {
    FieldValues,
    RegisterOptions,
    UseControllerProps,
    useController,
} from "react-hook-form";
import { UilTimes } from "@iconscout/react-unicons";
import { set } from "date-fns";
import { is } from "date-fns/locale";

type ChooseImageFormControlProps<T extends FieldValues> =
    UseControllerProps<T> &
        FormControlProps & {
            label: string;
            helperText?: string;
        };

export const ChooseImageFormControl = <T extends FieldValues>({
    control,
    name,
    label,
    helperText,
    rules,
    isRequired = true,
    defaultValue,
    ...props
}: ChooseImageFormControlProps<T>) => {
    const translate = useTranslate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImage, setSelectedImage] = useState<Image>();

    const inputRules: RegisterOptions<T> = {
        required: isRequired
            ? translate(
                  "validations.required",
                  { field: label },
                  `${label} is required`
              )
            : undefined,
    };

    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: inputRules,
        defaultValue,
    });

    const { data, isLoading, isError } = useOne<Image, HttpError>({
        resource: "images",
        id: value,
    });

    useEffect(() => {
        setSelectedImage(data?.data);
    }, [data]);

    return (
        <FormControl isInvalid={!!error} {...props}>
            <FormLabel>{label}</FormLabel>
            <Flex>
                {selectedImage && (
                    <Box position="relative">
                        <IconButton
                            position="absolute"
                            variant="primary"
                            boxSize="27px"
                            right="-2"
                            top="-2"
                            size="xs"
                            rounded="full"
                            onClick={() => {
                                setSelectedImage(undefined);
                                onChange(undefined);
                            }}
                            border=""
                            borderWidth="3px"
                            borderColor="white"
                            aria-label="Remove Image"
                            icon={<UilTimes color="white" size="18px" />}
                        />
                        <ChakraImage
                            src={selectedImage.directUrl}
                            alt={selectedImage.filename}
                            width="100px"
                            height="100px"
                            objectFit="cover"
                        />
                    </Box>
                )}
                {!selectedImage && (
                    <Button onClick={onOpen} isLoading={value && isLoading}>
                        Choose Image
                    </Button>
                )}
            </Flex>

            <ImageBrowserModal
                isOpen={isOpen}
                onClose={onClose}
                onSelected={(image) => {
                    onChange(image.id);
                    setSelectedImage(image);
                    onClose();
                }}
            />
            {helperText && !error && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};
