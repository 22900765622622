import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
    // define the part you're going to style
    container: {
        borderRadius: 2,
        boxShadow: "sm",
    },
    header: {
        fontSize: "1.5rem",
        fontWeight: 700,
        textColor: "gray.black",
    },
    body: {
        color: "text.body",
    },
    footer: {
        color: "text.body",
    },
});

const variants = {
    ghost: definePartsStyle({
        container: {
            boxShadow: "none",
        },
        header: {
            bg: "transparent"
        },
    }),
};

export const cardTheme = defineMultiStyleConfig({ baseStyle, variants });
