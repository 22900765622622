import {
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Input,
    Switch,
    Text,
    Textarea,
} from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";

import {
    FieldErrors,
    FieldError,
    FieldValues,
    UseFormRegister,
    FieldPath,
    ValidationRule,
    UseControllerProps,
    useController,
} from "react-hook-form";

type SwitchFormControlProps<T extends FieldValues> = UseControllerProps<T> &
    FormControlProps & {
        label: string;
        helperText?: string;
    };

export const SwitchFormControl = <T extends FieldValues>({
    control,
    name,
    label,
    helperText,
    rules,
    isRequired = true,
    ...props
}: SwitchFormControlProps<T>) => {
    const translate = useTranslate();
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: rules ?? {
            required: isRequired
                ? translate(
                      "validations.required",
                      { field: label },
                      `${label} is required`
                  )
                : undefined,
        },
    });

    return (
        <FormControl isInvalid={!!error} {...props}>
            <HStack spacing={0}>
                <FormLabel>{label}</FormLabel>
                <Switch
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    isChecked={value}
                />
                {helperText && !error && (
                    <FormHelperText>{helperText}</FormHelperText>
                )}
            </HStack>
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};
