import { ResourceAction, UserStatus } from "@bankingright-dashboard/enums";
import { IUser } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { Badge } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

interface UserListProps {
    resource?: string;
}

export const UserList: React.FC<UserListProps> = ({resource}) => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IUser>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: true,
            },
            {
                id: "userName",
                header: "Username",
                accessorKey: "userName",
                enableColumnFilter: true,
            },
            {
                id: "fullName",
                header: "Name",
                accessorKey: "fullName",
                enableColumnFilter: true,
            },
            {
                id: "status",
                header: "Status",
                accessorKey: "status",
                cell: function render({ row, getValue }) {
                    const value = (getValue() as string) ?? "active";

                    var colorScheme = "green";
                    switch (value) {
                        case UserStatus.active:
                            colorScheme = "green";
                            break;
                        case UserStatus.blocked:
                            colorScheme = "red";
                            break;
                        default:
                            colorScheme = "green";
                    }
                    return (
                        <Badge colorScheme={colorScheme} variant="subtle">
                            {value}
                        </Badge>
                    );
                },
            },
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            actions={[
                ResourceAction.show,
                ResourceAction.delete,
                ResourceAction.edit,
            ]}
            hasSelection={true}
            resourceName={resource}
        />
    );
};
