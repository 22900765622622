import { ColumnSorter } from "@bankingright-dashboard/table";
import {
    LoadingIndicator
} from "@bankingright-dashboard/ui";
import {
    Flex,
    HStack,
    List,
    Skeleton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import { BaseRecord } from "@refinedev/core";
import { flexRender, HeaderGroup, RowModel, Row } from "@tanstack/react-table";

interface TableListProps<T extends BaseRecord> {
    size?: "sm" | "md" | "lg";
    isFetching: boolean;
    isLoading: boolean;
    isError: boolean;
    pageSize: number;
    getHeaderGroups: () => HeaderGroup<T>[];
    getRowModel: () => RowModel<T>
    showRow: (row: Row<T>, newPage: boolean) => void;
    hasShow: boolean;
}

export const TableList = <T extends BaseRecord>({
    size,
    isFetching,
    isLoading,
    isError,
    pageSize,
    getHeaderGroups,
    getRowModel,
    showRow,
    hasShow
}: TableListProps<T>) => {

    const skeletonArray = Array.from(
        Array(pageSize).keys()
    );

    return (
        <List position="relative">
            <TableContainer whiteSpace="pre-line">
                <Table size={size} variant="simple">
                    <Thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers
                                    .filter(
                                        (header) =>
                                            !(
                                                header.column.columnDef.meta
                                                    ?.hidden ?? false
                                            )
                                    )
                                    .map((header) => (
                                        <Th
                                            key={header.id}
                                            width={
                                                header.column.columnDef.meta
                                                    ?.width ?? "unset"
                                            }
                                        >
                                            {!header.isPlaceholder && (
                                                <HStack spacing="2">
                                                    <Text
                                                        textTransform="capitalize"
                                                        letterSpacing="normal"
                                                        variant="header"
                                                    >
                                                        {flexRender(
                                                            header.column
                                                                .columnDef
                                                                .header,
                                                            header.getContext()
                                                        )}
                                                    </Text>
                                                    <HStack spacing="2">
                                                        <ColumnSorter
                                                            column={
                                                                header.column
                                                            }
                                                        />
                                                    </HStack>
                                                </HStack>
                                            )}
                                        </Th>
                                    ))}
                            </Tr>
                        ))}
                    </Thead>

                    <Tbody opacity={isFetching ? 0.5 : 1.0}>
                        {!isLoading &&
                            getRowModel().rows.map((row) => (
                                <Tr
                                    key={row.id}
                                    onMouseUp={(event) => {
                                        if (event.button == 1) {
                                            showRow(row, true);
                                        }
                                    }}
                                    onClick={(event) => {
                                        if (hasShow && !event.shiftKey && !event.metaKey) {
                                            showRow(row, false);
                                        }
                                        else if (event.shiftKey || event.metaKey) {
                                            row.toggleSelected();
                                        }
                                    }}
                                    _hover={{
                                        background: hasShow
                                            ? "chakra-subtle-bg"
                                            : "unset",
                                        cursor: hasShow ? "pointer" : "unset",
                                    }}
                                    _active={{
                                        background: hasShow
                                            ? useColorModeValue(
                                                  "gray.200",
                                                  "gray.600"
                                              )
                                            : "unset",
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        if (
                                            !(
                                                cell.column.columnDef.meta
                                                    ?.hidden ?? false
                                            )
                                        ) {
                                            return (
                                                <Td key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            );
                                        }
                                    })}
                                </Tr>
                            ))}

                        {isLoading &&
                            skeletonArray.map((value) => (
                                <Tr key={value}>
                                    {getHeaderGroups()[0].headers.map(
                                        (header) => (
                                            <Td key={header.id}>
                                                <Skeleton h="20px" />
                                            </Td>
                                        )
                                    )}
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </TableContainer>

            <LoadingIndicator isLoading={isFetching} />

            {isError && (
                <Flex
                    minH={`${
                        (pageSize ?? 10) * 50
                    }px`}
                    align="center"
                >
                    <Text w="100%" textAlign="center" padding={5}>
                        Could not retrieve the data, there was an error!
                    </Text>
                </Flex>
            )}
        </List>
    );
};
