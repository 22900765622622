import {
    FilterType,
    ResourceAction,
    ResourceListStyle,
} from "@bankingright-dashboard/enums";
import { Pagination } from "@bankingright-dashboard/pagination";
import {
    ActionButton,
    BackButton,
    CreateButton,
    DeleteButton,
    ExportButton,
    FilterBox,
    ImportButton,
    ListTaskButton,
    ListTaskButtonProps,
    RefreshButton,
} from "@bankingright-dashboard/ui";
import { normalize, notEmpty } from "@bankingright-dashboard/utils";
import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox,
    Flex,
    Heading,
    HStack,
    Spacer,
    VStack,
} from "@chakra-ui/react";

import {
    FileExportOptions,
    FileImportOptions,
    useFileExport,
    useFileImport,
} from "@bankingright-dashboard/hooks";
import {
    BaseRecord,
    CrudFilter,
    GetListResponse,
    LogicalFilter,
    useGo,
    useNavigation,
    useResource,
    useTranslate,
} from "@refinedev/core";

import { useTable, UseTableProps } from "@refinedev/react-table";
import { ColumnDef, RowData, Row } from "@tanstack/react-table";
import { FilterConfiguration, ListFilters } from "./list-filters";
import { TableList } from "./table-list";
import { GridList } from "./grid-list";
import { useState } from "react";
import { get } from "react-hook-form";
import { ResourceMeta } from "@refinedev/core/dist/contexts/resource/types";

declare module "@tanstack/table-core" {
    interface ColumnMeta<TData extends RowData, TValue> {
        hidden?: boolean;
        filter?: {
            type: FilterType;
            options?: Array<string>;
        };
        width?: string;
    }
}

interface ResourceListProps<T extends BaseRecord> extends UseTableProps<T> {
    title?: string;
    actions?: Array<ResourceAction>;
    resourceName?: string;
    clearWhenLoading?: boolean;
    additionalFilters?: Array<FilterConfiguration>;
    showPageSizeSelect?: boolean;
    size?: "sm" | "md" | "lg";
    importOptions?: FileImportOptions;
    exportOption?: FileExportOptions;
    listHeader?: (data?: GetListResponse<T>) => React.ReactElement;
    listFooter?: (data?: GetListResponse<T>) => React.ReactElement;
    additionalButtons?: Array<ListTaskButtonProps<T>>;
    listStyle?: ResourceListStyle;
    hasSelection?: boolean;
    onShow?: (row: Row<T>) => void;
    canCreate?: boolean;
    canRefresh?: boolean;
    gridMinChildWidth?: string;
    cardVariant?: string;
    hasBackButton?: boolean;
}

export type RowSelectionState = Record<string, boolean>;

export type RowSelectionTableState = {
    rowSelection: RowSelectionState;
};

type BaseRecordWithId = BaseRecord & { id?: string };

export const ResourceList = <T extends BaseRecordWithId>({
    title,
    columns,
    actions,
    resourceName,
    clearWhenLoading = false,
    additionalFilters,
    showPageSizeSelect = true,
    refineCoreProps,
    size = "md",
    importOptions,
    exportOption,
    listHeader,
    listFooter,
    additionalButtons,
    listStyle: style = ResourceListStyle.table,
    hasSelection = false,
    onShow,
    canCreate = true,
    canRefresh = true,
    gridMinChildWidth,
    cardVariant,
    hasBackButton = false,
}: ResourceListProps<T>) => {
    const translate = useTranslate();
    const { resource } = useResource(resourceName);
    const { showUrl: generateShowUrl } = useNavigation();
    const go = useGo();
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    const filteredActions = actions?.filter(
        (value) => value != ResourceAction.show
    );

    if (filteredActions && filteredActions.length > 0) {
        const actionColumn: ColumnDef<T> = {
            id: "actions",
            header: translate(
                `${normalize(resource!.name)}.list.actions`,
                "Actions"
            ),
            accessorKey: "id",
            enableColumnFilter: false,
            enableSorting: false,
            cell: function render({ row, getValue }) {
                return (
                    <ActionButton
                        recordItemId={getValue() as number}
                        actions={filteredActions}
                        resourceName={resource!.name}
                        display={row.getIsSelected() ? "none" : "flex"}
                    />
                );
            },
        };

        if (columns.find((column) => column.id === "actions") == undefined) {
            columns.push(actionColumn);
        }
    }

    if (hasSelection) {
        const selectionColumn: ColumnDef<T> = {
            id: "selection",
            header: "",
            accessorKey: "selection",
            enableColumnFilter: false,
            enableSorting: false,
            meta: {
                width: "35px",
            },
            cell: function render({ row }) {
                return (
                    <Box onClick={(event) => event.stopPropagation()}>
                        <Checkbox
                            isChecked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </Box>
                );
            },
        };

        if (columns.find((column) => column.id === "selection") == undefined) {
            columns.unshift(selectionColumn);
        }
    }

    const columnFilterConfiguration: Array<FilterConfiguration> = columns
        .map((column) => {
            if (column.enableColumnFilter) {
                return {
                    id: column.id!,
                    label: column.header?.toString() ?? "",
                    type: column.meta?.filter?.type ?? FilterType.input,
                    options: column.meta?.filter?.options,
                };
            }
        })
        .filter(notEmpty);

    const filterConfiguration = [
        ...columnFilterConfiguration,
        ...(additionalFilters ?? []),
    ];
    // .sort((value1, value2) =>
    //     value1.label.localeCompare(value2.label)
    // )

    const hasFilters = filterConfiguration.length > 0;

    const initialFilters: Array<CrudFilter> = filterConfiguration
        .filter((filter) => {
            return filter.defaultValue !== undefined;
        })
        .map((filter) => {
            return {
                field: filter.id,
                operator: "eq",
                value: filter.defaultValue,
            };
        });

    const {
        getIsAllRowsSelected,
        getIsSomeRowsSelected,
        getToggleAllRowsSelectedHandler,
        getHeaderGroups,
        getRowModel,
        getState,
        refineCore: {
            setCurrent,
            pageSize,
            setPageSize,
            pageCount,
            current,
            tableQueryResult,
            setFilters,
            filters,
        },
    } = useTable<T>({
        columns,
        onRowSelectionChange: setRowSelection,
        getRowId: (row) => row.id ?? row.index,
        state: {
            rowSelection,
        },
        enableRowSelection: hasSelection,
        refineCoreProps: {
            ...refineCoreProps,
            syncWithLocation: true,
            filters: { initial: initialFilters },
        },
        meta: {
            test: "test",
        },
    });

    if (importOptions) {
        const oldOnFinish = importOptions.onFinish;
        importOptions.onFinish = () => {
            tableQueryResult.refetch();
            oldOnFinish?.();
        };
    }

    const hasShow =
        !!resource?.show &&
        (actions?.includes(ResourceAction.show) || onShow != undefined) &&
        !tableQueryResult.isFetching;

    const showRow = (row: Row<T>, newPage: boolean = false) => {
        if (onShow) {
            onShow(row);
            return;
        }

        const showUrl = new URL(
            generateShowUrl(resource!.name, row.original.id!),
            window.location.origin
        );

        filterConfiguration
            .filter((filter) => filter.passValueToShow)
            .forEach((filter) => {
                const value = filters.find(
                    (f) => (f as LogicalFilter).field === filter.id
                )?.value;
                if (value) {
                    showUrl.searchParams.append(filter.id, value);
                }
            });

        const url = showUrl.pathname + showUrl.search;

        if (newPage) {
            window.open(url, "_blank");
        } else {
            go({
                to: url.toString(),
                type: "push",
            });
        }
    };

    const showSkeletonLoading =
        tableQueryResult.isLoading ||
        (clearWhenLoading &&
            (tableQueryResult.isFetching || tableQueryResult.isLoading));

    const filterBoxSelectionProps = hasSelection
        ? {
              checked: getIsAllRowsSelected(),
              indeterminate: getIsSomeRowsSelected(),
              onChange: getToggleAllRowsSelectedHandler(),
          }
        : undefined;

    const renderCardBody = () => {
        switch (style) {
            case ResourceListStyle.table:
                return (
                    <TableList
                        size={size}
                        isFetching={tableQueryResult.isFetching}
                        isLoading={showSkeletonLoading}
                        isError={tableQueryResult.isError}
                        pageSize={pageSize}
                        getHeaderGroups={getHeaderGroups}
                        getRowModel={getRowModel}
                        hasShow={hasShow ?? false}
                        showRow={showRow}
                    />
                );
            case ResourceListStyle.grid:
                return (
                    <GridList
                        size={size}
                        isFetching={tableQueryResult.isFetching}
                        isLoading={showSkeletonLoading}
                        isError={tableQueryResult.isError}
                        pageSize={pageSize}
                        getHeaderGroups={getHeaderGroups}
                        getRowModel={getRowModel}
                        hasShow={hasShow ?? false}
                        showRow={showRow}
                        hasActions={(filteredActions?.length ?? 0) > 0}
                        hasSelection={hasSelection}
                        minChildWidth={gridMinChildWidth}
                    />
                );
        }
    };

    return (
        <Card bg="defaults.bg-light" variant={cardVariant}>
            <CardHeader>
                <VStack align="stretch" gap={4}>
                    <Flex align="center">
                        <HStack spacing={2}>
                            {hasBackButton && <BackButton />}
                            <Heading variant="title-bold">
                                {title ??
                                    translate(
                                        `${normalize(
                                            resource!.name
                                        )}.titles.list`,
                                        resource!.name
                                    )}
                            </Heading>
                        </HStack>
                        <Spacer />
                        <HStack spacing={2}>
                            {additionalButtons?.map((buttonProps, index) => (
                                <ListTaskButton
                                    key={index}
                                    {...buttonProps}
                                    onClick={async () => {
                                        await buttonProps.task?.(
                                            tableQueryResult.data
                                        );
                                        if (buttonProps.shouldRefetch) {
                                            tableQueryResult.refetch();
                                        }
                                    }}
                                />
                            ))}
                            {hasSelection &&
                                (getIsSomeRowsSelected() ||
                                    getIsAllRowsSelected()) && (
                                    <DeleteButton
                                        recordItemId={Object.keys(rowSelection)}
                                        variant="destructive"
                                        justifyContent="flex-start"
                                        px={2}
                                        py={3}
                                        resource={resourceName}
                                        onSuccess={() => {
                                            setRowSelection({});
                                        }}
                                    />
                                )}
                            {canRefresh && (
                                <RefreshButton
                                    resourceNameOrRouteName={resourceName}
                                    onClick={() => {
                                        tableQueryResult.refetch();
                                    }}
                                />
                            )}
                            {importOptions && (
                                <ImportButton
                                    resourceNameOrRouteName={resourceName}
                                    {...useFileImport(importOptions)}
                                />
                            )}
                            {exportOption && (
                                <ExportButton
                                    resourceNameOrRouteName={resourceName}
                                    {...useFileExport(exportOption)}
                                />
                            )}
                            {!!resource?.create && canCreate && (
                                <CreateButton
                                    resourceNameOrRouteName={resourceName}
                                />
                            )}
                        </HStack>
                    </Flex>
                    {hasFilters && (
                        <FilterBox selection={filterBoxSelectionProps}>
                            <ListFilters
                                configuration={filterConfiguration}
                                setFilters={(value) => {
                                    setFilters(value, "replace");
                                }}
                                filters={filters}
                            />
                        </FilterBox>
                    )}
                </VStack>
            </CardHeader>

            <CardBody>
                {listHeader?.(tableQueryResult.data)}
                {renderCardBody()}
                {listFooter?.(tableQueryResult.data)}
            </CardBody>
            <CardFooter mt={-3} mx={5}>
                <Pagination
                    current={current}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    showPageSizeSelect={showPageSizeSelect}
                    setCurrent={setCurrent}
                    setPageSize={setPageSize}
                />
            </CardFooter>
        </Card>
    );
};
