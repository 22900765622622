import { Text, Avatar, Box, Button, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, BoxProps, Image, AspectRatio } from "@chakra-ui/react";
import { useGetLocale, useSetLocale, useTranslate } from "@refinedev/core";
import { useTranslation } from "react-i18next";
import {
    FiCheck,
    FiChevronDown
} from 'react-icons/fi';

interface LanguageMenuProps extends BoxProps { }

export const LanguageMenu = ({ ...props }: LanguageMenuProps) => {
    const { i18n } = useTranslation();
    const locale = useGetLocale();
    const changeLanguage = useSetLocale();
    const translate = useTranslate();

    return (
        <Box {...props}>
            <Menu>
                <MenuButton as={Button}>
                    <HStack spacing={3} >
                        <AspectRatio w="25px" ratio={1}>
                            <Image src={`/images/flags/${locale()}.svg`} borderRadius="full" />
                        </AspectRatio>

                        <Text>{translate(`languages.${locale()}`, locale())}</Text>
                        <Box display={{ base: "none", md: "flex" }}>
                            <FiChevronDown />
                        </Box>
                    </HStack>
                </MenuButton>
                <MenuList>
                    {[...(i18n.languages || [])].sort().map((lang: string) => (
                        <MenuItem
                            key={lang}
                            value={lang}
                            onClick={() => changeLanguage(lang)}
                            icon={
                                <HStack>
                                    <Flex align="center" visibility={lang == locale() ? "visible" : "hidden"}>
                                        <FiCheck size={20} />
                                    </Flex>
                                    <AspectRatio w="25px" ratio={1}>
                                        <Image src={`/images/flags/${lang}.svg`} borderRadius="full" />
                                    </AspectRatio>
                                </HStack>
                            }
                        >
                            {translate(`languages.${lang}`, lang)}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    )
}