import {
    Heading,
    Text
} from "@chakra-ui/react";
import { useShow } from "@refinedev/core";

import { ISecurityAudit } from "@bankingright-dashboard/interfaces";
import { Show } from "@bankingright-dashboard/ui";
import { convertToDate } from "@bankingright-dashboard/utils";

export const SecurityAuditShow: React.FC = () => {
    const { queryResult } = useShow<ISecurityAudit>();
    const { data, isLoading } = queryResult;
    const audit = data?.data;
    
    return (
        <Show isLoading={isLoading}>
            <Heading as="h5" size="sm">
                Id
            </Heading>
            <Text mt={2}>{audit?.id}</Text>

            <Heading as="h5" size="sm" mt={4}>
                User ID
            </Heading>
            <Text mt={2}>{audit?.userId}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Device ID
            </Heading>
            <Text mt={2}>{audit?.deviceId}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Date
            </Heading>
            <Text mt={2}>{audit?.date != undefined ? convertToDate(audit.date).toLocaleDateString() : ""}</Text>

            <Heading as="h5" size="sm" mt={4}>
                User agent
            </Heading>
            <Text mt={2}>{audit?.userAgent}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Type
            </Heading>
            <Text mt={2}>{audit?.type}</Text>

            <Heading as="h5" size="sm" mt={4}>
                IP
            </Heading>
            <Text mt={2}>{audit?.ip}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Session ID
            </Heading>
            <Text mt={2}>{audit?.sessionId}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Operation ID
            </Heading>
            <Text mt={2}>{audit?.operationId}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Endpoint
            </Heading>
            <Text mt={2}>{audit?.endpoint}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Status
            </Heading>
            <Text mt={2}>{audit?.status}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Reason
            </Heading>
            <Text mt={2}>{audit?.reason}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Method
            </Heading>
            <Text mt={2}>{audit?.method}</Text>
        </Show>
    );
};