import { formatBytes } from "@bankingright-dashboard/utils";
import { CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
    BoxProps,
    Text,
    Flex,
    Spacer,
    IconButton,
    Link,
    HStack,
} from "@chakra-ui/react";

interface FileBoxProps extends BoxProps {
    filename: string;
    url?: string;
    size?: number;
    onDelete: () => void;
}

export const FileBox = ({
    filename,
    url,
    size,
    onDelete,
    ...props
}: FileBoxProps) => {
    return (
        <Flex
            px={5}
            py={2}
            m={1}
            align="flex-start"
            bg="gray.200"
            alignItems="center"
            rounded="lg"
            {...props}
        >
            {url && (
                <Link href={url} isExternal>
                    {filename}
                    <ExternalLinkIcon mx={2} />
                </Link>
            )}
            {!url && <Text>{filename}</Text>}
            <Spacer />
            <HStack spacing={5}>
                {size && <Text>{formatBytes(size)}</Text>}
                <IconButton
                    aria-label="Delete"
                    icon={<CloseIcon />}
                    onClick={onDelete}
                />
            </HStack>
        </Flex>
    );
};
