import { useExportOne, useManualImport } from "@bankingright-dashboard/hooks";
import { IUserSegment } from "@bankingright-dashboard/interfaces";
import { ExportButtonProps, Show } from "@bankingright-dashboard/ui";
import { Heading, Text } from "@chakra-ui/react";
import {
    useExport,
    useNotification,
    useResource,
    useShow,
    useTranslate,
    useUpdate,
} from "@refinedev/core";

interface UserSegmentImport {
    userId: string;
}

export const UserSegmentShow: React.FC = () => {
    const { queryResult } = useShow<IUserSegment>();
    const { data, isLoading, isFetching } = queryResult;
    const segment = data?.data;
    const { id, resource } = useResource();
    const { mutate } = useUpdate();
    const { open } = useNotification();
    const translate = useTranslate();

    const updateUserSegment = async (
        results: UserSegmentImport[]
    ): Promise<void> => {
        const userIds = results.flatMap((item) => {
            return item.userId != undefined ? item.userId : [];
        });

        if (userIds.length == 0) {
            open?.({
                type: "error",
                message: translate("notifications.errorNotStatus", "Error"),
                description: translate(
                    "notifications.manualImportError",
                    "Failed to import, please check file."
                ),
            });
            return;
        } else {
            return mutate({
                resource: resource!.name,
                values: {
                    users: userIds,
                },
                id: id!,
                meta: {
                    parent: "users",
                },
            });
        }
    };

    const importProps = useManualImport<UserSegmentImport>({
        onImport: updateUserSegment,
        onFinish: () => {
            queryResult.refetch();
        },
    });

    const { triggerExport, isLoading: isExportLoading } =
        useExportOne<IUserSegment>({
            id: id!,
            convertData: (data) => {
                return data.users.map((user) => {
                    return {
                        users: user,
                    };
                });
            },
            meta: {
                parent: "users",
            },
        });
    const exportProps: ExportButtonProps = {
        onClick: triggerExport,
        isLoading: isExportLoading,
    };

    return (
        <Show
            isLoading={isLoading || isFetching}
            importButtonProps={importProps}
            exportButtonProps={exportProps}
            meta={{ parent: "users" }}
        >
            <Heading as="h5" size="sm">
                Id
            </Heading>
            <Text mt={2}>{segment?.id}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Title
            </Heading>
            <Text mt={2}>{segment?.title}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Description
            </Heading>
            <Text mt={2}>{segment?.description}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Number of users
            </Heading>
            <Text mt={2}>{segment?.users?.length ?? 0}</Text>
        </Show>
    );
};
