import { MsalProvider } from "@azure/msal-react";
import { BankingRightPublicClientApplication } from "@bankingright-dashboard/msal";
import { bankingRightTheme as theme } from "@bankingright-dashboard/theme";
import { ColorModeScript } from "@chakra-ui/react";
import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import "./i18n";
import { loginRequest, msalConfig } from "./msalConfig";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root")!
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <React.Suspense fallback={
      <div>Loading...</div>
    }>
      <MsalProvider instance={BankingRightPublicClientApplication({msalConfig: msalConfig, tokenRequest: loginRequest})}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
      </MsalProvider>
    </React.Suspense>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();