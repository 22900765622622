import {
    extendTheme,
    type ThemeConfig
} from "@chakra-ui/react";
import { colorComponents } from "./color-components";
import { colorPrimitives } from "./color-primitives";
import { components } from "./components";
import { sizes } from "./sizes";
import { textStyles } from "./text-styles";

// 2. Add your color mode config
const config: ThemeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

// 3. extend the theme
export const bankingRightTheme = extendTheme(colorPrimitives, colorComponents, components, sizes, textStyles, config);