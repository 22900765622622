import { FilterType, ResourceAction } from "@bankingright-dashboard/enums";
import { ISecurityAudit } from "@bankingright-dashboard/interfaces";
import { FilterConfiguration, ResourceList } from "@bankingright-dashboard/table";
import { convertToDate } from "@bankingright-dashboard/utils";
import { Text, VStack } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";


export const SecurityAuditList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<ISecurityAudit>[]>(
        () => [      
            {
                id: "type",
                header: "Type",
                accessorKey: "type",
                enableColumnFilter: true
            },
            {
                id: "date",
                header: "Date",
                accessorKey: "date",
                enableSorting: false,
                cell: function render({ getValue }) {
                    return (
                        <VStack align="flex-start">
                            <Text>{convertToDate(getValue() as string).toLocaleDateString()}</Text>
                            <Text>{convertToDate(getValue() as string).toLocaleTimeString()}</Text>
                        </VStack>
                    )
                },
            },
            {
                id: "user_id",
                header: "User ID",
                accessorKey: "userId",
                enableColumnFilter: true
            },
            {
                id: "endpoint",
                header: "Endpoint",
                accessorKey: "endpoint",
                enableColumnFilter: true
            },
            {
                id: "status",
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: true
            }
        ],
        [],
    );

    const additionalFilters: Array<FilterConfiguration> = [
        {
            id: "from_date",
            label: "From date",
            type: FilterType.date
        }
    ]

    return (
        <ResourceList columns={columns} actions={[ResourceAction.show]} additionalFilters={additionalFilters} />
    )
};