import {
    Box,
    BoxProps,
    Tab,
    Table,
    TableContainer,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import { Theme } from "react-base16-styling";
import { JSONTree } from "react-json-tree";

interface JsonViewerProps extends BoxProps {
    body?: string;
    headers?: string;
    height?: string;
}

export const JsonViewer = ({ body, headers, height = "500px", ...props }: JsonViewerProps) => {
    const theme: Theme = {
        extend: "monokai",
        tree: {
            padding: "15px",
            fontSize: "14px"
        },
    };

    var jsonBody: any;
    try {
        jsonBody = JSON.parse(body ?? "{}");
    } catch {
        console.log("Error parsing JSON");
    }

    var jsonHeaders: any | undefined;
    try {
        jsonHeaders = JSON.parse(headers ?? "[]");
    } catch {
        console.log("Error parsing JSON");
    }

    const bgColor = useColorModeValue("white", "gray.800")

    return (
        <Box {...props}>
            <Tabs>
                <TabList>
                    <Tab>Formatted</Tab>
                    <Tab>Raw</Tab>
                    <Tab>Headers</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Box h={height} overflowY="auto">
                            <JSONTree
                                labelRenderer={([key]) => <Text>{key}</Text>}
                                valueRenderer={(raw: any) => (
                                    <Text as="em">{raw}</Text>
                                )}
                                data={jsonBody}
                                theme={theme}
                                invertTheme={useColorModeValue(true, false)}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box h={height} overflowY="auto" bg={bgColor}>
                            <Text whiteSpace="pre" p={5} fontSize="sm">
                                {JSON.stringify(jsonBody, null, 4)}
                            </Text>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <TableContainer
                            whiteSpace="pre-line"
                            h={height}
                            overflowY="auto"
                            bg={bgColor}
                            p={3}
                        >
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Th>Key</Th>
                                    <Th>Value</Th>
                                </Thead>
                                <Tbody>
                                    {jsonHeaders?.map((value: any) => (
                                        <Tr>
                                            <Td>
                                                <Text fontWeight="bold">
                                                    {value.Key}:
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text>{value.Value}</Text>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};
