import { ImageCard } from "@bankingright-dashboard/ui";
import {
    convertToDate,
    generateImageSubTitle,
} from "@bankingright-dashboard/utils";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    ModalProps,
    Box,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { Image } from "@bankingright-dashboard/interfaces";
import React from "react";
import {
    ResourceAction,
    ResourceListStyle,
} from "@bankingright-dashboard/enums";
import { ResourceList } from "@bankingright-dashboard/table";

interface ImageBrowserModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelected: (image: Image) => void;
}

export const ImageBrowserModal: React.FC<ImageBrowserModalProps> = ({
    isOpen,
    onClose,
    onSelected,
}) => {
    const columns = React.useMemo<ColumnDef<Image>[]>(
        () => [
            {
                id: "directUrl",
                header: "Url",
                accessorKey: "directUrl",
                cell: function render({ row }) {
                    const filename = row.original.filename ?? "unknown";
                    const dateModified =
                        row.original.dateModified ?? row.original.dateCreated;

                    return (
                        <ImageCard
                            name={filename}
                            url={row.original.directUrl}
                            fallbackUrl="https://via.placeholder.com/300"
                            subTitle={generateImageSubTitle(row.original)}
                            date={convertToDate(dateModified)}
                        />
                    );
                },
            },
        ],
        []
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxH="80%" maxW="70%">
                <ModalCloseButton zIndex={1} />
                <ModalBody overflowY="auto">
                        <ResourceList
                            title="Choose Image"
                            columns={columns}
                            actions={[]}
                            listStyle={ResourceListStyle.grid}
                            onShow={(row) => {
                                onSelected(row.original);
                            }}
                            canCreate={false}
                            canRefresh={false}
                            resourceName="images"
                            refineCoreProps={{
                                resource: "images",
                                pagination: {
                                    pageSize: 10,
                                }
                            }}
                            gridMinChildWidth="250px"
                            cardVariant="ghost"
                        />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
