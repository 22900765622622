import { ILog, LogStackTrace } from "@bankingright-dashboard/interfaces";
import { JsonViewer, Show } from "@bankingright-dashboard/ui";
import { convertToDate } from "@bankingright-dashboard/utils";
import {
    Box,
    Flex,
    HStack,
    Heading,
    Tag,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useShow } from "@refinedev/core";
import { useLocation } from "react-router-dom";

export const LogShow: React.FC = () => {
    const location = useLocation();
    const source = new URLSearchParams(location.search).get("source");

    const { queryResult } = useShow<ILog>({
        meta: {
            filters: [
                {
                    field: "source",
                    operator: "eq",
                    value: source,
                },
            ],
        },
    });

    const { data, isLoading } = queryResult;
    const log = data?.data;

    const hasBody = log?.type == "request" || log?.type == "dependency";
    const hasTrace = log?.type == "trace" || log?.type == "exception";
    const hasException = log?.type == "exception";

    const renderException = () => {
        return (
            <>
                {log?.exception != undefined ? (
                    <Box mt={10}>
                        <Heading size="md" pb={2}>
                            Exception:
                        </Heading>
                        {renderSimpleItem(log.exception.message, "Message")}
                        {renderSimpleItem(log.exception.method, "Method")}
                        {renderSimpleItem(log.exception.type, "Type")}

                        <Heading size="md" pt={10} pb={2}>
                            Stacktrace:
                        </Heading>
                        <Box
                            bg={useColorModeValue("gray.100", "gray.700")}
                            p={5}
                            overflow="auto"
                            maxH="500px"
                        >
                            {log?.exception?.stackTrace?.map(
                                (trace: LogStackTrace) => {
                                    return (
                                        <Box mb={5}>
                                            {trace.fileName && (
                                                <HStack spacing={2}>
                                                    <Text fontWeight="bold">
                                                        {trace.fileName}
                                                    </Text>
                                                    <Tag
                                                        colorScheme="green"
                                                        fontSize="0.8em"
                                                    >
                                                        line {trace.line}
                                                    </Tag>
                                                </HStack>
                                            )}
                                            {trace?.method && (
                                                <Text>{trace?.method}</Text>
                                            )}
                                            {trace?.assembly && (
                                                <Text fontStyle="italic">
                                                    {trace?.assembly}
                                                </Text>
                                            )}
                                        </Box>
                                    );
                                }
                            )}
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
            </>
        );
    };

    const renderSimpleItem = (value: string | undefined, label: string) => {
        return (
            <>
                {value != undefined ? (
                    <HStack spacing={2}>
                        <Text fontWeight="bold" minW={20}>
                            {label}:
                        </Text>
                        <Text>{value}</Text>
                    </HStack>
                ) : (
                    <></>
                )}
            </>
        );
    };

    return (
        <Show isLoading={isLoading}>
            <Flex
                direction="row"
                wrap="wrap"
                rowGap={10}
                justifyContent="space-between"
            >
                <Box>
                    {log?.endpoint && (
                        <Tag
                            size="md"
                            fontSize="1.5em"
                            fontWeight="bold"
                            p={2}
                            mb={2}
                        >
                            {log?.endpoint}
                        </Tag>
                    )}
                    <HStack spacing={2} wrap="wrap" mb={2}>
                        {log?.resultCode && (
                            <Tag
                                size="md"
                                fontSize="1.2em"
                                fontWeight="bold"
                                colorScheme={
                                    log?.resultCode >= 200 &&
                                    log?.resultCode <= 299
                                        ? "green"
                                        : "red"
                                }
                                p={1}
                            >
                                {log?.resultCode}
                            </Tag>
                        )}
                        {log?.verb && (
                            <Tag
                                size="md"
                                fontSize="1.2em"
                                fontWeight="bold"
                                colorScheme="blue"
                                p={1}
                            >
                                {log?.verb}
                            </Tag>
                        )}
                    </HStack>
                    {log?.date && (
                        <Text>
                            {convertToDate(log?.date ?? "").toLocaleString()}
                        </Text>
                    )}
                    {log?.duration && <Text>{log?.duration} ms</Text>}
                    <Box mt={10}>
                        {renderSimpleItem(log?.name, "Name")}
                        {renderSimpleItem(log?.url, "URL")}
                        {renderSimpleItem(log?.type, "Type")}
                        {renderSimpleItem(log?.subType, "Sub type")}
                        {renderSimpleItem(log?.level, "Level")}
                        {log?.country &&
                            renderSimpleItem(
                                `${log?.country} - ${log?.city}`,
                                "Location"
                            )}
                        {renderSimpleItem(log?.ip, "IP")}
                    </Box>
                </Box>
                <Box>
                    {renderSimpleItem(log?.id, "ID")}
                    {renderSimpleItem(log?.userId, "User")}
                    {renderSimpleItem(log?.deviceId, "Device")}
                    {renderSimpleItem(log?.sessionId, "Session")}
                    {renderSimpleItem(log?.operationId, "Operation")}
                </Box>
            </Flex>
            {hasBody && (
                <Flex
                    gap={5}
                    justifyContent="space-evenly"
                    bg={useColorModeValue("gray.100", "gray.700")}
                    mt={10}
                    direction={{ base: "column", lg: "row" }}
                >
                    <Box w={{ base: "100%", lg: "49%" }} minH="400px" pl={5}>
                        <Heading size="sm" pt={5}>
                            Request
                        </Heading>
                        <JsonViewer
                            mt={2}
                            body={log?.requestBody}
                            headers={log?.requestHeaders}
                        />
                    </Box>
                    <Box bg="gray.200" w="5px" />
                    <Box
                        w={{ base: "100%", lg: "49%" }}
                        minH="400px"
                        pl={{ base: 5, lg: "unset" }}
                    >
                        <Heading size="sm" pt={5}>
                            Response
                        </Heading>
                        <JsonViewer
                            mt={2}
                            body={log?.responseBody}
                            headers={log?.responseHeaders}
                        />
                    </Box>
                </Flex>
            )}
            {hasTrace && log?.message && (
                <>
                    <Heading size="sm" pt={5}>
                        Message:
                    </Heading>
                    <Text>{log?.message}</Text>
                </>
            )}
            {hasException && <>{renderException()}</>}
        </Show>
    );
};
