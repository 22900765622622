import { ThemeOverride } from "@chakra-ui/react";

export const sizes: ThemeOverride = {
    shadows: {
        base: "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
        xs: "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
        sm: "2.5px 2.5px 5.5px 0px rgba(125, 125, 125, 0.08)"
    },
    radii: {
        0: 0,
        1: "0.375rem",
        2: "0.5rem"
    },
    space: {
        0: 0,
        1: "0.25rem",
        2: "0.5rem",
        3: "0.75rem",
        4: "1rem",
        5: "1.25rem",
        6: "1.5rem",
        7: "1.75rem",
        8: "2rem",
        9: "2.25rem",
        10: "2.5rem",
        12: "3rem",
        14: "3.5rem",
        16: "4rem",
        20: "5rem",
    }
}