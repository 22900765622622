import { IDevice } from "@bankingright-dashboard/interfaces";
import { Show } from "@bankingright-dashboard/ui";
import { convertToDate } from "@bankingright-dashboard/utils";
import {
    Heading, HStack, Text
} from "@chakra-ui/react";
import { useShow } from "@refinedev/core";

export const DeviceShow: React.FC = () => {
    const { queryResult } = useShow<IDevice>();
    const { data, isLoading } = queryResult;
    const device = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Heading as="h5" size="sm">
                Id
            </Heading>
            <Text mt={2}>{device?.id}</Text>

            <Heading as="h5" size="sm" mt={4}>
                User Id
            </Heading>
            <Text mt={2}>{device?.userId}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Name
            </Heading>
            <Text mt={2}>{device?.deviceName}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Platform
            </Heading>
            <Text mt={2}>{device?.platform}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Brand
            </Heading>
            <Text mt={2}>{device?.brand}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Push token
            </Heading>
            <Text mt={2}>{device?.pushToken}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Status
            </Heading>
            <Text mt={2}>{device?.status}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Created at
            </Heading>
            <Text mt={2}>{convertToDate(device?.dateCreated).toLocaleDateString()} {convertToDate(device?.dateCreated).toLocaleTimeString()}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Last modified at
            </Heading>
            <Text mt={2}>{convertToDate(device?.dateModified).toLocaleDateString()} {convertToDate(device?.dateModified).toLocaleTimeString()}</Text>

            <Heading as="h5" size="sm" mt={4}>
                Wrong verification attempts
            </Heading>
            <Text mt={2}>{device?.wrongVerificationAttempts}</Text>
                
        </Show>
    );
};