import { ThemeOverride } from "@chakra-ui/react";
import { cardTheme } from "./card";

export const components: ThemeOverride = {
    components: {
        Card: cardTheme,
        Heading: {
            variants: {
                "display-bold": {
                    fontSize: "2rem",
                    fontWeight: 700,
                    textColor: "gray.black",
                },
                display: {
                    fontSize: "2rem",
                    fontWeight: 400,
                    textColor: "gray.black",
                },
                "title-bold": {
                    fontSize: "1.5rem",
                    fontWeight: 700,
                    textColor: "gray.black",
                },
                title: {
                    fontSize: "1.5rem",
                    fontWeight: 400,
                    textColor: "gray.black",
                },
                subtitle: {
                    fontSize: "1rem",
                    fontWeight: 700,
                    textColor: "gray.black",
                },
            },
        },
        Text: {
            baseStyle: {
                textColor: "text.body",
            },
            variants: {
                body: {},
                "body-bold": {
                    fontWeight: 600,
                },
                "sub-bold": {
                    fontSize: "0.875rem",
                    fontWeight: 600,
                },
                header: {
                    fontSize: "0.875rem",
                    textColor: "text.headings",
                    fontWeight: 600,
                    lineHeight: "150%",
                },
            },
        },
        FormLabel: {
            baseStyle: {
                fontSize: "1rem",
                fontWeight: 600,
                textColor: "text.body",
                marginBottom: 1,
            },
        },
        Button: {
            baseStyle: {
                borderRadius: 1,
                _hover: {
                    _disabled: {
                        bgColor: "button.disabled.bg",
                        color: "button.disabled.fg",
                    },
                },
            },
            sizes: {
                md: {
                    fontSize: "1rem",
                    fontWeight: 600,
                },
                lg: {
                    fontSize: "1.125rem",
                    fontWeight: 600,
                },
            },
            variants: {
                primary: {
                    color: "button.primary.fg",
                    bgColor: "button.primary.bg",
                    _hover: {
                        bgColor: "button.primary.bg-hover",
                    },
                    _active: {
                        bgColor: "button.primary.bg-pressed",
                    },
                },
                secondary: {
                    color: "button.secondary.fg",
                    bgColor: "button.secondary.bg",
                    _hover: {
                        bgColor: "button.secondary.bg-hover",
                    },
                    _active: {
                        bgColor: "button.secondary.bg-pressed",
                    },
                },
                tertiary: {
                    color: "button.tertiary.fg",
                    bgColor: "button.tertiary.bg",
                    borderColor: "button.tertiary.border",
                    borderWidth: "1px",
                    _hover: {
                        bgColor: "button.tertiary.bg-hover",
                    },
                    _active: {
                        bgColor: "button.tertiary.bg-pressed",
                    },
                },
                destructive: {
                    color: "button.destructive.fg",
                    bgColor: "button.destructive.bg",
                    borderColor: "button.destructive.border",
                    borderWidth: "1px",
                    _hover: {
                        bgColor: "button.destructive.bg-hover",
                    },
                    _active: {
                        bgColor: "button.destructive.bg-pressed",
                    },
                },
                disabled: {
                    color: "button.disabled.fg",
                    bgColor: "button.disabled.bg",
                },
                action: {
                    color: "button.action.fg",
                    bgColor: "button.action.bg",
                    borderColor: "button.action.border",
                    borderWidth: "1px",
                    _hover: {
                        bgColor: "button.action.bg-hover",
                    },
                    _active: {
                        bgColor: "button.action.bg-pressed",
                    },
                },
                link: {
                    color: "action.interactive",
                    _hover: {
                        color: "action.interactive",
                    },
                    _active: {
                        color: "action.interactive-dark",
                    },
                }
            },
        },
    },
};
