import {
    Button,
    ButtonProps,
    IconButton
} from "@chakra-ui/react";
import { BaseRecord, GetListResponse, useTranslate } from "@refinedev/core";
import React from "react";
import { BiQuestionMark } from "react-icons/bi";

export interface ListTaskButtonProps<T extends BaseRecord> extends ButtonProps {
    label: string;
    shouldRefetch?: boolean;
    task?: (data?: GetListResponse<T>) => Promise<void>;
    icon?: React.ReactElement;
    hideText?: boolean;
    accessControl?: {
        enabled?: boolean;
        hideIfUnauthorized?: boolean;
    };
}

export const ListTaskButton = <T extends BaseRecord>({
    label,
    shouldRefetch,
    task,
    icon = <BiQuestionMark size={19} />,
    hideText = false,
    children,
    isLoading,
    ...rest
}: ListTaskButtonProps<T>) => {
    const translate = useTranslate();

    return (
        <>
            {hideText ? (
                <IconButton
                    variant="action"
                    as="span"
                    aria-label={label}
                    isLoading={isLoading}
                    icon={icon}
                    {...rest}
                ></IconButton>
            ) : (
                <Button
                    variant="action"
                    as="span"
                    leftIcon={icon}
                    isLoading={isLoading}
                    {...rest}
                >
                    {children ?? label}
                </Button>
            )}
        </>
    );
};
