import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
} from "@chakra-ui/react";
import { Props } from "chakra-react-select";
import { format, isToday } from "date-fns";
import {
    FieldValues,
    useController,
    UseControllerProps,
} from "react-hook-form";
import {
    convertToDate,
    convertToUnixTimestamp,
    isValidDate,
} from "@bankingright-dashboard/utils";
import {
    SingleDatepicker,
    CalendarPanel,
    Month_Names_Short,
    Weekday_Names_Short,
} from "chakra-dayzed-datepicker";
import { PropsConfigs } from "chakra-dayzed-datepicker/dist/utils/commonTypes";
import { CalendarPanelWithTime } from "@bankingright-dashboard/ui";
import { useState } from "react";

type DatePickerFormControlProps<T extends FieldValues> = UseControllerProps<T> &
    Props & {
        label: string;
        helperText?: string;
        showTime?: boolean;
    };

export const DatePickerFormControl = <T extends FieldValues>({
    control,
    name,
    id,
    label,
    rules,
    helperText,
    options,
    showTime = false,
    isRequired = true,
    isMulti,
    ...props
}: DatePickerFormControlProps<T>) => {
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: rules ?? {
            required: isRequired ? `${label} is required` : undefined,
        },
    });

    const [isOpen, setIsOpen] = useState(false);

    var selectedDate =
        value != undefined
            ? format(
                  convertToDate(value),
                  showTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"
              )
            : undefined;

    return (
        <FormControl isInvalid={!!error} id={id}>
            <FormLabel>{label}</FormLabel>
            <Popover
                placement="auto-start"
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
            >
                <PopoverTrigger>
                    <Button
                        px={5}
                        borderWidth="1px"
                        w="100%"
                        variant="ghost"
                        justifyContent="flex-start"
                    >
                        {selectedDate ?? ""}
                    </Button>
                </PopoverTrigger>
                <PopoverContent minW="max-content">
                    <PopoverArrow />
                    <PopoverBody>
                        <CalendarPanelWithTime
                            onDateChange={(date) => {
                                onChange(convertToUnixTimestamp(date));
                            }}
                            date={convertToDate(value)}
                            showTime={showTime}
                            isShown={isOpen}
                        />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            {helperText && !error && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
            {error?.message && (
                <FormErrorMessage>{error?.message}</FormErrorMessage>
            )}
        </FormControl>
    );
};
