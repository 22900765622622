import { BoxProps, Button, HStack } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import { cacheTokenKey } from "@bankingright-dashboard/providers";

interface ActionsMenuProps extends BoxProps { }

export const ActionsMenu = ({ ...props }: ActionsMenuProps) => {
    const translate = useTranslate();

    const clearCache = () => {
        localStorage.removeItem(cacheTokenKey);
    }

    return (
        <HStack {...props}>
            <Button variant="tertiary" size="sm" onClick={clearCache}>
                { translate(`settings.clearCache`,"Clear cache")}
            </Button>
        </HStack>
    )
}