import { IAccessControl } from "@bankingright-dashboard/interfaces";
import { DetailItem, Show } from "@bankingright-dashboard/ui";
import { VStack } from "@chakra-ui/react";
import { useShow, useTranslate } from "@refinedev/core";

export const AccessControlShow: React.FC = () => {
    const { queryResult } = useShow<IAccessControl>();
    const { data, isLoading } = queryResult;
    const access = data?.data;
    const translate = useTranslate();

    return (
        <VStack align="stretch" spacing={5}>
            <Show isLoading={isLoading}>
                <VStack align="flex-start" spacing={3}>
                    <DetailItem title="User ID" value={access?.userId} />
                </VStack>
            </Show>
        </VStack>
    );
};
