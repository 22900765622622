import { IUserInfo } from "@bankingright-dashboard/interfaces";
import { LoadingIndicator } from "@bankingright-dashboard/ui";
import { Badge, Box, Text, VStack } from "@chakra-ui/react";
import { useGetIdentity } from "@refinedev/core";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const hangfirePath = process.env.REACT_APP_HANGFIRE_PATH;
const managementApiUrl = process.env.REACT_APP_API_URL;
const hangfireCookieDomain = process.env.REACT_APP_HANGFIRE_COOKIE_DOMAIN;

export const HangfireShow: React.FC = () => {
    const { data: user } = useGetIdentity<IUserInfo>();
    const [cookies, setCookie, removeCookie] = useCookies(["hangfire-cookie"]);
    const [isCookieSet, setIsCookieSet] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const hangfireUrl = hangfirePath && managementApiUrl ? `${managementApiUrl}${hangfirePath}` : undefined;

    if (hangfireUrl === undefined) {
        return (
            <VStack>
                <Text>Hangfire is not configured.</Text>
                <Badge colorScheme="red">
                    Please set REACT_APP_HANGFIRE_URL in .env file
                </Badge>
            </VStack>
        );
    }

    useEffect(() => {
        setCookie("hangfire-cookie", user?.token ?? "", {
            path: "/",
            domain: `.${hangfireCookieDomain}`,
            expires: new Date(+(user?.tokenExpiration ?? ""))
        });
        setIsCookieSet(true);
        return () => {
            removeCookie("hangfire-cookie");
        };
    }, []);

    return (
        <>
            {isCookieSet && (
                <Box
                    as="iframe"
                    src={hangfireUrl}
                    minH="100vh"
                    minW="100%"
                    overflow="hidden"
                    onLoad={() => setIsLoading(false)}
                />
            )}
            <LoadingIndicator isLoading={isLoading} />
        </>
    );
};
