import { BackButton } from "@bankingright-dashboard/ui";
import {
    Avatar,
    Box,
    Card,
    CardBody,
    CardHeader,
    Heading,
    HStack,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useGetIdentity, useTranslate } from "@refinedev/core";
import { ColorMenu } from "./color-menu";
import { LanguageMenu } from "./language-menu";
import { IUserInfo } from "@bankingright-dashboard/interfaces";
import { ActionsMenu } from "./actions-menu";

export const UserSettings: React.FC = () => {
    const translate = useTranslate();
    const { data: user } = useGetIdentity<IUserInfo>();
    const username = user?.name ?? "Unknown";

    return (
        <Card bg="defaults.bg-light">
            <CardHeader>
                <HStack spacing={2}>
                    <BackButton />
                    <Heading>
                        {translate("settings.titles.edit", "Settings")}
                    </Heading>
                </HStack>
            </CardHeader>

            <CardBody>
                <Stack spacing={6}>
                    <HStack spacing={5} mb={10}>
                        <Avatar name={username} bg="teal.500" size={"xl"} />
                        <VStack align="flex-start">
                            <Heading size="md">{username}</Heading>
                            <Text>{user?.id}</Text>
                        </VStack>
                    </HStack>
                    <VStack align="flex-start">
                        <Heading size="xs" textTransform="uppercase">
                            {translate("settings.language", "Language")}
                        </Heading>
                        <LanguageMenu />
                    </VStack>
                    <VStack align="flex-start">
                        <Heading size="xs" textTransform="uppercase">
                            {translate("settings.colormode", "Language")}
                        </Heading>
                        <ColorMenu />
                    </VStack>
                    <VStack align="flex-start">
                        <Heading size="xs" textTransform="uppercase">
                            {translate("settings.actions", "Actions")}
                        </Heading>
                        <ActionsMenu />
                    </VStack>
                </Stack>
            </CardBody>
        </Card>
    );
};
