import { ResourceAction } from "@bankingright-dashboard/enums";
import {
    BoxProps,
    Divider,
    IconButton,
    Menu,
    MenuButton,
    MenuButtonProps,
    MenuList,
    VStack
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { DeleteButton, EditButton } from "./";

interface ActionButtonProps extends MenuButtonProps {
    recordItemId: number;
    actions: Array<ResourceAction>;
    resourceName?: string;
}

export const ActionButton = ({
    actions,
    recordItemId,
    resourceName,
    ...props
}: ActionButtonProps) => {
    return (
        <Menu>
            <MenuButton
                as={IconButton}
                icon={<FiMoreVertical size={24} />}
                variant="ghost"
                onClick={(event) => event.stopPropagation()}
                {...props}
            />
            <MenuList minW="120px" py={0}>
                <VStack align="stretch" spacing={0} divider={<Divider />}>
                    {actions.includes(ResourceAction.edit) && (
                        <EditButton
                            recordItemId={recordItemId}
                            size="md"
                            variant="ghost"
                            justifyContent="flex-start"
                            px={2}
                            py={3}
                            rounded="none"
                            resource={resourceName}
                        />
                    )}
                    {actions.includes(ResourceAction.delete) && (
                        <DeleteButton
                            recordItemId={recordItemId}
                            size="md"
                            variant="ghost"
                            color="button.destructive.fg"
                            justifyContent="flex-start"
                            px={2}
                            py={3}
                            rounded="none"
                            resource={resourceName}
                        />
                    )}
                </VStack>
            </MenuList>
        </Menu>
    );
};
