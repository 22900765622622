import { PositionEnrichmentType } from "@bankingright-dashboard/enums";
import { IPositionEnrichment } from "@bankingright-dashboard/interfaces";
import { DetailItem, Show } from "@bankingright-dashboard/ui";
import { Heading, Link, Text, VStack } from "@chakra-ui/react";
import { useShow, useTranslate } from "@refinedev/core";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export const PositionEnrichmentShow: React.FC = () => {
    const { queryResult } = useShow<IPositionEnrichment>();
    const { data, isLoading } = queryResult;
    const enrichment = data?.data;
    const translate = useTranslate();

    return (
        <VStack align="stretch" spacing={5}>
            <Show isLoading={isLoading}>
                <VStack align="flex-start" spacing={3}>
                    <DetailItem title="Id" value={enrichment?.id} />
                    <DetailItem title="Type" value={enrichment?.type} />
                    {enrichment?.type == PositionEnrichmentType.attachment &&
                        enrichment?.attachment && (
                            <VStack align="flex-start">
                                <Heading as="h5" size="sm">
                                    Attachment
                                </Heading>
                                <Link
                                    href={enrichment?.attachment.url}
                                    isExternal
                                >
                                    {enrichment?.attachment.filename}
                                    <ExternalLinkIcon mx={2} />
                                </Link>
                            </VStack>
                        )}
                    {enrichment?.type == PositionEnrichmentType.url &&
                        enrichment?.url && (
                            <DetailItem title="URL" value={enrichment?.url} />
                        )}
                </VStack>
            </Show>
        </VStack>
    );
};
