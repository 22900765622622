import {
    AppConfigurationType,
    ResourceAction,
} from "@bankingright-dashboard/enums";
import { IAppConfiguration } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { Badge, Box, HStack, Text, Tooltip } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { FiInfo } from "react-icons/fi";

export const AppConfigurationList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IAppConfiguration>[]>(
        () => [
            {
                id: "key",
                header: "Key",
                accessorKey: "key",
                enableSorting: false,
                enableColumnFilter: true,
                cell: function render({ row, getValue }) {
                    return (
                        <HStack>
                            <Text>{getValue() as string}</Text>
                            {row.original.description && (
                                <Tooltip
                                    label={row.original.description}
                                    placement="bottom-start"
                                >
                                    <Box>
                                        <FiInfo />
                                    </Box>
                                </Tooltip>
                            )}
                        </HStack>
                    );
                },
            },
            {
                id: "Value",
                header: "value",
                accessorKey: "value",
                enableSorting: false,
                enableColumnFilter: false,
                cell: function render({ row, getValue }) {
                    switch (row.original.type) {
                        case AppConfigurationType.boolean:
                            if (
                                (getValue() as string).toLowerCase() == "true"
                            ) {
                                return (
                                    <Badge colorScheme="green" variant="subtle">
                                        true
                                    </Badge>
                                );
                            } else {
                                return (
                                    <Badge colorScheme="red" variant="subtle">
                                        false
                                    </Badge>
                                );
                            }
                        case AppConfigurationType.enum:
                        case AppConfigurationType.multi:
                            if (row.original.value) {
                                const values = row.original.value.split(",");
                                return (
                                    <HStack spacing={2}>
                                        {values.map(
                                            (value: string, index: number) => (
                                                <Badge key={index}>
                                                    {value}
                                                </Badge>
                                            )
                                        )}
                                    </HStack>
                                );
                            } else {
                                return <></>;
                            }
                        default:
                            return <Text>{getValue() as string}</Text>;
                    }
                },
            },
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            actions={[ResourceAction.edit, ResourceAction.delete]}
        />
    );
};
