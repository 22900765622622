import {
    WealthForecastCategory,
    WealthForecastScenarioType,
} from "@bankingright-dashboard/enums";
import {
    WealthForecastConfiguration,
    WealthForecastScenario,
} from "@bankingright-dashboard/interfaces";
import { SaveButton } from "@bankingright-dashboard/ui";
import {
    Box,
    HStack,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
    VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

type FormData = {
    items: Array<FormItem>;
};

type FormItem = {
    category: WealthForecastCategory;
    scenarios: Array<WealthForecastScenario>;
};

const supportedScenarioTypes = [
    WealthForecastScenarioType.good,
    WealthForecastScenarioType.expected,
    WealthForecastScenarioType.bad,
];

interface WealthForecastConfigurationFormProps {
    data: Array<WealthForecastConfiguration>;
    term: "short" | "long";
    fieldWidth: number;
    onUpdate: (
        data: Array<WealthForecastConfiguration>,
        term: "short" | "long"
    ) => void;
    isLoading: boolean;
    lastUpdate: Date;
}

export const WealthForecastConfigurationForm: React.FC<
    WealthForecastConfigurationFormProps
> = ({ data, term, fieldWidth = 40, onUpdate, isLoading, lastUpdate }) => {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: useMemo(() => {
            return { items: data };
        }, [data]),
    });

    const { fields, replace, remove } = useFieldArray({
        control,
        name: "items",
    });

    const onSubmit = (data: FormData) => {
        onUpdate(data.items, term);
    };

    useEffect(() => {
        const values = data.map((item) => {
            const scenarios = supportedScenarioTypes.map((type) => {
                const scenario = item.scenarios.find(
                    (scenario) => scenario.type === type
                );
                return {
                    type,
                    value: scenario?.value ?? 0,
                };
            });

            return {
                category: item.category,
                scenarios,
            };
        });

        reset({ items: values });
        remove();
        replace(values);
    }, [data, lastUpdate]);

    return (
        <VStack align="flex-start" spacing={5}>
            <HStack spacing={5}>
                <Box w={fieldWidth} />
                <HStack spacing={3}>
                    {supportedScenarioTypes.map((type) => {
                        return (
                            <Text
                                key={type}
                                w={fieldWidth}
                                variant="body-bold"
                                textAlign="center"
                            >
                                {type}
                            </Text>
                        );
                    })}
                </HStack>
            </HStack>
            <VStack spacing={3}>
                {fields.map((item, index) => {
                    return (
                        <HStack key={`${index}.${term}`} spacing={5}>
                            <Text w={fieldWidth} variant="body-bold">
                                {item.category}
                            </Text>
                            <HStack spacing={3}>
                                {supportedScenarioTypes.map(
                                    (type, typeIndex) => {
                                        const scenario = item.scenarios.find(
                                            (scenario) => scenario.type === type
                                        );
                                        return (
                                            <Controller
                                                key={`${index}.${typeIndex}.${term}`}
                                                name={`items.${index}.scenarios.${typeIndex}.value`}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            "Value is required",
                                                    },
                                                }}
                                                render={({
                                                    field: {
                                                        ref,
                                                        ...restField
                                                    },
                                                }) => (
                                                    <NumberInput
                                                        w={fieldWidth}
                                                        precision={2}
                                                        step={0.1}
                                                        {...restField}
                                                    >
                                                        <NumberInputField
                                                            ref={ref}
                                                            name={
                                                                restField.name
                                                            }
                                                            borderColor={
                                                                errors?.items?.[
                                                                    index
                                                                ]?.scenarios?.[
                                                                    typeIndex
                                                                ]
                                                                    ? "red"
                                                                    : "initial"
                                                            }
                                                        />
                                                        <NumberInputStepper>
                                                            <NumberIncrementStepper />
                                                            <NumberDecrementStepper />
                                                        </NumberInputStepper>
                                                    </NumberInput>
                                                )}
                                            />
                                        );
                                    }
                                )}
                            </HStack>
                        </HStack>
                    );
                })}
            </VStack>
            <SaveButton
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
            />
        </VStack>
    );
};
