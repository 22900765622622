import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
} from "@chakra-ui/react";
import { CreatableSelect, Props } from "chakra-react-select";
import React, { KeyboardEventHandler } from "react";
import {
    FieldValues,
    useController,
    UseControllerProps,
} from "react-hook-form";

type CreatableSelectFormControlProps<T extends FieldValues> =
    UseControllerProps<T> &
        Props & {
            label: string;
            helperText?: string;
            type?: CreatableSelectType;
        };

const components = {
    DropdownIndicator: null,
};

const createOption = (label: string) => ({
    label,
    value: label,
});

export enum CreatableSelectType {
    array = "array",
    commaSeparated = "commaSeparated",
}

export const CreatableSelectFormControl = <T extends FieldValues>({
    control,
    name,
    id,
    label,
    rules,
    helperText,
    type = CreatableSelectType.commaSeparated,
    ...props
}: CreatableSelectFormControlProps<T>) => {
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules,
    });

    const [inputValue, setInputValue] = React.useState("");

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case "Enter":
            case " ":
            case "Tab":
                onEnterChange();
                setInputValue("");
                event.preventDefault();
        }
    };

    const onEnterChange = () => {
        if (inputValue?.length > 0) {
            switch (type) {
                case CreatableSelectType.array:
                    const newValues = value
                        ? [...value, inputValue]
                        : [inputValue];

                    onChange(newValues);
                    break;

                case CreatableSelectType.commaSeparated:
                    onChange(value ? `${value},${inputValue}` : inputValue);
            }
        }
    };

    const determineValue = () => {
        switch (type) {
            case CreatableSelectType.array:
                return value?.length > 0
                    ? value?.map((val: any) => createOption(val))
                    : value;
            case CreatableSelectType.commaSeparated:
                return value?.length > 0
                    ? value?.split(",").map((val: any) => createOption(val))
                    : value;
        }
    };

    return (
        <FormControl isInvalid={!!error} id={id}>
            <FormLabel>{label}</FormLabel>
            <CreatableSelect
                {...props}
                components={components}
                isMulti
                isClearable
                menuIsOpen={false}
                inputValue={inputValue}
                name={name}
                ref={ref}
                onChange={(val: any) => {
                    switch (type) {
                        case CreatableSelectType.array:
                            onChange(val.map((val: any) => val.value));
                            break;
                        case CreatableSelectType.commaSeparated:
                            onChange(String(val.map((val: any) => val.value)));
                    }
                }}
                onBlur={onEnterChange}
                value={determineValue()}
                onKeyDown={handleKeyDown}
                onInputChange={(newValue) => setInputValue(newValue)}
            />
            {helperText && !error && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
            {error?.message && (
                <FormErrorMessage>{error?.message}</FormErrorMessage>
            )}
        </FormControl>
    );
};
