import { capitalize } from "@bankingright-dashboard/utils";
import { AspectRatio, Box, BoxProps, Button, Flex, HStack, Menu,  MenuButton, MenuItem, MenuList, useColorMode } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import {
    FiCheck,
    FiChevronDown
} from 'react-icons/fi';

interface ColorMenuProps extends BoxProps { }

const colorModes = ["light", "dark"]

export const ColorMenu = ({ ...props }: ColorMenuProps) => {
    const translate = useTranslate();
    const { colorMode, setColorMode } = useColorMode()

    return (
        <HStack {...props}>
            <Menu>
                <MenuButton as={Button} rightIcon={<FiChevronDown />}>
                    {capitalize(translate(`colormode.${colorMode}`,colorMode))}
                </MenuButton>
                <MenuList>
                    {[...(colorModes || [])].sort().map((mode: string) => (
                        <MenuItem
                            key={mode}
                            value={mode}
                            onClick={() => setColorMode(mode)}
                            icon={
                                <HStack>
                                    <Flex align="center" visibility={mode == colorMode ? "visible" : "hidden"}>
                                        <FiCheck size={20} />
                                    </Flex>
                                </HStack>
                            }
                        >
                            {capitalize(translate(`colormode.${mode}`,mode))}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
            <Button variant="ghost" size="sm" onClick={() => setColorMode("system")}>
                { translate(`colormode.system`,"Use system value")}
            </Button>
        </HStack>
    )
}