import { ILocalization, Translation } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { ListTaskButtonProps } from "@bankingright-dashboard/ui";
import { convertToDate } from "@bankingright-dashboard/utils";
import { Badge, HStack, Text, VStack } from "@chakra-ui/react";
import {
    useApiUrl,
    useCustomMutation,
    useNotification,
    useResource,
    useTranslate,
} from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { BiCloudDownload } from "react-icons/bi";

export const LocalizationList: React.FC = () => {
    const translate = useTranslate();
    const { resource } = useResource();
    const { open } = useNotification();
    const apiUrl = useApiUrl();
    const url = `${apiUrl}/v1/${resource!.name}/update`;
    const { mutate, isLoading } = useCustomMutation();

    const columns = React.useMemo<ColumnDef<ILocalization>[]>(
        () => [
            {
                id: "key",
                header: "Key",
                accessorKey: "key",
                enableColumnFilter: true,
            },
            {
                id: "translations",
                header: "Translations",
                accessorKey: "translations",
                cell: function render({ getValue }) {
                    return (
                        <VStack align="flex-start">
                            {(getValue() as Translation[])?.map(
                                (translation) => {
                                    return (
                                        <HStack align="baseline">
                                            <Badge>
                                                {translation.language}
                                            </Badge>
                                            <Text>{translation.value}</Text>
                                        </HStack>
                                    );
                                }
                            )}
                        </VStack>
                    );
                },
            },
        ],
        []
    );

    const forceUpdate: ListTaskButtonProps<ILocalization> = {
        task: (data) => {
            return new Promise<void>((resolve) => {
                mutate(
                    {
                        url: url,
                        method: "post",
                        values: {},
                    },
                    {
                        onSuccess: () => {
                            open?.({
                                type: "success",
                                message: "Force update",
                                description:
                                    "The latest localizations have been retrieved from the localization service.",
                            });
                        },
                        onSettled: () => {
                            resolve();
                        },
                    }
                );
            });
        },
        icon: <BiCloudDownload size={19} />,
        label: "Force Update",
        isLoading: isLoading,
        shouldRefetch: true,
    };

    return (
        <ResourceList
            columns={columns}
            listHeader={(data) => {
                return (
                    <>
                        {data?.lastUpdated && (
                            <Text mb={2}>
                                Localizations last updated at:{" "}
                                {convertToDate(
                                    data?.lastUpdated
                                ).toLocaleDateString()}{" "}
                                {convertToDate(
                                    data?.lastUpdated
                                ).toLocaleTimeString()}
                            </Text>
                        )}
                    </>
                );
            }}
            additionalButtons={[forceUpdate]}
        />
    );
};
