import {
    AccessControlCreate,
    AccessControlEdit,
    AccessControlList,
    AccessControlShow,
} from "@bankingright-dashboard/access-control";
import {
    ContentTriggerCreate,
    ContentTriggerEdit,
    ContentTriggerList,
    ContentTriggerShow,
    ContentTriggerUserList,
} from "@bankingright-dashboard/content-triggers";
import {
    ImageCreate,
    ImageEdit,
    ImageList,
    ImageShow,
} from "@bankingright-dashboard/images";
import {
    AppConfigurationEdit,
    AppConfigurationList,
} from "@bankingright-dashboard/app-configuration";
import {
    CallToActionCreate,
    CallToActionEdit,
    CallToActionList,
    CallToActionShow,
} from "@bankingright-dashboard/call-to-action";
import { DeviceList, DeviceShow } from "@bankingright-dashboard/devices";
import { HangfireShow } from "@bankingright-dashboard/hangfire";
import { HealthChecksShow } from "@bankingright-dashboard/health-checks";
import { Layout } from "@bankingright-dashboard/layout";
import { LocalizationList } from "@bankingright-dashboard/localization";
import { Login } from "@bankingright-dashboard/login";
import {
    LogList,
    LogShow,
    OperationDetails,
} from "@bankingright-dashboard/logs";
import {
    MessageCreate,
    MessageList,
    MessageShow,
    MessageUserList,
} from "@bankingright-dashboard/messages";
import {
    PositionEnrichmentCreate,
    PositionEnrichmentEdit,
    PositionEnrichmentList,
    PositionEnrichmentShow,
} from "@bankingright-dashboard/position-enrichment";
import {
    PushNotificationCreate,
    PushNotificationList,
} from "@bankingright-dashboard/push-notifications";
import {
    SecurityAuditList,
    SecurityAuditShow,
} from "@bankingright-dashboard/security-audits";
import { UserSettings } from "@bankingright-dashboard/settings";
import {
    SigningTaskApprovalRequestCreate,
    SigningTaskApprovalRequestList,
    SigningTaskApprovalRequestShow,
} from "@bankingright-dashboard/signing-tasks-approval-requests";
import { StatisticsOverview } from "@bankingright-dashboard/statistics";
import { ErrorComponent } from "@bankingright-dashboard/ui";
import {
    UserSegmentCreate,
    UserSegmentEdit,
    UserSegmentList,
    UserSegmentShow,
} from "@bankingright-dashboard/user-segments";
import {
    UserCreate,
    UserEdit,
    UserList,
    UserShow,
} from "@bankingright-dashboard/users";
import { WealthForecastConfigurationOverview } from "@bankingright-dashboard/wealth-forecast-configuration";
import { Authenticated, CanAccess } from "@refinedev/core";
import { NavigateToResource } from "@refinedev/react-router-v6";
import { Outlet, Route, Routes } from "react-router-dom";
import { ReactComponent as Logo } from "./images/bankingright_logo.svg";

export const ShowcaseRoutes = () => {
    return (
        <Routes>
            <Route
                element={
                    <Authenticated key="dashboard" fallback={<Outlet />}>
                        <NavigateToResource resource="calltoaction" />
                    </Authenticated>
                }
            >
                <Route
                    path="/login"
                    element={<Login logo={<Logo width="200px" />} />}
                />
            </Route>
            <Route
                element={
                    <Authenticated key="dashboard" redirectOnFail="/login">
                        <Layout logo={<Logo width="160px" />}>
                            <CanAccess fallback={<ErrorComponent />}>
                                <Outlet />
                            </CanAccess>
                        </Layout>
                    </Authenticated>
                }
            >
                <Route
                    index
                    element={<NavigateToResource resource="calltoaction" />}
                />
                <Route path="calltoaction">
                    <Route index element={<CallToActionList />} />
                    <Route path="create" element={<CallToActionCreate />} />
                    <Route path="edit/:id" element={<CallToActionEdit />} />
                    <Route path="show/:id" element={<CallToActionShow />} />
                </Route>
                <Route path="appconfiguration">
                    <Route index element={<AppConfigurationList />} />
                    <Route path="edit/:id" element={<AppConfigurationEdit />} />
                </Route>
                <Route path="devices">
                    <Route index element={<DeviceList />} />
                    <Route path="show/:id" element={<DeviceShow />} />
                </Route>
                <Route path="messages">
                    <Route index element={<MessageList />} />
                    <Route path="create" element={<MessageCreate />} />
                    <Route path="show/:id" element={<MessageShow />} />
                    <Route path="show/:id/users" element={<MessageUserList/>} />
                </Route>
                <Route path="pushnotifications">
                    <Route index element={<PushNotificationList />} />
                    <Route path="create" element={<PushNotificationCreate />} />
                </Route>
                <Route path="users">
                    <Route index element={<UserList />} />
                    <Route path="create" element={<UserCreate />} />
                    <Route path="show/:id" element={<UserShow />} />
                    <Route path="edit/:id" element={<UserEdit />} />
                </Route>
                <Route path="users/segments">
                    <Route index element={<UserSegmentList />} />
                    <Route path="create" element={<UserSegmentCreate />} />
                    <Route path="edit/:id" element={<UserSegmentEdit />} />
                    <Route path="show/:id" element={<UserSegmentShow />} />
                </Route>
                <Route path="statistics">
                    <Route index element={<StatisticsOverview />} />
                </Route>
                <Route path="logs">
                    <Route index element={<LogList />} />
                    <Route path="show/:id" element={<LogShow />} />
                    <Route
                        path="show/:id/operation"
                        element={<OperationDetails />}
                    />
                </Route>
                <Route path="security/audits">
                    <Route index element={<SecurityAuditList />} />
                    <Route path="show/:id" element={<SecurityAuditShow />} />
                </Route>
                <Route path="localizations">
                    <Route index element={<LocalizationList />} />
                </Route>
                <Route path="positions/enrichments">
                    <Route index element={<PositionEnrichmentList />} />
                    <Route
                        path="create"
                        element={<PositionEnrichmentCreate />}
                    />
                    <Route
                        path="show/:id"
                        element={<PositionEnrichmentShow />}
                    />
                    <Route
                        path="edit/:id"
                        element={<PositionEnrichmentEdit />}
                    />
                </Route>
                <Route path="wealthforecast/configuration">
                    <Route
                        index
                        element={<WealthForecastConfigurationOverview />}
                    />
                </Route>
                <Route path="accesscontrol">
                    <Route index element={<AccessControlList />} />
                    <Route path="create" element={<AccessControlCreate />} />
                    <Route path="edit/:id" element={<AccessControlEdit />} />
                    <Route path="show/:id" element={<AccessControlShow />} />
                </Route>
                <Route path="contenttriggers">
                    <Route index element={<ContentTriggerList />} />
                    <Route path="create" element={<ContentTriggerCreate />} />
                    <Route path="edit/:id" element={<ContentTriggerEdit />} />
                    <Route path="show/:id" element={<ContentTriggerShow />} />
                    <Route path="show/:id/users" element={<ContentTriggerUserList/>} />
                </Route>
                <Route path="processes">
                    <Route index element={<HangfireShow />} />
                </Route>
                <Route path="healthchecks">
                    <Route index element={<HealthChecksShow />} />
                </Route>
                <Route path="signingtasks/approvalrequests">
                    <Route index element={<SigningTaskApprovalRequestList />} />
                    <Route
                        path="create"
                        element={<SigningTaskApprovalRequestCreate />}
                    />
                    <Route
                        path="show/:id"
                        element={<SigningTaskApprovalRequestShow />}
                    />
                </Route>
                <Route path="images">
                    <Route index element={<ImageList />} />
                    <Route path="create" element={<ImageCreate />} />
                    <Route path="edit/:id" element={<ImageEdit />} />
                    <Route path="show/:id" element={<ImageShow />} />
                </Route>
                <Route path="settings">
                    <Route index element={<UserSettings />} />
                </Route>
                <Route path="*" element={<ErrorComponent />} />
            </Route>
        </Routes>
    );
};
