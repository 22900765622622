import { FilterType, ResourceAction } from "@bankingright-dashboard/enums";
import { IDevice } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const DeviceList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IDevice>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableSorting: false,
                enableColumnFilter: true,
            },
            {
                id: "user_id",
                header: "User ID",
                accessorKey: "userId",
                enableSorting: false,
                enableColumnFilter: true,
            },
            {
                id: "device_name",
                header: "Device Name",
                accessorKey: "deviceName",
                enableSorting: false,
                enableColumnFilter: true,
            },
            {
                id: "platform",
                header: "Platform",
                accessorKey: "platform",
                enableSorting: false,
                enableColumnFilter: true,
                meta: {
                    hidden: true,
                    filter: {
                        type: FilterType.select,
                        options: ["ios", "android", "web"],
                    },
                },
            },
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            actions={[ResourceAction.show, ResourceAction.delete]}
            hasSelection={true}
        />
    );
};
