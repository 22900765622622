import { FormControl} from "@chakra-ui/react";
import { useForm } from "@refinedev/react-hook-form";
import { InputFormControl, SelectFormControl} from "@bankingright-dashboard/table";
import { AppConfigurationType, FormType } from "@bankingright-dashboard/enums";
import { IAppConfiguration } from "@bankingright-dashboard/interfaces";
import { covertToOptionArray } from "@bankingright-dashboard/utils";
import { Create, Edit } from "@bankingright-dashboard/ui";

interface AppConfigurationFormProps {
    type: FormType
}

export const AppConfigurationForm = ({ type }: AppConfigurationFormProps) => {

    const {
        control,
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        register,
        formState: { errors },
        resetField,
    } = useForm<IAppConfiguration>();

    const renderValueFormControl = () => {

        if (queryResult?.data?.data == undefined) return (<></>)

        const { key, description, regex, regexExplanation, type, enumOptions } = queryResult?.data?.data
        const pattern = regex != undefined ? { value: RegExp(regex), message: regexExplanation ?? "" } : undefined

        switch (type ?? AppConfigurationType.string) {
            case AppConfigurationType.boolean:
                return (
                    <SelectFormControl
                        control={control}
                        name="value"
                        label={"Value"}
                        options={covertToOptionArray(["true", "false"])}
                        helperText={description}
                        rules={{ required: "Please indicate 'true' or 'false'" }}
                        selectedOptionStyle="check"
                    />
                )

            case AppConfigurationType.enum:
            case AppConfigurationType.multi:
                if (enumOptions != undefined) {
                    return (
                        <SelectFormControl
                            isMulti={type == AppConfigurationType.multi}
                            control={control}
                            id="value"
                            name="value"
                            label="Value"
                            options={covertToOptionArray(enumOptions.split(","))}
                            helperText={description}
                            rules={{ required: "Please provide at least one value" }}
                        />
                    )
                }
                else {
                    return (
                        <InputFormControl
                            name={"value"}
                            label={"Value"}
                            control={control}
                            helperText={description}
                            rules={pattern}
                        />
                    )
                }

            case AppConfigurationType.integer:
                return (
                    <FormControl mb="3" isInvalid={!!errors?.status} label={description}>
                        <InputFormControl
                            name={"value"}
                            label={"Value"}
                            control={control}
                            helperText={description}
                            rules={pattern ?? { value: RegExp("^[0-9]*$"), message: "Only numbers are allowed" }}
                        />
                    </FormControl>
                )

            default:
                return (
                    <InputFormControl
                        name={"value"}
                        label={"Value"}
                        control={control}
                        helperText={description}
                        rules={pattern}
                    />
                )
        }
    }

    const form = () => {
        return (
            <>
                <InputFormControl
                    name={"key"}
                    label={"Key"}
                    control={control}
                    isReadOnly={true}
                />
                {renderValueFormControl()}
            </>
        )
    }

    switch (type) {
        case FormType.create:
            return (
                <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Create>
            )
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            )
    }
};