import { normalize } from "@bankingright-dashboard/utils";
import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import {
    AccessControlContext,
    useCan,
    useLink,
    useNavigation,
    useResource,
    userFriendlyResourceName,
    useTranslate,
    useUserFriendlyName,
} from "@refinedev/core";
import React, { useContext } from "react";
import { FiList } from "react-icons/fi";

interface ListButtonProps extends ButtonProps {
    icon?: React.ReactElement;
    hideText?: boolean;
    resourceNameOrRouteName?: string;
    accessControl?: {
        enabled?: boolean;
        hideIfUnauthorized?: boolean;
    };
}

export const ListButton = ({
    icon = <FiList />,
    hideText = false,
    resourceNameOrRouteName,
    accessControl,
    children,
    ...props
}: ListButtonProps) => {
    const accessControlContext = useContext(AccessControlContext);

    const accessControlEnabled =
        accessControl?.enabled ??
        accessControlContext.options.buttons.enableAccessControl;

    const hideIfUnauthorized =
        accessControl?.hideIfUnauthorized ??
        accessControlContext.options.buttons.hideIfUnauthorized;

    const getFriendlyName = useUserFriendlyName();
    const { resource } = useResource(resourceNameOrRouteName);

    const { listUrl: generateListUrl } = useNavigation();
    const Link = useLink();

    const translate = useTranslate();

    const { data } = useCan({
        resource: resource?.name,
        action: "list",
        queryOptions: {
            enabled: accessControlEnabled,
        },
        params: {
            resource,
        },
    });

    const disabledTitle = () => {
        if (data?.can) return "";
        else if (data?.reason) return data.reason;
        else
            return translate(
                "buttons.notAccessTitle",
                "You don't have permission to access"
            );
    };

    const listUrl = generateListUrl(resource!.name);

    if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
        return null;
    }

    return (
        <Link to={listUrl} replace={false}>
            {hideText ? (
                <IconButton
                    icon={icon}
                    variant="action"
                    aria-label={translate(
                        `${normalize(resource!.name)}.titles.list`,
                        getFriendlyName(resource!.name, "plural")
                    )}
                    isDisabled={data?.can === false}
                    title={disabledTitle()}
                    {...props}
                />
            ) : (
                <Button
                    variant="action"
                    isDisabled={data?.can === false}
                    leftIcon={icon}
                    title={disabledTitle()}
                    {...props}
                >
                    {children ??
                        translate(
                            `${normalize(resource!.name!)}.titles.list`,
                            getFriendlyName(resource!.name, "plural")
                        )}
                </Button>
            )}
        </Link>
    );
};
