import { FormType, ImageCategory } from "@bankingright-dashboard/enums";
import { Image } from "@bankingright-dashboard/interfaces";
import {
    AttachmentWithFile,
    DropzoneFormControl,
    SelectFormControl,
    SwitchFormControl,
} from "@bankingright-dashboard/table";
import { Create, Edit } from "@bankingright-dashboard/ui";
import { covertToOptionArray } from "@bankingright-dashboard/utils";
import { VStack } from "@chakra-ui/react";
import {
    useApiUrl,
    useCreate,
    useCreateMany,
    useCustomMutation,
    useNotification,
    useResource,
    useTranslate,
} from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";

interface ImageFormProps {
    type: FormType;
}

type AttachmentList = {
    attachments: Array<AttachmentWithFile>;
};

export const ImageForm = ({ type }: ImageFormProps) => {
    const translate = useTranslate();
    const { resource } = useResource();
    const apiUrl = useApiUrl();
    const { mutate, isLoading } = useCustomMutation();
    const { open } = useNotification();

    const {
        refineCore: { formLoading, onFinish, redirect },
        saveButtonProps,
        formState: { errors, isValid },
        control,
        handleSubmit,
        getValues,
        trigger,
    } = useForm<Image>();

    const convertToMultiPart = (images: AttachmentList) => {
        const filenameAsId = getValues().filenameAsId as boolean;
        const formData = new FormData();

        const attachment = images.attachments[0];
        const fileNameWithoutExtension = attachment.filename.split(".")[0];
        const imageId = filenameAsId ? fileNameWithoutExtension : attachment.id

        formData.append(
            imageId, 
            attachment.file!, 
            attachment.filename
        );

        const body = {
            category: getValues().category,
            id: imageId,
            filename: attachment.filename,
            mimeType: attachment.mimeType,
            extension: attachment.extension,
        };

        formData.append("body", JSON.stringify({ ...body }));
        return formData;
    };

    const convertToMultiPartBulk = (images: AttachmentList) => {
        const filenameAsId = getValues().filenameAsId as boolean;
        const formData = new FormData();
        images.attachments.forEach((attachment: AttachmentWithFile) => {
            formData.append(
                filenameAsId ? attachment.filename.split(".")[0] : attachment.id,
                attachment.file!,
                attachment.filename
            );
        });

        const body = {
            images: images.attachments.map((attachment: AttachmentWithFile) => {
                return {
                    category: getValues().category,
                    id: filenameAsId ? attachment.filename.split(".")[0] : attachment.id,
                    filename: attachment.filename,
                    mimeType: attachment.mimeType,
                    extension: attachment.extension,
                };
            }),
        };

        formData.append("body", JSON.stringify({ ...body }));

        return formData;
    };

    // Override the default save operation
    saveButtonProps.onClick = (e: any) => {
        e.preventDefault();

        // First convert the normal request to a multi-part and include attachments if provided (and submit it if valid)
        const list = getValues() as AttachmentList;

        // If there are multiple attachments, we need to use the bulk endpoint. We cannot use the default useCreateMany hook here
        // as we are dealing with FormData and not JSON
        if (list.attachments.length > 1) {
            if (!isValid) {
                trigger();
                return;
            }

            const url = `${apiUrl}/v1/${resource!.name}/create/bulk`;
            mutate(
                {
                    url: url,
                    method: "post",
                    values: convertToMultiPartBulk(list),
                },
                {
                    onSuccess: () => {
                        open?.({
                            type: "success",
                            message: translate(
                                `notifications.success`,
                                "Successful"
                            ),
                            description: translate(
                                `notifications.createSuccessBulk`,
                                { resource: resource!.name }
                            ),
                        });
                        redirect("list");
                    },
                }
            );
        } else {
            handleSubmit(
                () => {
                    onFinish(
                        convertToMultiPart(getValues() as AttachmentList)
                    ).catch((e) => {
                        // don't do anything
                    });
                },
                () => false
            )(e);
        }
    };

    const form = () => {
        return (
            <VStack spacing={5}>
                <SelectFormControl
                    name="category"
                    label="Category"
                    control={control}
                    options={covertToOptionArray(Object.values(ImageCategory))}
                    isRequired={true}
                />
                {type === FormType.create && (
                    <SwitchFormControl
                        name={"filenameAsId"}
                        label="Filename as ID"
                        control={control}
                        isRequired={false}
                    />
                )}
                <DropzoneFormControl
                    control={control}
                    id="attachments"
                    name="attachments"
                    label="Image"
                    multiple={true}
                />
            </VStack>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading || isLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit
                    isLoading={formLoading || isLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Edit>
            );
    }
};
