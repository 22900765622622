import { ThemeOverride } from "@chakra-ui/react";

export const colorPrimitives: ThemeOverride = {
    colors: {
        blue: {
            100: "#F2F3F9",
            200: "#EBECF9",
            300: "#C2C5EE",
            400: "#ABA8E7",
            500: "#343DC8",
            600: "#1A23AF",
            700: "#151D93",
            900: "#000336",
        },
        green: {
            500: "#058145",
            600: "#006333",
            700: "#004725",
            bright: "#1ED4AA",
            "bright-soft": "#B7F1E4",
        },
        gray: {
            100: "#E6ECF2",
            200: "#CBD5E0",
            300: "#84898F",
            400: "#545454",
            500: "#3E3E3E",
            700: "#272727",
            900: "#171717",
            white: "#FFFFFF",
            black: "#000000",
        },
        orange: {
            500: "#FFA500",
            300: "#FFEBCD",
        },
        red: {
            100: "#FEF1F1",
            300: "#FED7D7",
            500: "#C81515",
        },
    },
};