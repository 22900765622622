import {
    AccessControlRole,
    FilterType,
    ResourceAction,
} from "@bankingright-dashboard/enums";
import { IAccessControl } from "@bankingright-dashboard/interfaces";
import { FilterConfiguration, ResourceList } from "@bankingright-dashboard/table";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const AccessControlList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IAccessControl>[]>(
        () => [
            {
                id: "user_id",
                header: "User ID",
                accessorKey: "userId",
                enableColumnFilter: true,
                meta: {
                    identifier: true,
                },
            },
            {
                id: "role",
                header: "Role",
                accessorKey: "role",
                enableColumnFilter: true,
                meta: {
                    filter: {
                        type: FilterType.select,
                        options: Object.values(AccessControlRole),
                    },
                },
            },
        ],
        []
    );

    const additionalFilters: Array<FilterConfiguration> = [
        {
            id: "include_no_role_users",
            label: "Users without role",
            type: FilterType.select,
            options: ["true", "false"],
            defaultValue: "true",
            hidden: true,
        },
    ];

    return (
        <ResourceList
            columns={columns}
            actions={[ResourceAction.edit, ResourceAction.delete]}
            additionalFilters={additionalFilters}
        />
    );
};
