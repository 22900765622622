import {
    AccountInfo,
    IPublicClientApplication,
    SilentRequest,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { IUserInfo } from "@bankingright-dashboard/interfaces";
import { AuthBindings } from "@refinedev/core";

interface BankingRightAuthProviderProps {
    tokenRequest: any;
    tokenKey?: string;
    instance: IPublicClientApplication;
}

export const BankingRightAuthProvider = ({
    tokenRequest,
    tokenKey = "bankingright-auth",
    instance,
}: BankingRightAuthProviderProps): AuthBindings => {
    const request: SilentRequest = {
        ...tokenRequest,
        account: instance.getActiveAccount(),
    };

    return {
        login: async () => {
            instance.loginRedirect();
            return {
                success: true,
            };
        },
        register: async () => {
            return {
                success: true,
                redirectTo: "/",
            };
        },
        forgotPassword: async () => {
            return {
                success: true,
                redirectTo: "/",
            };
        },
        updatePassword: async () => {
            return {
                success: true,
                redirectTo: "/",
            };
        },
        logout: async () => {
            instance.logoutRedirect();
            return {
                success: true,
            };
        },
        check: async () => {
            try {
                await instance.initialize();
                if (instance.getActiveAccount()) {
                    const token = await instance.acquireTokenSilent(request);

                    if (token.expiresOn && token.expiresOn < new Date()) {
                        console.log(
                            "Access Token expired and could not renew it, logging out"
                        );
                        return {
                            authenticated: false,
                            redirectTo: "/login",
                        };
                    }

                    if (token.accessToken && token.accessToken.length > 0) {
                        localStorage.setItem(tokenKey, token.accessToken);
                        localStorage.setItem(
                            "token-expiration",
                            token.expiresOn?.valueOf().toString() ?? ""
                        );

                        return {
                            authenticated: true,
                        };
                    } else {
                        console.log(
                            "No Access Token found during Silent Token request, logging out, please check your scopes!"
                        );
                        return {
                            authenticated: false,
                        };
                    }
                } else {
                    return {
                        authenticated: false,
                        redirectTo: "/login",
                    };
                }
            } catch (e) {
                return {
                    authenticated: false,
                    redirectTo: "/login",
                };
            }
        },
        onError: async (error) => {
            console.error(error);
            return { error };
        },
        getPermissions: async () => {
            return null;
        },
        getIdentity: async (): Promise<IUserInfo | undefined> => {
            await instance.initialize();
            const account = instance.getActiveAccount();
            if (account === null || account === undefined) {
                return undefined;
            } else {
                return {
                    id: account.localAccountId,
                    name: account.name,
                    email: account.username,
                    avatar: undefined,
                    token: localStorage.getItem(tokenKey) ?? undefined,
                    tokenExpiration:
                        localStorage.getItem("token-expiration") ?? undefined,
                };
            }
        },
    };
};
