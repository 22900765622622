import { FormType } from "@bankingright-dashboard/enums";
import { IPushNotification } from "@bankingright-dashboard/interfaces";
import {
    CreatableSelectFormControl,
    DatePickerFormControl,
    MarkdownEditorFormControl,
    InputFormControl,
    InputFormType,
} from "@bankingright-dashboard/table";
import { Create, Edit } from "@bankingright-dashboard/ui";
import { Checkbox, FormControl, FormLabel, VStack } from "@chakra-ui/react";
import { useForm } from "@refinedev/react-hook-form";

interface PushNotificationFormProps {
    type: FormType;
}

export const PushNotificationForm = ({ type }: PushNotificationFormProps) => {
    const {
        control,
        refineCore: { formLoading },
        saveButtonProps,
        register,
        formState: { errors },
    } = useForm<IPushNotification>();

    const form = () => {
        return (
            <>
                <CreatableSelectFormControl
                    control={control}
                    id="userId"
                    name="userId"
                    label="User ID(s)"
                    placeholder="Keep empty to send to all users"
                />
                <CreatableSelectFormControl
                    control={control}
                    id="deviceId"
                    name="deviceId"
                    label="Device ID(s)"
                    placeholder="Keep empty to send to all devices (of the specified users if provided)"
                />
                <DatePickerFormControl
                    control={control}
                    id="date"
                    name="date"
                    label="Delivery date"
                    isRequired={false}
                    showTime={true}
                />
                <InputFormControl
                    name="title"
                    label="Title"
                    control={control}
                />
                <InputFormControl name="body" label="Body" control={control} />
                <InputFormControl
                    name={"badgeCount"}
                    label={"Badge count"}
                    control={control}
                    isRequired={false}
                    rules={{
                        pattern: {
                            value: RegExp("^[0-9]*$"),
                            message: "Only numbers are allowed",
                        },
                    }}
                />
                <InputFormControl
                    name="customData"
                    label="Custom data"
                    control={control}
                    isRequired={false}
                    type={InputFormType.textarea}
                />
                <FormControl mt={5} isInvalid={!!errors?.status}>
                    <FormLabel>Additional options:</FormLabel>
                    <VStack align="flex-start">
                        <Checkbox id="silent" {...register("silent")}>
                            Send silently
                        </Checkbox>
                        <Checkbox id="immediate" {...register("immediate")}>
                            Send immediately
                        </Checkbox>
                    </VStack>
                </FormControl>
            </>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
    }
};
