import { Box, Heading, Spacer, Text, VStack } from "@chakra-ui/react";
import { useNavigation, useShow } from "@refinedev/core";
import dompurify from "dompurify";

import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { MessageBodyType } from "@bankingright-dashboard/enums";
import { IMessage } from "@bankingright-dashboard/interfaces";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { convertToDate } from "@bankingright-dashboard/utils";
import { ButtonWithLabelProps, DetailItem, Show } from "@bankingright-dashboard/ui";
import { FiUsers } from "react-icons/fi";

export const MessageShow: React.FC = () => {
    const { queryResult } = useShow<IMessage>();
    const { data, isLoading } = queryResult;
    const message = data?.data;
    const { list } = useNavigation();

    const renderMessageBody = () => {
        switch (message?.bodyType ?? MessageBodyType.plain) {
            case MessageBodyType.markdown:
                return (
                    <ReactMarkdown
                        remarkPlugins={[gfm]}
                        components={ChakraUIRenderer()}
                        children={message?.body ?? ""}
                        skipHtml
                    />
                );
            case MessageBodyType.html:
                return (
                    <Box
                        dangerouslySetInnerHTML={{
                            __html: dompurify.sanitize(message?.body ?? ""),
                        }}
                    />
                );
            case MessageBodyType.plain:
                return <Box>{message?.body}</Box>;
        }
    };

    const usersButton: ButtonWithLabelProps = {
        label: "Users",
        leftIcon: <FiUsers />,
        variant: "tertiary",
        "aria-label": "Users",
        onClick: () => {
            list("messages-users")
        },
    };

    return (
        <Show isLoading={isLoading} additionalButtons={[usersButton]}>
            <VStack spacing={10} align="flex-start">
                {message && (
                    <VStack align="flex-start">
                        <DetailItem title="Id" value={message?.id} />
                        <DetailItem title="User ID" value={message?.userId} />
                        <DetailItem
                            title="Date"
                            value={convertToDate(
                                message.date
                            ).toLocaleDateString()}
                        />
                        <DetailItem title="Title" value={message?.title} />
                        <VStack align="flex-start">
                            <Heading as="h5" size="sm">
                                Body
                            </Heading>
                            {renderMessageBody()}
                        </VStack>
                    </VStack>
                )}
                {message?.statistics && (
                    <VStack align="flex-start" spacing={3}>
                        <Heading fontSize="2xl">Statistics</Heading>0
                        <DetailItem
                            title="Number of users"
                            value={message?.statistics?.total}
                        />
                        <DetailItem
                            title="Number of times read"
                            value={message?.statistics?.read}
                        />
                    </VStack>
                )}
            </VStack>
        </Show>
    );
};
