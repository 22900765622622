import {
    Button,
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    Textarea,
    VStack,
} from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import { useState, useRef } from "react";

import {
    FieldErrors,
    FieldError,
    FieldValues,
    UseFormRegister,
    FieldPath,
    ValidationRule,
    UseControllerProps,
    useController,
} from "react-hook-form";

type PasswordInputFormControlProps<T extends FieldValues> =
    UseControllerProps<T> & {
        label?: string;
        helperText?: string;
    };

export const PasswordInputFormControl = <T extends FieldValues>({
    control,
    name,
    label,
    helperText,
    ...props
}: PasswordInputFormControlProps<T>) => {
    const password = useController({
        name,
        control,
        rules: {
            required: "Password is required",
            minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
            },
        },
    });

    const repeatPassword = useController({
        control,
        name: "repeatPassword" as FieldPath<T>,
        rules: {
            validate: (value) =>
                value === password.field.value || "The passwords do not match",
        },
    });

    const translate = useTranslate();
    const [show, setShow] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const handleClick = () => setShow(!show);
    const handleRepeatPasswordClick = () =>
        setShowRepeatPassword(!showRepeatPassword);

    return (
        <FormControl
            isInvalid={
                !!password.fieldState.error || !!repeatPassword.fieldState.error
            }
            {...props}
        >
            {label && <FormLabel>{label}</FormLabel>}
            <VStack spacing={3}>
                <InputGroup size="md">
                    <Input
                        id={password.field.name}
                        pr="4.5rem"
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                        onChange={password.field.onChange}
                        onBlur={password.field.onBlur}
                        ref={password.field.ref}
                        value={password.field.value}
                    />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? "Hide" : "Show"}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <InputGroup size="md">
                    <Input
                        id={repeatPassword.field.name}
                        pr="4.5rem"
                        type={showRepeatPassword ? "text" : "password"}
                        placeholder="Repeat password"
                        onChange={repeatPassword.field.onChange}
                        onBlur={repeatPassword.field.onBlur}
                        ref={repeatPassword.field.ref}
                        value={repeatPassword.field.value}
                    />
                    <InputRightElement width="4.5rem">
                        <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleRepeatPasswordClick}
                        >
                            {showRepeatPassword ? "Hide" : "Show"}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </VStack>

            {helperText && !password.fieldState.error && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
            <FormErrorMessage>
                {password.fieldState.error?.message ??
                    repeatPassword.fieldState.error?.message}
            </FormErrorMessage>
        </FormControl>
    );
};
