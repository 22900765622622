import React from "react";
import {
    Box,
    chakra,
    Container,
    Text,
    HStack,
    VStack,
    Flex,
    useColorModeValue,
    useBreakpointValue,
    Heading,
} from "@chakra-ui/react";

export interface TimelineProps {
    title?: string;
    data: TimelineData[];
}

export interface TimelineData {
    id: number;
    date: string;
    title?: string;
    description: string;
    status: string;
    icon?: React.ReactNode;
    color?: string;
}

export const Timeline = ({ title, data }: TimelineProps) => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const isDesktop = useBreakpointValue({ base: false, md: true });

    return (
        <Container maxWidth="7xl" p={{ base: 2, sm: 10 }}>
            {title && (
                <Heading
                    fontSize="2xl"
                    fontWeight="bold"
                    mb={18}
                    textAlign="center"
                >
                    {title}
                </Heading>
            )}
            {data.map((milestone) => (
                <Flex key={milestone.id} mb="10px">
                    {/* Desktop view(left card) */}
                    {isDesktop && milestone.id % 2 === 0 && (
                        <>
                            <EmptyCard />
                            <LineWithDot />
                            <Card {...milestone} />
                        </>
                    )}

                    {/* Mobile view */}
                    {isMobile && (
                        <>
                            <LineWithDot />
                            <Card {...milestone} />
                        </>
                    )}

                    {/* Desktop view(right card) */}
                    {isDesktop && milestone.id % 2 !== 0 && (
                        <>
                            <Card {...milestone} />

                            <LineWithDot />
                            <EmptyCard />
                        </>
                    )}
                </Flex>
            ))}
        </Container>
    );
};

const Card = ({ id, title, description, date, icon, color }: TimelineData) => {
    // For even id show card on left side
    // For odd id show card on right side
    const isEvenId = id % 2 == 0;
    let borderWidthValue = isEvenId ? "15px 15px 15px 0" : "15px 0 15px 15px";
    let leftValue = isEvenId ? "-15px" : "unset";
    let rightValue = isEvenId ? "unset" : "-15px";

    const isMobile = useBreakpointValue({ base: true, md: false });
    if (isMobile) {
        leftValue = "-15px";
        rightValue = "unset";
        borderWidthValue = "15px 15px 15px 0";
    }

    return (
        <HStack
            flex={1}
            p={{ base: 3, sm: 6 }}
            bg={useColorModeValue("gray.100", "gray.800")}
            spacing={5}
            rounded="lg"
            alignItems="center"
            pos="relative"
            _before={{
                content: `""`,
                w: "0",
                h: "0",
                borderColor: `transparent ${useColorModeValue(
                    "#edf2f6",
                    "#1a202c"
                )} transparent`,
                borderStyle: "solid",
                borderWidth: borderWidthValue,
                position: "absolute",
                left: leftValue,
                right: rightValue,
                display: "block",
            }}
        >
            <Box>
                <Text color={isEvenId ? "others.decoration.3" : "others.decoration.4"}>
                    {date}
                </Text>
                <VStack spacing={2} mb={3} textAlign="left">
                    <Heading
                        fontSize="xl"
                        lineHeight={1.2}
                        fontWeight="bold"
                        w="100%"
                    >
                        {title}
                    </Heading>
                    <Text fontSize="md">{description}</Text>
                </VStack>
            </Box>

            <Box
                p="7px"
                bg={color ?? "transparent"}
                rounded="full"
                position="absolute"
                top="10px"
                left="100%"
                transform="translate(-130%, 0)"
            >
                {icon && icon}
            </Box>
        </HStack>
    );
};

const LineWithDot = () => {
    return (
        <Flex
            pos="relative"
            alignItems="center"
            mr={{ base: "40px", md: "40px" }}
            ml={{ base: "0", md: "40px" }}
        >
            <chakra.span
                position="absolute"
                left="50%"
                height="calc(100% + 10px)"
                border="1px solid"
                borderColor={useColorModeValue("gray.200", "gray.700")}
                top="0px"
            ></chakra.span>
            <Box pos="relative" p="10px">
                <Box
                    pos="absolute"
                    top="0"
                    left="0"
                    bottom="0"
                    right="0"
                    width="100%"
                    height="100%"
                    backgroundSize="cover"
                    backgroundRepeat="no-repeat"
                    backgroundPosition="center center"
                    bg={useColorModeValue("gray.600", "gray.200")}
                    borderRadius="100px"
                    backgroundImage="none"
                    opacity={1}
                ></Box>
            </Box>
        </Flex>
    );
};

const EmptyCard = () => {
    return (
        <Box
            flex={{ base: 0, md: 1 }}
            p={{ base: 0, md: 6 }}
            bg="transparent"
        ></Box>
    );
};
