import { ThemeOverride } from "@chakra-ui/react";
import "@fontsource/inter/400.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"

export const textStyles: ThemeOverride = {
    fonts: {
        body: "Inter",
        heading: "Inter",
    }
}