import {
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Textarea,
} from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";

import {
    FieldValues,
    RegisterOptions,
    UseControllerProps,
    useController,
} from "react-hook-form";

export enum InputFormType {
    input = "input",
    numberInput = "numberInput",
    textarea = "textarea",
}

type InputFormControlProps<T extends FieldValues> = UseControllerProps<T> &
    FormControlProps & {
        label: string;
        helperText?: string;
        type?: InputFormType;
    };

export const InputFormControl = <T extends FieldValues>({
    control,
    name,
    label,
    helperText,
    rules,
    isRequired = true,
    defaultValue,
    type = InputFormType.input,
    ...props
}: InputFormControlProps<T>) => {
    const translate = useTranslate();

    const inputRules: RegisterOptions<T> = {
        valueAsNumber: type == InputFormType.numberInput ? true : undefined,
        required: isRequired
            ? translate(
                  "validations.required",
                  { field: label },
                  `${label} is required`
              )
            : undefined,
    };

    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: inputRules,
        defaultValue,
    });

    const InputField = type == InputFormType.textarea ? Textarea : Input;

    return (
        <FormControl isInvalid={!!error} {...props}>
            <FormLabel>{label}</FormLabel>
            <InputField
                name={name}
                type={type == InputFormType.numberInput ? "number" : "text"}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ""}
            />
            {helperText && !error && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};
