import { ThemeOverride } from "@chakra-ui/react";

export const colorComponents: any = {
    semanticTokens: {
        colors: {
            action: {
                control: {
                    border: { default: "gray.300" },
                    "bg-disabled": { default: "gray.200" },
                },
                interactive: { default: "blue.500" },
                "interactive-dark": {
                    default: "blue.600",
                },
                "interactive-disabled": {
                    default: "blue.400",
                },
                toggle: {
                    "surface-off": { default: "gray.200" },
                    "fg-off": { default: "gray.400" },
                },
                "bg-accent": { default: "blue.200" },
                tab: { border: { default: "gray.200" } },
            },
            alert: {
                "bg-error": { default: "red.300" },
                "icon-error": { default: "red.500" },
                "bg-neutral": { default: "gray.200" },
                "icon-neutral": { default: "gray.400" },
                "bg-success": {
                    default: "green.bright-soft",
                },
                "icon-success": {
                    default: "green.bright",
                },
                "bg-warning": { default: "orange.300" },
                "icon-warning": { default: "orange.500" },
            },
            button: {
                primary: {
                    bg: { default: "blue.500" },
                    fg: { default: "gray.white" },
                    "bg-hover": { default: "blue.600" },
                    "bg-pressed": { default: "blue.700" },
                },
                secondary: {
                    bg: { default: "green.500" },
                    fg: { default: "gray.white" },
                    "bg-hover": { default: "green.600" },
                    "bg-pressed": { default: "green.700" },
                },
                tertiary: {
                    bg: { default: "gray.white" },
                    fg: { default: "blue.500" },
                    "bg-hover": { default: "blue.100" },
                    "bg-pressed": { default: "blue.200" },
                },
                destructive: {
                    bg: { default: "gray.white" },
                    fg: { default: "red.500" },
                    border: { default: "red.500" },
                    "bg-hover": { default: "red.100" },
                    "bg-pressed": { default: "red.300" },
                },
                action: {
                    bg: { default: "gray.white" },
                    fg: { default: "gray.400" },
                    border: { default: "gray.200" },
                    "bg-hover": { default: "gray.100" },
                    "bg-pressed": { default: "gray.200" },
                },
                disabled: {
                    fg: { default: "gray.white" },
                    bg: { default: "gray.300" },
                },
            },
            defaults: {
                "bg-light": { default: "gray.white" },
                "bg-accent": { default: "blue.100" },
                "fg-light": { default: "gray.white" },
                "border-divider": { default: "gray.200" },
            },
            others: {
                badge: {
                    bg: { default: "blue.500" },
                    fg: { default: "gray.white" },
                },
                decoration: {
                    "1": "#FFFFFF",
                    "2": "#FFBF61",
                    "3": "#1BBF99",
                    "4": "#717AFF",
                    "5": "#151D93",
                    "6": "#FF9800",
                },
                scrollbar: {
                    bg: { default: "gray.200" },
                    fg: { default: "gray.300" },
                },
                tooltip: { bg: { default: "blue.900" } },
            },
            progress: {
                dark: { default: "blue.500" },
                light: { default: "blue.200" },
            },
            sidepanel: {
                surface: { 
                    default: "blue.100",
                },
                "bg-item": { default: "blue.100" },
                "fg-item": { default: "gray.900" },
                "bg-item-active": { default: "blue.100" },
                "fg-item-active": { default: "blue.500" },
                category: { default: "gray.400" },
            },
            text: {
                titles: { default: "gray.900" },
                headings: { default: "gray.400" },
                body: { default: "gray.900" },
                subtext: { default: "gray.500" },
                shortkey: { default: "gray.300" },
                placeholder: { default: "gray.300" },
            },
        },
    },
};
