import {
    ContentTriggerCategory,
    ContentTriggerContentType,
    ContentTriggerLocation,
    ContentTriggerStatus,
    FormType,
} from "@bankingright-dashboard/enums";
import {
    IContentTrigger,
    Image,
    IUserSegment,
} from "@bankingright-dashboard/interfaces";
import {
    ChooseImageFormControl,
    ColorPickerFormControl,
    CreatableSelectFormControl,
    CreatableSelectType,
    DatePickerFormControl,
    InputFormControl,
    InputFormType,
    MarkdownEditorFormControl,
    SelectFormControl,
    SwitchFormControl,
} from "@bankingright-dashboard/table";
import { Create, Edit } from "@bankingright-dashboard/ui";
import { covertToOptionArray } from "@bankingright-dashboard/utils";
import { Button, Heading, useDisclosure, VStack } from "@chakra-ui/react";
import { HttpError, useSelect } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { useState } from "react";

interface ContentTriggerFormProps {
    type: FormType;
    suggestedColors?: string[];
    suggestedColorsDark?: string[];
}

export const ContentTriggerForm = ({
    type,
    suggestedColors,
    suggestedColorsDark,
}: ContentTriggerFormProps) => {
    const {
        control,
        refineCore: { formLoading },
        saveButtonProps,
        setValue,
        formState: { errors },
        watch,
    } = useForm<IContentTrigger, HttpError, IContentTrigger>({
        defaultValues: {
            content: {
                contentType: ContentTriggerContentType.markdown,
            },
        },
    });

    const segmentSelectProps = useSelect<IUserSegment>({
        resource: "segments",
        meta: {
            parent: "users",
        },
    });

    const form = () => {
        return (
            <VStack align="stretch" spacing={10}>
                <VStack align="flex-start" spacing={3}>
                    {type == FormType.create && (
                        <>
                            <CreatableSelectFormControl
                                control={control}
                                id="userIds"
                                name="userIds"
                                label="User ID(s)"
                                placeholder="Keep empty to send to all users"
                                type={CreatableSelectType.array}
                            />
                            <SelectFormControl
                                control={control}
                                id="userSegments"
                                name="userSegments"
                                label="User Segments(s)"
                                isMulti={true}
                                placeholder="Keep empty to send to all users"
                                type={CreatableSelectType.array}
                                {...segmentSelectProps}
                            />
                        </>
                    )}
                    <SelectFormControl
                        control={control}
                        name="status"
                        label="Status"
                        options={covertToOptionArray(
                            Object.values(ContentTriggerStatus)
                        )}
                        defaultValue={ContentTriggerStatus.active}
                        selectedOptionStyle="check"
                        isRequired={true}
                    />
                    <InputFormControl
                        name="priority"
                        label="Priority"
                        control={control}
                        type={InputFormType.numberInput}
                    />
                    <SelectFormControl
                        control={control}
                        name="category"
                        label="Category"
                        options={covertToOptionArray(
                            Object.values(ContentTriggerCategory)
                        )}
                        rules={{ required: "Please provide a category'" }}
                        selectedOptionStyle="check"
                    />
                    <InputFormControl
                        name="subCategory"
                        label="Subcategory"
                        control={control}
                        isRequired={false}
                    />
                    <SelectFormControl
                        control={control}
                        name="location"
                        label="Location"
                        options={covertToOptionArray(
                            Object.values(ContentTriggerLocation)
                        )}
                        rules={{ required: "Please provide a location'" }}
                        selectedOptionStyle="check"
                    />
                    <SwitchFormControl
                        name={"dismissible"}
                        label="Can be dismissed"
                        control={control}
                        isRequired={false}
                    />
                    <DatePickerFormControl
                        control={control}
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        isRequired={true}
                        showTime={true}
                    />
                    <DatePickerFormControl
                        control={control}
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        isRequired={true}
                        showTime={true}
                    />
                    <InputFormControl
                        name={"maxShowCount"}
                        label={"Max show (view count)"}
                        control={control}
                        type={InputFormType.numberInput}
                    />
                </VStack>
                <VStack align="flex-start" spacing={3}>
                    <Heading fontSize="2xl">Content</Heading>
                    <InputFormControl
                        name={"content.title"}
                        label={"Title"}
                        control={control}
                    />
                    <MarkdownEditorFormControl
                        control={control}
                        id="content.body"
                        name="content.body"
                        label="Body"
                    />
                    <InputFormControl
                        name={"content.buttonTitle"}
                        label={"Button title"}
                        control={control}
                        isRequired={false}
                    />
                    <InputFormControl
                        name={"content.buttonUrl"}
                        label={"Button Url"}
                        control={control}
                        isRequired={false}
                    />
                    <ChooseImageFormControl
                        name={"content.imageId"}
                        label={"Image"}
                        control={control}
                        isRequired={false}
                    />
                    <ColorPickerFormControl
                        name={"content.baseColor"}
                        label={"Base color"}
                        control={control}
                        suggestedColors={suggestedColors}
                    />
                    <ColorPickerFormControl
                        name={"content.baseColorDark"}
                        label={"Base color dark-mode"}
                        control={control}
                        suggestedColors={suggestedColorsDark}
                    />
                </VStack>
                {/* {type == FormType.create && (
                    <DropzoneFormControl
                        control={control}
                        id="attachments"
                        name="attachments"
                        label="Attachments"
                    />
                )} */}
            </VStack>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
    }
};
