import {
    Box, FormControl, FormErrorMessage,
    FormHelperText,
    FormLabel
} from "@chakra-ui/react";
import MDEditor from '@uiw/react-md-editor';
import { Props } from "chakra-react-select";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";

type MarkdownEditorFormControlProps<T extends FieldValues> = UseControllerProps<T> &
    Props & {
        label: string;
        helperText?: string;
    };

export const MarkdownEditorFormControl = <T extends FieldValues>({ control, name, id, label, rules, helperText, options, isMulti, ...props }: MarkdownEditorFormControlProps<T>) => {
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules,
    });

    return (
        <FormControl mb={4} isInvalid={!!error} id={id}>
            <FormLabel>{label}</FormLabel>
            <Box data-color-mode="light">
                <MDEditor
                    value={value}
                    onChange={onChange}                    
                />
            </Box>
            {helperText && !error &&
                <FormHelperText>{helperText}</FormHelperText>
            }
            {error?.message &&
                <FormErrorMessage>
                    {error?.message}
                </FormErrorMessage>
            }
        </FormControl>
    );
};