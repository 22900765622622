import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import React from "react";
import { FiCheck } from "react-icons/fi";

export interface SaveButtonProps extends ButtonProps {
    icon?: React.ReactElement;
    hideText?: boolean;
}

export const SaveButton: React.FC<SaveButtonProps> = ({
    icon = <FiCheck size="18px" />,
    hideText = false,
    children,
    ...props
}) => {
    const translate = useTranslate();

    return hideText ? (
        <IconButton
            variant="secondary"
            aria-label={translate("buttons.save", "Save")}
            icon={icon}
            {...props}
        />
    ) : (
        <Button variant="secondary" leftIcon={icon} {...props}>
            {children ?? translate("buttons.save", "Save")}
        </Button>
    );
};
