import {
    BoxProps,
    Button,
    ButtonProps,
    Checkbox,
    HStack,
    IconButton,
    Text,
    VStack,
    useDisclosure,
} from "@chakra-ui/react";
import { UilFilter } from "@iconscout/react-unicons";
import { useTranslate } from "@refinedev/core";
import React from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export interface FilterBoxSelectionProps {
    checked: boolean;
    indeterminate: boolean;
    onChange: (event: unknown) => void;
}

interface FilterBoxProps extends BoxProps {
    selection?: FilterBoxSelectionProps;
}

export const FilterBox = ({
    selection,
    children,
    ...props
}: FilterBoxProps) => {
    const translate = useTranslate();
    const { isOpen, onToggle } = useDisclosure();

    return (
        <VStack
            align="flex-start"
            borderWidth="1px"
            borderRadius={1}
            borderColor="button.action.border"
            padding={4}
            gap={4}
            {...props}
        >
            <HStack spacing={5}>
                {selection && (
                    <Checkbox
                        isChecked={selection?.checked ?? false}
                        isIndeterminate={selection?.indeterminate ?? false}
                        onChange={selection?.onChange}
                    />
                )}
                <Button
                    variant="link"
                    padding={0}
                    leftIcon={<UilFilter size={18} />}
                    rightIcon={
                        isOpen ? (
                            <FiChevronUp size={18} />
                        ) : (
                            <FiChevronDown size={18} />
                        )
                    }
                    justifyContent="flex-start"
                    onClick={onToggle}
                >
                    {translate("buttons.filter", "Filter")}
                </Button>
            </HStack>
            {isOpen && children}
        </VStack>
    );
};
