import {
    FilterType,
    MessageStatus,
    ResourceAction,
} from "@bankingright-dashboard/enums";
import { IDevice } from "@bankingright-dashboard/interfaces";
import {
    FilterConfiguration,
    ResourceList,
} from "@bankingright-dashboard/table";
import { convertToDate } from "@bankingright-dashboard/utils";
import { Badge, Text, VStack } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const MessageList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IDevice>[]>(
        () => [
            {
                id: "title",
                header: "Title",
                accessorKey: "title",
                enableColumnFilter: true,
                meta: {
                    width: "70%",
                },
            },
            {
                id: "date",
                header: "Date",
                accessorKey: "date",
                cell: function render({ getValue }) {
                    return (
                        <VStack align="flex-start">
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </VStack>
                    );
                },
            },
            {
                id: "status",
                header: "Status",
                accessorKey: "status",
                cell: function render({ row, getValue }) {
                    const value = (getValue() as string) ?? "active";

                    var colorScheme = "green";
                    switch (value) {
                        case MessageStatus.active:
                            colorScheme = "green";
                            break;
                        case MessageStatus.draft:
                            colorScheme = "blue";
                            break;
                        default:
                            colorScheme = "green";
                    }
                    return (
                        <Badge colorScheme={colorScheme} variant="subtle">
                            {value}
                        </Badge>
                    );
                },
            },
        ],
        []
    );

    const additionalFilters: Array<FilterConfiguration> = [
        {
            id: "id",
            label: "ID",
            type: FilterType.input,
        },
        {
            id: "user_id",
            label: "User ID",
            type: FilterType.input,
        },
        {
            id: "from_date",
            label: "From date",
            type: FilterType.date,
        },
        {
            id: "to_date",
            label: "To date",
            type: FilterType.date,
        },
    ];

    return (
        <ResourceList
            columns={columns}
            actions={[ResourceAction.show, ResourceAction.delete]}
            additionalFilters={additionalFilters}
            hasSelection={true}
        />
    );
};
