import { IUserInfo } from "@bankingright-dashboard/interfaces";
import { normalize } from "@bankingright-dashboard/utils";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Avatar,
    Box,
    BoxProps,
    CloseButton,
    Drawer,
    DrawerContent,
    Flex,
    FlexProps,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    VStack,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import {
    CanAccess,
    ITreeMenu,
    LayoutProps,
    useGetIdentity,
    useLink,
    useLogout,
    useMenu,
    useNavigation,
    useTranslate,
} from "@refinedev/core";
import React, { useState } from "react";
import {
    FiChevronDown,
    FiChevronsLeft,
    FiChevronsRight,
    FiMenu,
} from "react-icons/fi";

interface BankingRightLayoutProps extends LayoutProps {
    logo?: React.ReactElement;
}

export const Layout: React.FC<BankingRightLayoutProps> = ({
    logo,
    children,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isFullMenu, setIsFullMenu] = useState(true);

    const onChangeMenuSize = () => {
        setIsFullMenu(!isFullMenu);
    };

    const onOpenMenu = () => {
        setIsFullMenu(true);
        onOpen();
    }

    return (
        <Box
            minH="100vh"
            bg="defaults.bg-accent"
        >
            <MobileNav logo={logo} isFullMenu={isFullMenu} onOpen={onOpenMenu} />
            <SidebarContent
                onClose={() => onClose}
                display={{ base: "none", md: "block" }}
                isOpen={isOpen}
                isFullMenu={isFullMenu}
                onChangeMenuSize={onChangeMenuSize}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <SidebarContent
                        onClose={onClose}
                        isOpen={isOpen}
                        isFullMenu={isFullMenu}
                        onChangeMenuSize={onChangeMenuSize}
                    />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <Box ml={{ base: 0, md: isFullMenu ? 60 : 16 }} p="4">
                {children}
            </Box>
        </Box>
    );
};

interface SidebarProps extends BoxProps {
    onClose: () => void;
    isOpen: boolean;
    isFullMenu: boolean;
    onChangeMenuSize: () => void;
}

const SidebarContent = ({
    onClose,
    isOpen,
    isFullMenu,
    onChangeMenuSize,
    ...rest
}: SidebarProps) => {
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    const translate = useTranslate();

    const createMenuItems = (menuItems: ITreeMenu[]) => {
        const flattened = menuItems
            .flatMap((item) => [item, ...item.children])
            .filter((item) => item.route != undefined)
            .map((item) => {
                item.children = [];
                return item;
            });

        const categories = flattened.reduce((acc: string[], item) => {
            const category = item.meta?.category;
            if (category && !acc.includes(category)) {
                acc.push(category);
            }
            return acc;
        }, []);

        const menu: ITreeMenu[] = categories.map((category) => {
            return {
                key: category,
                name: category,
                children: flattened.filter(
                    (item) => item.meta?.category === category
                ),
            };
        });

        const otherItems = flattened.filter((item) => !item.meta?.category);
        if (otherItems.length > 0) {
            menu.push({
                key: "other",
                name: "other",
                children: otherItems,
            });
        }

        return menu;
    };

    const menu = createMenuItems(menuItems);
    const indexes = menu.map((_, index) => index);
    const showGrouping = menu.length > 1 && isFullMenu;

    const renderNavItems = (
        tree: ITreeMenu[],
        selectedKey: string,
        onClose: () => void
    ): JSX.Element[] => {
        return tree.map((item: ITreeMenu) => {
            const { key, name, children, meta } = item;

            if (children && children.length > 0) {
                return (
                    <AccordionItem border="none" key={key}>
                        <AccordionButton
                            justifyContent="space-between"
                            px={5}
                            py={showGrouping ? 2 : 0}
                            display={showGrouping ? "flex" : "none"}
                        >
                            <Text
                                variant="sub-bold"
                                textColor="sidepanel.category"
                            >
                                {translate(
                                    `menu.category.${normalize(name)}`,
                                    name
                                )}
                            </Text>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel
                            px={2}
                            pt={showGrouping ? 2 : 0}
                            pb={showGrouping ? 5 : 0}
                        >
                            <VStack align="stretch">
                                {renderNavItems(children, selectedKey, onClose)}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                );
            }

            const isSelected = key === selectedKey;
            return (
                <CanAccess
                    key={key}
                    resource={name.toLowerCase()}
                    action="list"
                >
                    <NavItem
                        key={selectedKey}
                        route={key}
                        isSelected={isSelected}
                        icon={meta?.icon}
                        onClick={onClose}
                        isFullMenu={isFullMenu}
                    >
                        {translate(`${normalize(name)}.titles.menu`, name)}
                    </NavItem>
                </CanAccess>
            );
        });
    };

    return (
        <Box
            // transition="0.5s ease"
            bg="sidepanel.surface"
            w={{ base: "full", md: isFullMenu ? 60 : 16 }}
            pos="fixed"
            h="full"
            overflowY="auto"
            overflowX="hidden"
            pt={isFullMenu ? 0 : 8}
            pb={{ base: 0, md: 24 }}
            sx={{
                scrollbarWidth: "thin",
                scrollbarColor: "var(--chakra-colors-gray-300) transparent",                
            }}
            {...rest}
        >
            <CloseButton
                display={{ base: "flex", md: "none" }}
                onClick={onClose}
                mx={3}
                my={5}
            />

            <Accordion allowMultiple defaultIndex={indexes}>
                {renderNavItems(menu, selectedKey, onClose)}
            </Accordion>
            {!isOpen && (
                <Box
                    w={isFullMenu ? 60 : 16}
                    h={10}
                    pos="fixed"
                    bottom={0}
                    cursor="pointer"
                    pointerEvents="none"
                >
                    <Flex
                        h="100%"
                        align="center"
                        justifyContent={isFullMenu ? "flex-end" : "center"}
                        mr={isFullMenu ? 3 : 0}
                    >
                        <IconButton
                            variant="link"
                            icon={
                                isFullMenu ? (
                                    <FiChevronsLeft size={20} />
                                ) : (
                                    <FiChevronsRight size={20} />
                                )
                            }
                            aria-label="close"
                            onClick={onChangeMenuSize}
                            pointerEvents="auto"
                        />
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: React.ReactNode;
    isFullMenu?: boolean;
    route?: string;
    isSelected: Boolean;
    children: any;
}
const NavItem = ({
    icon,
    route,
    isFullMenu = true,
    isSelected,
    children,
    ...rest
}: NavItemProps) => {
    const Link = useLink();

    return (
        <Link
            to={route ?? "#"}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
        >
            <Flex
                align="center"
                px={isFullMenu ? 3 : 1}
                py={3}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                justifyContent={isFullMenu ? "flex-start" : "center"}
                _hover={{
                    bg: "sidepanel.bg-item-active",
                    color: "sidepanel.fg-item-active",
                }}
                bg={
                    isSelected
                        ? "sidepanel.bg-item-active"
                        : "sidepanel.bg-item"
                }
                {...rest}
            >
                <HStack gap={3}>
                    {icon && (
                        <Box
                            color={
                                isSelected
                                    ? "sidepanel.fg-item-active"
                                    : "sidepanel.fg-item"
                            }
                        >
                            {icon}
                        </Box>
                    )}
                    {isFullMenu && (
                        <Text
                            variant={isSelected ? "body-bold" : "bold"}
                            color={
                                isSelected
                                    ? "sidepanel.fg-item-active"
                                    : "sidepanel.fg-item"
                            }
                        >
                            {children}
                        </Text>
                    )}
                </HStack>
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    logo?: React.ReactElement;
    isFullMenu: boolean;
    onOpen: () => void;
}

const MobileNav = ({ logo, isFullMenu, onOpen, ...rest }: MobileProps) => {
    const { data: user } = useGetIdentity<IUserInfo>();
    const { mutate: logout } = useLogout();
    const { push } = useNavigation();
    const translate = useTranslate();

    const settings = () => {
        push("/settings");
    };

    return (
        <Flex
            height="20"
            alignItems="center"
            bg="header.bg"
            justifyContent="space-between"
            {...rest}
        >
            <IconButton
                display={{ base: "flex", md: "none" }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
                ml={4}
            />
            <Spacer display={{ base: "none", md: "flex" }} />
            <Box
                position="fixed"
                display={{ base: "none", md: "flex" }}
                w={isFullMenu ? 60 : 16}
                h="100%"
                pl={{ base: 0, md: 5 }}
                bg="sidepanel.surface"
            >
                {logo && isFullMenu && logo}
            </Box>
            <Box display={{ base: "flex", md: "none" }}>{logo && logo}</Box>

            <HStack
                spacing={{ base: "3", md: "6" }}
                ml={{ base: 0, md: 1 }}
                px={{ base: 4, md: 4 }}
            >
                <Flex alignItems={"center"}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: "none" }}
                        >
                            <HStack>
                                <Avatar
                                    name={user?.name ?? "Unknown"}
                                    bg="teal.500"
                                    size={"sm"}
                                />
                                <VStack
                                    display={{ base: "none", md: "flex" }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2"
                                >
                                    <Text fontSize="sm">
                                        {user?.name ?? "Unknown"}
                                    </Text>
                                </VStack>
                                <Box display={{ base: "none", md: "flex" }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => settings()}>
                                {translate("settings.settings", "Settings")}
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => logout()}>
                                {translate("logout.logout", "Logout")}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};
