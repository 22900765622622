import { ICallToAction } from "@bankingright-dashboard/interfaces";
import { Show } from "@bankingright-dashboard/ui";
import {
    Heading, Text
} from "@chakra-ui/react";
import { useShow, useTranslate } from "@refinedev/core";

export const CallToActionShow: React.FC = () => {
    const { queryResult } = useShow<ICallToAction>();
    const { data, isLoading } = queryResult;
    const callToAction = data?.data;
    const translate = useTranslate();

    return (
        <Show isLoading={isLoading}>
            <Heading as="h5" size="sm">
                Id
            </Heading>
            <Text mt={2}>{callToAction?.id}</Text>

            <Heading as="h5" size="sm" mt={4}>
                {translate("pages.calltoaction.appVersion", "App Version")}
            </Heading>
            <Text mt={2}>{callToAction?.appVersion}</Text>

            <Heading as="h5" size="sm" mt={4}>
                {translate("pages.calltoaction.platformVersion", "Platform Version")}
            </Heading>
            <Text mt={2}>{callToAction?.platformVersion}</Text>

            <Heading as="h5" size="sm" mt={4}>
                {translate("pages.calltoaction.platform", "Platform")}
            </Heading>
            <Text mt={2}>{callToAction?.platform}</Text>

            <Heading as="h5" size="sm" mt={4}>
                {translate("pages.calltoaction.blocking", "Blocking")}
            </Heading>
            <Text mt={2}>{callToAction?.blocking ? "Yes" : "No"}</Text>

            <Heading as="h5" size="sm" mt={4}>
                {translate("pages.calltoaction.title", "Title")}
            </Heading>
            <Text mt={2}>{callToAction?.title}</Text>

            <Heading as="h5" size="sm" mt={4}>
                {translate("pages.calltoaction.body", "Body")}
            </Heading>
            <Text mt={2}>{callToAction?.body}</Text>

            <Heading as="h5" size="sm" mt={4}>
                {translate("pages.calltoaction.actionUrl", "Action URL")}
            </Heading>
            <Text mt={2}>{callToAction?.actionUrl}</Text>

            <Heading as="h5" size="sm" mt={4}>
                {translate("pages.calltoaction.buttonText", "Button Text")}
            </Heading>
            <Text mt={2}>{callToAction?.buttonText}</Text>
        </Show>
    );
};