import {
    FilterType,
    ResourceAction,
    LogType,
    LogSource,
} from "@bankingright-dashboard/enums";
import { ILog } from "@bankingright-dashboard/interfaces";
import { FilterConfiguration, ResourceList } from "@bankingright-dashboard/table";
import { convertToDate } from "@bankingright-dashboard/utils";
import {
    Badge,
    Link as ChakraLink,
    HStack,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useLink } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const LogList: React.FC = () => {
    const Link = useLink();

    const columns = React.useMemo<ColumnDef<ILog>[]>(
        () => [
            {
                id: "type",
                header: "Type",
                accessorKey: "type",
                enableColumnFilter: true,
                meta: {
                    filter: {
                        type: FilterType.select,
                        options: Object.values(LogType),
                    },
                },
                cell: function render({ getValue }) {
                    const type = getValue() as string;
                    switch (type) {
                        case "request":
                            return <Badge colorScheme="blue">{type}</Badge>;
                        case "exception":
                            return <Badge colorScheme="red">{type}</Badge>;
                        default:
                            return <Badge>{type}</Badge>;
                    }
                },
            },
            {
                id: "date",
                header: "Date",
                accessorKey: "date",
                cell: function render({ getValue }) {
                    return (
                        <HStack align="flex-start">
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </HStack>
                    );
                },
            },
            {
                id: "verb",
                header: "Verb",
                accessorKey: "verb",
                enableColumnFilter: false,
            },
            {
                id: "endpoint",
                header: "Endpoint / Message",
                accessorKey: "endpoint",
                enableColumnFilter: false,
                cell: function render({ row, getValue }) {
                    const endpoint = getValue() as string;
                    const message = row.original.message;
                    if (endpoint && endpoint.length > 0) {
                        return (
                            <Text>{endpoint}</Text>
                        )
                    }
                    else {
                        return (
                            <Text>{message ?? ""}</Text>
                        )
                    }
                },
                meta: {
                    width: "50%",
                },
            },
            {
                id: "resultCode",
                header: "Status",
                accessorKey: "resultCode",
                enableColumnFilter: true,
                cell: function render({ getValue }) {
                    const resultCode = getValue() as number;
                    if (resultCode < 200 || resultCode >= 300) {
                        return (
                            <Badge colorScheme="red" variant="subtle">
                                {resultCode}
                            </Badge>
                        );
                    } else {
                        return (
                            <Badge colorScheme="green" variant="subtle">
                                {resultCode}
                            </Badge>
                        );
                    }
                },
            },
            {
                id: "operation_id",
                header: "Operation ID",
                accessorKey: "operationId",
                enableColumnFilter: true,
                cell: function render(props) {
                    const source = props.table.initialState.columnFilters.find((filter) => filter.id === "source")?.value;
                    return (
                        <ChakraLink
                            as={Link}
                            to={`/logs/show/${
                                props.getValue() as string
                            }/operation?source=${source}`}
                            onClick={(event: any) => {
                                event.stopPropagation();
                            }}
                            onMouseUp={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            {props.getValue() as string}
                        </ChakraLink>
                    );
                },
            },
        ],
        []
    );

    const additionalFilters: Array<FilterConfiguration> = [
        {
            id: "source",
            label: "Source",
            type: FilterType.select,
            options: Object.values(LogSource),
            isRequired: true,
            defaultValue: LogSource.api,
            passValueToShow: true,
        },
        {
            id: "id",
            label: "ID",
            type: FilterType.input,
        },
        {
            id: "sub_operation_id",
            label: "Sub Operation ID",
            type: FilterType.input,
            passValueToShow: true,
        },
        {
            id: "endpoint",
            label: "Endpoint",
            type: FilterType.input,
        },
        {
            id: "session_id",
            label: "Session ID",
            type: FilterType.input,
        },
        {
            id: "device_id",
            label: "Device ID",
            type: FilterType.input,
        },
        {
            id: "ip",
            label: "IP",
            type: FilterType.input,
        },
        {
            id: "from_date",
            label: "From Date",
            type: FilterType.dateTime,
        },
        {
            id: "to_date",
            label: "To Date",
            type: FilterType.dateTime,
        },
    ];

    return (
        <ResourceList
            columns={columns}
            additionalFilters={additionalFilters}
            actions={[ResourceAction.show]}
            refineCoreProps={{ pagination: { pageSize: 25 } }}
            size="sm"
        />
    );
};
