import {
    Box,
    BoxProps,
    Image as ChakraImage,
    Flex,
    Text,
    VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";

interface ImageCardProps extends BoxProps {
    name: string;
    url: string;
    fallbackUrl?: string;
    subTitle?: string;
    date?: Date;
}

export const ImageCard = ({
    name,
    url,
    fallbackUrl = undefined,
    subTitle = undefined,
    date = undefined,
    ...props
}: ImageCardProps) => {
    return (
        <Flex
            direction="column"
            spacing={1}
            align="flex-start"
            border="1px"
            borderColor="defaults.border-divider"
            shadow="sm"
            rounded="md"
            bg="repeating-conic-gradient(rgba(255,255,255,100) 0 90deg, rgb(242,243,249,20) 0 180deg) 0 0/6% 7%;"
            h="300px"
            overflow="hidden"
            {...props}
        >
            <ChakraImage
                objectFit="scale-down"
                src={url}
                fallbackSrc={fallbackUrl}
                h="75%"
                w="100%"
            />
            <VStack
                spacing={0}
                p={2}
                px={2}
                align="flex-start"
                bg="defaults.bg-accent"
                w="100%"
                minH="25%"
                flexShrink={0}
            >
                <Text
                    fontWeight="semibold"
                    fontSize="sm"
                    w="100%"
                    noOfLines={1}
                >
                    {name}
                </Text>
                {subTitle && <Text mb={-1} fontSize="sm">{subTitle}</Text>}
                {date && (
                    <Text fontSize="sm">
                        {format(date, "yyyy-MM-dd HH:mm")}
                    </Text>
                )}
            </VStack>
        </Flex>
    );
};
