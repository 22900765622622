import { Box, Button, Flex, Spacer, VStack } from "@chakra-ui/react";
import { useLogin } from "@refinedev/core";
import { useTranslate } from "@refinedev/core";

interface LoginProps {
    logo?: React.ReactElement;
}

export const Login: React.FC<LoginProps> = ({ logo }) => {
    const translate = useTranslate();

    const { mutate: login } = useLogin();
    return (
        <Flex h="100vh" align="center" justifyContent="center" bg="defaults.bg-light">
            <VStack textAlign="center" p={16} spacing={12} bg="defaults.bg-accent" borderColor="defaults.border-divider" borderRadius={1} borderWidth={1}>
                <Box>
                    {logo}
                </Box>
                <Button variant="primary" size="lg" onClick={() => login({})}>
                    {translate("pages.login.button", "Sign in")}
                </Button>
            </VStack>
        </Flex>
    );
};
