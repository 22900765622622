import { MessageUser } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const MessageUserList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<MessageUser>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: true,
            },
            {
                id: "didRead",
                header: "Did Read",
                accessorKey: "didRead",
                enableColumnFilter: false,
            }
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            resourceName="messages-users"
            refineCoreProps={{
                resource: "messages-users",
            }}
            hasBackButton={true}
        />
    );
};
