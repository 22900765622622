import React from "react";
import { useTranslate } from "@refinedev/core";
import { IconButton, Button, ButtonProps } from "@chakra-ui/react";
import { BiExport } from "react-icons/bi";

export interface ExportButtonProps extends ButtonProps {
    icon?: React.ReactElement;
    hideText?: boolean
    resourceNameOrRouteName?: string
    isLoading: boolean
    accessControl?: {
        enabled?: boolean;
        hideIfUnauthorized?: boolean;
    };
}

export const ExportButton: React.FC<ExportButtonProps> = ({
    icon = <BiExport size={19}/>,
    isLoading,
    hideText = false,
    children,
    ...rest
}) => {
    const translate = useTranslate();

    return hideText ? (
        <IconButton
            variant="action"
            aria-label={translate("buttons.export", "Export")}
            isLoading={isLoading}
            icon={icon}
            {...rest}
        />        
    ) : (
        <Button
            variant="action"
            isLoading={isLoading}
            leftIcon={icon}
            {...rest}
        >
            {children ?? translate("buttons.export", "Export")}
        </Button>
    );
};