import { isValidDate } from "@bankingright-dashboard/utils";
import { Button, Center, Flex, VStack } from "@chakra-ui/react";
import {
    CalendarPanel,
    Month_Names_Short,
    Weekday_Names_Short,
} from "chakra-dayzed-datepicker";
import { PropsConfigs } from "chakra-dayzed-datepicker/dist/utils/commonTypes";
import {
    differenceInMinutes,
    format,
    isToday,
    setHours,
    setMinutes
} from "date-fns";
import React, { useEffect, useState } from "react";

interface CalendarPanelWithTimeProps {
    showTime?: boolean;
    onDateChange: (date: Date) => void;
    date?: Date;
    isShown?: boolean;
}

export const CalendarPanelWithTime: React.FC<CalendarPanelWithTimeProps> = (
    props
) => {
    const { showTime, onDateChange, date, isShown } = props;
    const ref = React.useRef<HTMLButtonElement>(null);
    const [dateInView, setDateInView] = useState(date);
    const [hasUpdatedDateInView, setHasUpdatedDateInView] = useState(false);

    var isTodaySelected = date != undefined ? isToday(date) : false;

    const generateOptions = () => {
        const options = [];
        for (let i = 0; i < 24; i++) {
            const hour = i < 10 ? `0${i}` : `${i}`;
            options.push(`${hour}:00`, `${hour}:30`);
        }
        return options;
    };

    const getNearestTimeOption = (offset = 0) => {
        var now = new Date();
        if (date && isValidDate(date)) now = date;
        const options = generateOptions();
        let nearestOptionIndex = 0;
        let minDifference = Infinity;

        options.forEach((option, index) => {
            const [hours, minutes] = option.split(":").map(Number);
            const optionDate = setMinutes(setHours(now, hours), minutes);
            const difference = Math.abs(differenceInMinutes(now, optionDate));
            if (difference < minDifference) {
                minDifference = difference;
                nearestOptionIndex = index;
            }
        });

        // Apply the offset and ensure the index is within bounds
        nearestOptionIndex = Math.max(
            0,
            Math.min(options.length - 1, nearestOptionIndex + offset)
        );

        return options[nearestOptionIndex];
    };

    const nearestTimeOption = getNearestTimeOption(
        date && isValidDate(date) ? 3 : 5
    );

    const handleOnDateSelected = (props: {
        date: Date;
        nextMonth: boolean;
        prevMonth: boolean;
        selectable: boolean;
        selected: boolean;
        today: boolean;
    }) => {
        const { date: newDate } = props;
        if (date && isValidDate(date)) {
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const updatedDate = setMinutes(setHours(newDate, hours), minutes);
            onDateChange(updatedDate);
        } else {
            onDateChange(newDate);
        }
    };

    const selectedOption = (option: string) => {
        if (!option || option === "" || !date || !isValidDate(date))
            return false;
        return option === format(date, "HH:mm");
    };

    const propsConfigs: PropsConfigs = {
        triggerBtnProps: {
            width: "100%",
            justifyContent: "flex-start",
        },
        dayOfMonthBtnProps: {
            defaultBtnProps: {
                _hover: {
                    background: "button.tertiary.bg-hover",
                },
            },
            selectedBtnProps: {
                background: "button.primary.bg",
                color: "button.primary.fg",
                _hover: {
                    color: "button.primary.fg",
                    background: "button.primary.bg-hover",
                },
            },
            todayBtnProps: {
                background: !isTodaySelected
                    ? "button.tertiary.bg-pressed"
                    : "button.primary.bg",
                color: !isTodaySelected
                    ? "button.tertiary.fg"
                    : "button.primary.fg",
            },
        },
    };

    useEffect(() => {
        if (isShown && ref.current) {
            ref.current.scrollIntoView(false);
            if (!hasUpdatedDateInView) {
                setHasUpdatedDateInView(true);
            }
        }
    }, [isShown]);

    useEffect(() => {
        if (hasUpdatedDateInView) {
            setDateInView(date);
        }
    }, [hasUpdatedDateInView]);

    return (
        <Flex gap={1} maxH="300px">
            <CalendarPanel
                dayzedHookProps={{
                    showOutsideDays: true,
                    onDateSelected: handleOnDateSelected,
                    selected: date,
                    date: dateInView ?? new Date(),
                }}
                configs={{
                    dateFormat: "yyyy-MM-dd",
                    monthNames: Month_Names_Short,
                    dayNames: Weekday_Names_Short,
                    firstDayOfWeek: 0,
                }}
                propsConfigs={propsConfigs}
            />
            {showTime && (
                <VStack
                    overflowY="auto"
                    gap={0}
                    border="1px"
                    borderColor="gray.200"
                    align="stretch"
                    position="relative"
                >
                    <Center
                        fontWeight="bold"
                        borderBottom="1px"
                        borderColor="gray.100"
                        px={5}
                        py={3}
                        position="sticky"
                        alignSelf="flex-start"
                        top={0}
                        zIndex={1}
                        background="white"
                    >
                        Time
                    </Center>
                    {generateOptions().map((option) => (
                        <Button
                            variant="action"
                            minH="40px"
                            border="none"
                            key={option}
                            rounded="none"
                            fontSize="sm"
                            ref={option == nearestTimeOption ? ref : undefined}
                            color={
                                selectedOption(option)
                                    ? "button.primary.fg"
                                    : "button.action.fg"
                            }
                            background={
                                selectedOption(option)
                                    ? "button.primary.bg"
                                    : "button.action.bg"
                            }
                            _hover={
                                selectedOption(option)
                                    ? {
                                          background: "button.primary.bg-hover",
                                      }
                                    : {
                                          background: "button.action.bg-hover",
                                      }
                            }
                            onClick={() => {
                                const newDate =
                                    date && isValidDate(date)
                                        ? date
                                        : new Date();
                                onDateChange(
                                    new Date(
                                        `${format(
                                            newDate,
                                            "yyyy-MM-dd"
                                        )}T${option}`
                                    )
                                );
                            }}
                        >
                            {option}
                        </Button>
                    ))}
                </VStack>
            )}
        </Flex>
    );
};
