import {
    MetaQuery,
    useApiUrl,
    useCustomMutation,
    useResource,
    useTranslate,
} from "@refinedev/core";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export type FileImportOptions = {
    /**
     * Resource name for API data interactions.
     * @default Resource name that it reads from route
     */
    resource?: string;
    /**
     * Path on the resource to be used for the file upload
     * @default import
     */
    path?: string;
    /**
     * Custom body that is attached to the default body
     */
    customBody?: any;
    /**
     *  Metadata query for `dataProvider`
     */
    meta?: MetaQuery;
    /**
     * Called when the import handling was done
     */
    onFinish?: () => void;
};

export type HandleFileImportChangeType<TData> = (onChangeParams: {
    file: File;
}) => Promise<void>;

export type UseFileImportInputPropsType = {
    type: "file";
    accept: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export type UseFileImportReturnType = {
    inputProps: UseFileImportInputPropsType;
    isLoading: boolean;
    handleChange: HandleFileImportChangeType<void>;
};

/**
 * `useFileImport` is importing data by uploading a user provided CSV file to the server
 */
export const useFileImport = ({
    resource: resourceFromProps,
    path = "import",
    meta,
    customBody,
    onFinish,
}: FileImportOptions): UseFileImportReturnType => {
    const [isLoading, setIsLoading] = useState(false);
    const translate = useTranslate();
    const { resource } = useResource(resourceFromProps);
    const apiUrl = useApiUrl();
    const { mutate } = useCustomMutation();

    var endpoint: string;
    if (meta?.parent) {
        endpoint = `${apiUrl}/v1/${meta.parent}/${resource!.name}/${path}`;
    } else {
        endpoint = `${apiUrl}/v1/${resource!.name}/${path}`;
    }

    const convertToMultiPart = (file: File) => {
        const formData = new FormData();
        const id = uuidv4();
        const body = {
            id: id,
            name: file.name,
        };

        formData.append(id, file, file.name);
        formData.append("body", JSON.stringify({ ...body, ...customBody }));
        return formData;
    };

    const handleChange: HandleFileImportChangeType<void> = ({ file }) => {
        return new Promise<void>((resolve) => {
            setIsLoading(true);
            mutate(
                {
                    url: endpoint,
                    method: "post",
                    values: convertToMultiPart(file),
                    successNotification: (data, values) => {
                        return {
                            message: translate(`notifications.success`, "Successful"),
                            description:translate(`notifications.importSuccess`, "Successfully imported file"),
                            type: "success",
                        };
                    },
                },
                {
                    onSettled: () => {
                        resolve();
                    },
                }
            );
        }).then(() => {
            setIsLoading(false);
            onFinish?.();
        });
    };

    return {
        inputProps: {
            type: "file",
            accept: ".csv",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files && event.target.files.length > 0) {
                    handleChange({ file: event.target.files[0] });
                }
            },
        },
        isLoading,
        handleChange,
    };
};
