import { colorOnBackground } from "@bankingright-dashboard/utils";
import {
    Button,
    Center,
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    SimpleGrid,
    Textarea,
    color,
} from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";

import {
    FieldValues,
    Path,
    PathValue,
    UseControllerProps,
    useController,
} from "react-hook-form";

type ColorPickerFormControlProps<T extends FieldValues> =
    UseControllerProps<T> &
        FormControlProps & {
            label: string;
            helperText?: string;
            suggestedColors?: string[];
        };

export const ColorPickerFormControl = <T extends FieldValues>({
    control,
    name,
    label,
    helperText,
    suggestedColors,
    rules,
    isRequired = true,
    defaultValue,
    ...props
}: ColorPickerFormControlProps<T>) => {
    const translate = useTranslate();
    const colors = suggestedColors ?? [
        "#b50716",
        "#008364",
        "#2820be",
        "#ffffff",
        "#000000",
    ];
    const hexRegex = new RegExp("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$");
    const defaultColor = (defaultValue ?? colors[0]) as PathValue<T, Path<T>>;

    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: rules ?? {
            required: isRequired
                ? translate(
                      "validations.required",
                      { field: label },
                      `${label} is required`
                  )
                : undefined,
            pattern: {
                value: hexRegex,
                message: "Invalid color format. Please use hex format.",
            },
        },
        defaultValue: defaultColor,
    });

    const color = value ?? colors[0];

    return (
        <FormControl isInvalid={!!error} {...props}>
            <FormLabel>{label}</FormLabel>
            <Popover variant="picker">
                <PopoverTrigger>
                    <Button
                        aria-label={color}
                        background={color}
                        height="22px"
                        width="22px"
                        padding={0}
                        minWidth="unset"
                        borderRadius={3}
                        borderWidth="1px"
                    ></Button>
                </PopoverTrigger>
                <PopoverContent width="170px">
                    <PopoverArrow bg={color} />
                    <PopoverCloseButton
                        color={
                            hexRegex.test(color)
                                ? colorOnBackground(color, "white", "black")
                                : "black"
                        }
                    />
                    <PopoverHeader
                        height="100px"
                        backgroundColor={hexRegex.test(color) ? color : "white"}
                        borderTopLeftRadius={5}
                        borderTopRightRadius={5}
                        color="white"
                    >
                        <Center
                            textColor={
                                hexRegex.test(color)
                                    ? colorOnBackground(color, "white", "black")
                                    : "black"
                            }
                            height="100%"
                        >
                            {hexRegex.test(color) ? color : "Invalid color"}
                        </Center>
                    </PopoverHeader>
                    <PopoverBody>
                        <SimpleGrid columns={5} spacing={2}>
                            {colors.map((color, index) => (
                                <Button
                                    key={index}
                                    aria-label={color}
                                    background={color}
                                    height="22px"
                                    width="22px"
                                    padding={0}
                                    minWidth="unset"
                                    borderRadius={3}
                                    _hover={{ background: color }}
                                    onClick={() => {
                                        onChange(color);
                                    }}
                                    borderWidth="1px"
                                ></Button>
                            ))}
                        </SimpleGrid>
                        <Input
                            borderRadius={3}
                            marginTop={3}
                            placeholder="#000000"
                            size="sm"
                            value={color}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                        />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};
