import { FilterType, ResourceAction } from "@bankingright-dashboard/enums";
import { IDevice } from "@bankingright-dashboard/interfaces";
import {
    FilterConfiguration,
    ResourceList,
} from "@bankingright-dashboard/table";
import { convertToDate } from "@bankingright-dashboard/utils";
import { Text, VStack } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const PushNotificationList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IDevice>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: true,
            },
            {
                id: "date",
                header: "Date",
                accessorKey: "date",
                enableSorting: false,
                cell: function render({ getValue }) {
                    return (
                        <VStack align="flex-start">
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </VStack>
                    );
                },
            },
            {
                id: "user_id",
                header: "User ID",
                accessorKey: "userId",
                enableColumnFilter: true,
            },
            {
                id: "device_id",
                header: "Device ID",
                accessorKey: "deviceId",
                enableColumnFilter: true,
            },
            {
                id: "title",
                header: "Title",
                accessorKey: "title",
                enableColumnFilter: true,
            },
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            actions={[ResourceAction.show, ResourceAction.delete]}
            hasSelection={true}
        />
    );
};
