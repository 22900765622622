import { DayStatistic, DayStatisticsResponse, Statistics, StatisticsResponse } from "@bankingright-dashboard/interfaces";
import { LoadingIndicator, RefreshButton } from "@bankingright-dashboard/ui";
import { normalize } from "@bankingright-dashboard/utils";

import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    Spacer,
    Text,
} from "@chakra-ui/react";
import {
    useApiUrl,
    useCustom,
    useResource,
    useTranslate,
} from "@refinedev/core";
import { add, format, differenceInCalendarDays, isFuture } from "date-fns";
import React from "react";

export const StatisticsOverview: React.FC = () => {
    const translate = useTranslate();
    const { resource } = useResource();
    const apiUrl = useApiUrl();

    const dateFormatter = (date: number) => {
        return format(new Date(date), "dd/MMM");
    };

    const statisticsResponse = useCustom<StatisticsResponse>({
        url: `${apiUrl}/v1/${resource!.name}`,
        method: "get",
    });

    const dayStatisticsResponse = useCustom<DayStatisticsResponse>({
        url: `${apiUrl}/v1/${resource!.name}/day`,
        method: "get",
    });

    const graphData = dayStatisticsResponse.data?.data.results.map((day) => {
        
        return {
            day: +day.date,
            loginSuccess: day?.statistics.login.success.total ?? 0,
            loginFailed: day?.statistics.login.failed.total ?? 0,
        };
    });

    const refresh = () => {
        statisticsResponse.refetch();
        dayStatisticsResponse.refetch();
    };

    const statistics = statisticsResponse.data?.data;
    const loading =
        statisticsResponse.isLoading ||
        statisticsResponse.isFetching ||
        dayStatisticsResponse.isLoading ||
        dayStatisticsResponse.isFetching;

    return (
        <Card bg="defaults.bg-light">
            <CardHeader>
                <Flex>
                    <Heading size="lg">
                        {translate(
                            `${normalize(resource!.name)}.titles.overview`,
                            resource!.name
                        )}
                    </Heading>
                    <Spacer />
                    <RefreshButton onClick={refresh} />
                </Flex>
            </CardHeader>

            <CardBody>
                <Heading as="h2" size="lg" pb={5}>
                    Last Month
                </Heading>
                <Heading as="h5" size="sm">
                    Total successfull logins
                </Heading>
                <Text mt={2}>{statistics?.statistics.login.success.total}</Text>

                <Heading as="h5" size="sm" mt={4}>
                    Total successfull PIN logins
                </Heading>
                <Text mt={2}>{statistics?.statistics.login.success.pin}</Text>

                <Heading as="h5" size="sm" mt={4}>
                    Total successfull Biometrics logins
                </Heading>
                <Text mt={2}>{statistics?.statistics.login.success.biometric}</Text>

                <Heading as="h5" size="sm" mt={4}>
                    Total failed logins
                </Heading>
                <Text mt={2}>{statistics?.statistics.login.failed.total}</Text>

                <LineChart
                    width={500}
                    height={300}
                    data={graphData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="day"
                        tickFormatter={dateFormatter}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="loginSuccess"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                    />
                    <Line
                        type="monotone"
                        dataKey="loginFailed"
                        stroke="#82ca9d"
                    />
                </LineChart>
            </CardBody>

            <LoadingIndicator isLoading={loading} />
        </Card>
    );
};
