import { FilterType } from "@bankingright-dashboard/enums";
import { covertToOptionArray, notEmpty } from "@bankingright-dashboard/utils";
import {
    Box,
    BoxProps,
    Button,
    Flex,
    HStack,
    Spacer,
    VStack,
} from "@chakra-ui/react";
import { CrudFilter, LogicalFilter } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { DatePickerFormControl } from "./date-picker-form-control";
import { SelectFormControl } from "./select-form-control";
import { InputFormControl } from "./input-form-control";
import { FiChevronRight, FiX } from "react-icons/fi";

export interface FilterConfiguration {
    id: string;
    label: string;
    type: FilterType;
    options?: Array<string>;
    isRequired?: boolean;
    defaultValue?: string;
    passValueToShow?: boolean;
    hidden?: boolean;
}

interface ListFiltersProps extends BoxProps {
    configuration: Array<FilterConfiguration>;
    setFilters: (filters: Array<CrudFilter>) => void;
    filters?: Array<CrudFilter>;
}

export const ListFilters = ({
    configuration,
    setFilters,
    filters,
    ...props
}: ListFiltersProps) => {
    var defaultValues: Record<string, any> = {};
    configuration.forEach(
        (config) =>
            (defaultValues[config.id] =
                filters?.find((f) => (f as LogicalFilter).field === config.id)
                    ?.value ?? config.defaultValue)
    );

    const {
        control,
        getValues,
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({ defaultValues });

    const configurationToFilters = (config: Array<FilterConfiguration>) => {
        const values = getValues();
        return config
            .map((item) => {
                const value = values[item.id];
                if (value && value.length > 0) {
                    var filter: CrudFilter = {
                        field: item.id,
                        operator: "eq",
                        value: values[item.id],
                    };

                    return filter;
                }
            })
            .filter(notEmpty);
    };

    const onSubmit = () => {
        setFilters(configurationToFilters(configuration));
    };

    const onReset = () => {
        reset();
        setFilters(configurationToFilters(configuration));
    };

    return configuration.length > 0 ? (
        <Box w="100%" {...props}>
            <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
                <VStack align="stretch">
                    <Flex gap={4} wrap="wrap">
                        {configuration.map((config, index) => {
                            if (config.hidden) {
                                return <></>;
                            }

                            switch (config.type) {
                                case FilterType.select:
                                    return (
                                        <Box w="200px" key={index}>
                                            <SelectFormControl
                                                control={control}
                                                name={config.id}
                                                label={config.label}
                                                options={covertToOptionArray(
                                                    config.options ?? []
                                                )}
                                                isRequired={
                                                    config.isRequired ?? false
                                                }
                                            />
                                        </Box>
                                    );

                                case FilterType.input:
                                    return (
                                        <Box w="200px" key={index}>
                                            <InputFormControl
                                                name={config.id}
                                                label={config.label}
                                                control={control}
                                                isRequired={
                                                    config.isRequired ?? false
                                                }
                                            />
                                        </Box>
                                    );

                                case FilterType.date:
                                case FilterType.dateTime:
                                    return (
                                        <Box w="200px" key={index}>
                                            <DatePickerFormControl
                                                control={control}
                                                id={config.id}
                                                name={config.id}
                                                label={config.label}
                                                isRequired={
                                                    config.isRequired ?? false
                                                }
                                                showTime={
                                                    config.type ===
                                                    FilterType.dateTime
                                                }
                                            />
                                        </Box>
                                    );
                            }
                        })}
                    </Flex>
                    <HStack>
                        <Spacer />
                        <HStack gap={4}>
                            <Button
                                type="reset"
                                variant="link"
                                color="action.interactive"
                                leftIcon={<FiX size={18} />}
                            >
                                Clear all
                            </Button>
                            <Button
                                type="submit"
                                variant="tertiary"
                                rightIcon={<FiChevronRight size={18} />}
                            >
                                Apply
                            </Button>
                        </HStack>
                    </HStack>
                </VStack>
            </form>
        </Box>
    ) : (
        <></>
    );
};
