import {
    AccessControlContext,
    MetaQuery,
    useCan,
    useDelete,
    useDeleteMany,
    useMutationMode,
    useResource,
    useTranslate,
    useUserFriendlyName,
} from "@refinedev/core";
import React, { useContext, useState } from "react";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    ButtonProps,
    IconButton,
    useDisclosure,
} from "@chakra-ui/react";
import { UilTrash } from "@iconscout/react-unicons";

export interface DeleteButtonProps extends ButtonProps {
    icon?: React.ReactElement;
    hideText?: boolean;
    recordItemId: Array<number | string> | number | string;
    resourceNameOrRouteName?: string;
    accessControl?: {
        enabled?: boolean;
        hideIfUnauthorized?: boolean;
    };
    confirmTitle?: string;
    confirmBody?: string;
    confirmCancelText?: string;
    confirmOkText?: string;
    meta?: MetaQuery;
    onSuccess?: () => void;
}

export const DeleteButton = ({
    icon = <UilTrash size="18px" />,
    hideText = false,
    recordItemId,
    resourceNameOrRouteName,
    accessControl,
    confirmTitle,
    confirmBody,
    confirmCancelText,
    confirmOkText,
    meta,
    onSuccess,
    children,
    ...props
}: DeleteButtonProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef(null);

    const accessControlContext = useContext(AccessControlContext);

    const accessControlEnabled =
        accessControl?.enabled ??
        accessControlContext.options.buttons.enableAccessControl;

    const hideIfUnauthorized =
        accessControl?.hideIfUnauthorized ??
        accessControlContext.options.buttons.hideIfUnauthorized;

    const getFriendlyName = useUserFriendlyName();
    const { id, resource } = useResource(resourceNameOrRouteName);
    const resourceId = id ?? recordItemId;

    const translate = useTranslate();

    const { mutationMode: mutationModeContext } = useMutationMode();

    const mutationMode = mutationModeContext;

    const { mutate, isLoading } = useDelete();
    const { mutate: mutateBulk, isLoading: isLoadingBulk } = useDeleteMany();

    const { data } = useCan({
        resource: resource?.name,
        action: "delete",
        params: { resourceId, resource },
        queryOptions: {
            enabled: accessControlEnabled,
        },
    });

    const [opened, setOpened] = useState(false);

    const isBulkDelete = Array.isArray(resourceId);

    const onConfirm = () => {
        setOpened(false);
        if (isBulkDelete) {
            mutateBulk(
                {
                    ids: resourceId,
                    resource: resource!.name,
                    meta: meta,
                    mutationMode,
                },
                {
                    onSuccess: () => {
                        onClose();
                        if (onSuccess) {
                            onSuccess();
                        }
                    },
                }
            );
            return;
        } else {
            mutate(
                {
                    id: resourceId!,
                    resource: resource!.name,
                    meta: meta,
                    mutationMode,
                },
                {
                    onSuccess: () => {
                        onClose();
                        if (onSuccess) {
                            onSuccess();
                        }
                    },
                }
            );
        }
    };

    if (
        !resource?.meta?.canDelete ||
        (accessControlEnabled && hideIfUnauthorized && !data?.can)
    ) {
        return null;
    }

    const onDelete = (event: any) => {
        event.stopPropagation();
        onOpen();
    };

    return (
        <>
            {hideText ? (
                <IconButton
                    icon={icon}
                    variant="destructive"
                    aria-label={translate("buttons.edit", "Edit")}
                    onClick={onDelete}
                    isDisabled={
                        isLoading || isLoadingBulk || data?.can === false
                    }
                    isLoading={isLoading || isLoadingBulk}
                    {...props}
                ></IconButton>
            ) : (
                <Button
                    variant="destructive"
                    onClick={onDelete}
                    isDisabled={isLoading || data?.can === false}
                    isLoading={isLoading || isLoadingBulk}
                    leftIcon={icon}
                    {...props}
                >
                    {children ?? translate("buttons.delete", "Delete")}
                </Button>
            )}

            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>
                        {confirmBody ?? translate("buttons.delete", "Delete")}{isBulkDelete ? ` ${(recordItemId as Array<string | number>).length} ` : " "}
                        {translate(
                            isBulkDelete ? `${resource?.name}.titles.list` : `${resource?.name}.${resource?.name}`,
                            getFriendlyName(resource!.name, isBulkDelete ? "plural" : "singular")
                        )}
                        ?
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {confirmBody ??
                            translate("buttons.confirm", "Are you sure?")}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            {confirmCancelText ??
                                translate("buttons.cancel", "Cancel")}
                        </Button>
                        <Button
                            colorScheme="red"
                            ml={3}
                            onClick={onConfirm}
                            isLoading={isLoading || isLoadingBulk}
                        >
                            {confirmOkText ??
                                translate("buttons.delete", "Delete")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};
