import {
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Icon,
} from "@chakra-ui/react";
import { Props } from "chakra-react-select";
import { FileBox } from "@bankingright-dashboard/ui";
import { Attachment } from "@bankingright-dashboard/interfaces";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
    FieldValues,
    useController,
    UseControllerProps,
} from "react-hook-form";
import { FiHome } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import { useTranslate } from "@refinedev/core";

type DropzoneFormControlProps<T extends FieldValues> = UseControllerProps<T> &
    Props & {
        label: string;
        helperText?: string;
        multiple?: boolean;
    };

export interface AttachmentWithFile extends Attachment {
    file?: File;
}

function convertToAttachment(input: File): AttachmentWithFile {
    return {
        id: uuidv4(),
        filename: input.name,
        mimeType: input.type,
        extension: input.name.split(".").pop() ?? "",
        file: input,
    };
}

export const DropzoneFormControl = <T extends FieldValues>({
    control,
    name,
    id,
    label,
    rules,
    helperText,
    isRequired = false,
    multiple = true,
    ...props
}: DropzoneFormControlProps<T>) => {

    const translate = useTranslate();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required: isRequired ? translate("validations.required", { field: label }, `${label} is required`) : undefined },
    });

    const onDrop = useCallback((acceptedFiles: any) => {
        onChange(
            multiple
                ? acceptedFiles.map((val: File) => convertToAttachment(val))
                : convertToAttachment(acceptedFiles[0])
        );
    }, []);

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
        useDropzone({
            multiple: multiple,
            onDrop,
        });

    const dropText = isDragActive
        ? multiple ? "Drop the files here ..." : "Drop the file here ..."
        : multiple ? "Drag 'n' drop files here, or click to select files" : "Drag 'n' drop file here, or click to select file";

    const activeBg = "gray.100";
    const borderColor = isDragActive ? "teal.300" : "gray.300";

    const renderFiles = (files: AttachmentWithFile[] | AttachmentWithFile) => {
        return (
            <Flex align="stretch" direction="column" mt={3}>
                {multiple &&
                    Array.isArray(files) &&
                    files?.map((item: AttachmentWithFile, index: number) => (
                        <FileBox
                            key={index}
                            filename={item.filename}
                            url={item.url}
                            size={item.size ?? item.file?.size}
                            onDelete={() => {
                                var attachments = value.filter(
                                    (attachment: AttachmentWithFile) =>
                                        attachment != item
                                );
                                onChange(attachments);
                            }}
                        />
                    ))}
                {!multiple && files && (
                    <FileBox
                    filename={(files as AttachmentWithFile).filename}
                    url={(files as AttachmentWithFile).url}
                    size={(files as AttachmentWithFile).size ?? (files as AttachmentWithFile).file?.size}
                        onDelete={() => {
                            onChange(null);
                        }}
                    />
                )}
            </Flex>
        );
    };

    return (
        <FormControl mb={4} isInvalid={!!error} id={id}>
            <FormLabel>{label}</FormLabel>

            <Center
                p={10}
                cursor="pointer"
                bg={isDragActive ? activeBg : "transparent"}
                _hover={{ bg: activeBg }}
                transition="background-color 0.2s ease"
                borderRadius={4}
                border="3px dashed"
                borderColor={borderColor}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <Icon as={FiHome} mr={2} />
                <p>{dropText}</p>
            </Center>
            {renderFiles(value)}

            {helperText && !error && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
            {error?.message && (
                <FormErrorMessage>{error?.message}</FormErrorMessage>
            )}
        </FormControl>
    );
};
