import {
    WealthForecastConfiguration,
    WealthForecastConfigurationResponse,
} from "@bankingright-dashboard/interfaces";
import { LoadingIndicator, RefreshButton } from "@bankingright-dashboard/ui";
import { normalize } from "@bankingright-dashboard/utils";
import {
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    Spacer,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react";
import {
    useApiUrl,
    useCustom,
    useCustomMutation,
    useResource,
    useTranslate,
} from "@refinedev/core";
import React, { useState } from "react";
import { WealthForecastConfigurationForm } from "./form";

const fieldWidth = 40;

export const WealthForecastConfigurationOverview: React.FC = () => {
    const translate = useTranslate();
    const { resource } = useResource();
    const apiUrl = useApiUrl();
    const url = `${apiUrl}/v1/${resource?.meta?.parent}/${resource!.name}`;
    const [lastUpdate, setLastUpdate] = useState(new Date());

    const wealthForecast = useCustom<WealthForecastConfigurationResponse>({
        url: url,
        method: "get",
    });
    const { mutate, isLoading } =
        useCustomMutation<WealthForecastConfigurationResponse>();

    const refresh = async () => {
        await wealthForecast.refetch();
        setLastUpdate(new Date());
    };

    const showLoading =
        wealthForecast.isLoading || wealthForecast.isFetching || isLoading;

    const onUpdate = (
        data: Array<WealthForecastConfiguration>,
        term: "short" | "long"
    ) => {
        // Make sure that all scenario values are numbers
        const cleanData = data.map((item) => {
            const scenarios = item.scenarios.map((scenario) => {
                return {
                    ...scenario,
                    value: Number(scenario.value),
                };
            });

            return {
                ...item,
                scenarios,
            };
        });

        mutate(
            {
                url: url,
                method: "put",
                values: {
                    shortTerm: term === "short" ? cleanData : undefined,
                    longTerm: term === "long" ? cleanData : undefined,
                },
            },
            {
                onSuccess: () => {
                    refresh();
                },
            }
        );
    };

    return (
        <Card bg="defaults.bg-light">
            <CardHeader>
                <Flex>
                    <Heading variant="title-bold">
                        {translate(
                            `${normalize(resource!.name)}.titles.overview`,
                            resource!.name
                        )}
                    </Heading>
                    <Spacer />
                    <RefreshButton onClick={refresh} />
                </Flex>
            </CardHeader>

            <CardBody>
                <Tabs>
                    <TabList>
                        <Tab>Short Term</Tab>
                        <Tab>Long Term</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {wealthForecast.isFetched && (
                                <WealthForecastConfigurationForm
                                    data={wealthForecast.data?.data.shortTerm!}
                                    term="short"
                                    fieldWidth={fieldWidth}
                                    onUpdate={onUpdate}
                                    isLoading={showLoading}
                                    lastUpdate={lastUpdate}
                                />
                            )}
                        </TabPanel>
                        <TabPanel>
                            {wealthForecast.isFetched && (
                                <WealthForecastConfigurationForm
                                    data={wealthForecast.data?.data.longTerm!}
                                    term="long"
                                    fieldWidth={fieldWidth}
                                    onUpdate={onUpdate}
                                    isLoading={showLoading}
                                    lastUpdate={lastUpdate}
                                />
                            )}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </CardBody>

            <LoadingIndicator isLoading={showLoading} />
        </Card>
    );
};
