import { FormType, SignItemType } from "@bankingright-dashboard/enums";
import {
    IPushNotification,
    ISigningTaskApprovalRequest,
    SignItem,
} from "@bankingright-dashboard/interfaces";
import {
    AttachmentWithFile,
    CreatableSelectFormControl,
    DatePickerFormControl,
    DropzoneFormControl,
    MarkdownEditorFormControl,
    InputFormControl,
} from "@bankingright-dashboard/table";
import { Create, Edit } from "@bankingright-dashboard/ui";
import { convertToUnixTimestamp } from "@bankingright-dashboard/utils";
import { Checkbox, FormControl, FormLabel, VStack } from "@chakra-ui/react";
import { useCreate } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";

interface SigningTaskApprovalRequestFormProps {
    type: FormType;
}

export const SigningTaskApprovalRequestForm = ({
    type,
}: SigningTaskApprovalRequestFormProps) => {
    const {
        getValues,
        control,
        refineCore: { formLoading, queryResult, onFinish },
        saveButtonProps,
        register,
        formState: { errors },
        resetField,
        handleSubmit,
    } = useForm<ISigningTaskApprovalRequest>();

    const { mutate } = useCreate<ISigningTaskApprovalRequest>();

    // Override the default save operation
    saveButtonProps.onClick = (e: any) => {
        e.preventDefault();

        handleSubmit(
            () => {
                const values = getValues();

                const signItem: SignItem[] = [
                    {
                        type: SignItemType.task,
                        title: values.title,
                        body: values.body,
                        isCreator: false,
                        numberOfApprovalsRequired: 1,
                    },
                ];

                const approvalRequest: ISigningTaskApprovalRequest = {
                    userId: values.userId,
                    expiresAt: values.expiresAt,
                    signItems: signItem,
                };

                onFinish(approvalRequest).catch((e) => {
                    // don't do anything
                });
            },
            () => false
        )(e);

        // Then check if a push notification should be send along with it
        if (getValues("schedulePushNotification")) {

            const customData = {
                type: "signingtask",
            };

            const notification: IPushNotification = {
                userId: getValues("userId"),
                title: "You have a new task to approve!",
                body: getValues("title"),
                date: convertToUnixTimestamp(new Date()),
                customData: JSON.stringify(customData),
                immediate: true
            };            

            mutate({
                resource: "pushnotifications",
                values: notification,
            });
        }
    };

    const form = () => {
        return (
            <>
                <InputFormControl
                    control={control}
                    id="userId"
                    name="userId"
                    label="User ID"
                />
                <DatePickerFormControl
                    control={control}
                    id="expiresAt"
                    name="expiresAt"
                    label="Expires at"
                    isRequired={true}
                    showTime={true}
                />
                <InputFormControl
                    name={"title"}
                    label={"Title"}
                    control={control}
                />
                <InputFormControl
                    control={control}
                    id="body"
                    name="body"
                    label="Body"
                />
                <FormControl mt={5} isInvalid={!!errors?.status}>
                    <FormLabel>Options:</FormLabel>
                    <VStack align="flex-start">
                        <Checkbox
                            id="schedulePushNotification"
                            {...register("schedulePushNotification")}
                        >
                            Notify by Push Notification
                        </Checkbox>
                    </VStack>
                </FormControl>
            </>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
    }
};
