import { BaseRecord, importCSVMapper } from "@refinedev/core";
import { MapDataFn } from "@refinedev/core/dist/hooks/export/types";
import { ParseConfig, parse } from "papaparse";
import { useState } from "react";

export type ManualImportOptions<
    TItem,
    TVariables = any,
    TData extends BaseRecord = BaseRecord
> = {
    /**
     * Resource name for API data interactions.
     * @default Resource name that it reads from route
     */
    resource?: string;
    /**
     * A mapping function that runs for every record. Mapped data will be included in the file contents.
     */
    mapData?: MapDataFn<TItem, TVariables>;
    /**
     * Custom Papa Parse options.
     * @type [`ParseConfig`](https://www.papaparse.com/docs)
     */
    paparseOptions?: ParseConfig;

    /**
     * Called when the CSV import was done
     */
    onImport?: (results: TItem[]) => Promise<void>;
    
    /**
     * Called when the import handling was done
     */
    onFinish?: () => void;
};

export type HandleManualImportChangeType<TData> = (onChangeParams: {
    file: Partial<File>;
}) => Promise<TData[]>;

export type UseManualImportInputPropsType = {
    type: "file";
    accept: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export type UseManualImportReturnType<TData extends BaseRecord = BaseRecord> = {
    inputProps: UseManualImportInputPropsType;
    isLoading: boolean;
    handleChange: HandleManualImportChangeType<TData>;
};

/**
 * `useManualImport` is a hook based on the refine `useManaual` but adjusted to manually 
 *  indicate what should happen after the csv import
 *
 * @see {@link https://refine.dev/docs/core/hooks/import-export/useImport} for more details.
 *
 * @typeParam TItem - Interface of parsed csv data
 * @typeParam TData - Result data of the query extends {@link https://refine.dev/docs/api-references/interfaceReferences#baserecord `BaseRecord`}
 * @typeParam TVariables - Values for mutation function
 *
 */
export const useManualImport = <
    TItem = any,
    TData extends BaseRecord = BaseRecord,
    TVariables = any
>({
    resource: resourceFromProps,
    mapData = (item) => item as unknown as TVariables,
    paparseOptions,
    onImport,
    onFinish
}: ManualImportOptions<TItem> = {}): UseManualImportReturnType<TData> => {
    const [isLoading, setIsLoading] = useState(false);
    const handleChange: HandleManualImportChangeType<TData> = ({ file }) => {
        return new Promise<TData[]>((resolve) => {
            setIsLoading(true);
            parse(file as any, {
                complete: async ({ data }: { data: unknown[][] }) => {
                    const values = importCSVMapper(data, mapData);
                    await onImport?.(values);
                    resolve(values);
                },

                ...paparseOptions,
            });
        }).then((values) => {
            setIsLoading(false);
            onFinish?.()
            return values;
        });
    };

    return {
        inputProps: {
            type: "file",
            accept: ".csv",
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files && event.target.files.length > 0) {
                    handleChange({ file: event.target.files[0] });
                }
            },
        },
        isLoading,
        handleChange,
    };
};
