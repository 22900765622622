import { ContentTriggerUser } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const ContentTriggerUserList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<ContentTriggerUser>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: true,
            },
            {
                id: "didDismiss",
                header: "Did Dismiss",
                accessorKey: "didDismiss",
                enableColumnFilter: false,
            },
            {
                id: "didTap",
                header: "Did Tap",
                accessorKey: "didTap",
                enableColumnFilter: false,
            },
            {
                id: "showCount",
                header: "Show Count",
                accessorKey: "showCount",
                enableColumnFilter: false,
            },
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            resourceName="contenttriggers-users"
            refineCoreProps={{
                resource: "contenttriggers-users",
            }}
            hasBackButton={true}
        />
    );
};
