import { Box, Heading, Spacer, Text, VStack } from "@chakra-ui/react";
import { useShow } from "@refinedev/core";
import dompurify from "dompurify";

import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { MessageBodyType } from "@bankingright-dashboard/enums";
import {
    IMessage,
    ISigningTaskApprovalRequest,
} from "@bankingright-dashboard/interfaces";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { convertToDate } from "@bankingright-dashboard/utils";
import { DetailItem, Show } from "@bankingright-dashboard/ui";

export const SigningTaskApprovalRequestShow: React.FC = () => {
    const { queryResult } = useShow<ISigningTaskApprovalRequest>();
    const { data, isLoading } = queryResult;
    const approvalRequest = data?.data;

    return (
        <Show isLoading={isLoading}>
            <VStack align="flex-start" spacing={3}>
                <DetailItem title="Id" value={approvalRequest?.id} />
                <DetailItem title="userId" value={approvalRequest?.userId} />

                <VStack align="flex-start">
                    <Heading as="h5" size="sm">
                        Created at
                    </Heading>
                    <Text>
                        {approvalRequest?.date &&
                            convertToDate(
                                approvalRequest?.date
                            ).toLocaleDateString()}{" "}
                        {approvalRequest?.date &&
                            convertToDate(
                                approvalRequest?.date
                            ).toLocaleTimeString()}
                    </Text>
                </VStack>

                <VStack align="flex-start">
                    <Heading as="h5" size="sm">
                        Expires at
                    </Heading>
                    <Text>
                        {approvalRequest?.expiresAt && convertToDate(
                            approvalRequest?.expiresAt
                        ).toLocaleDateString()}{" "}
                        {approvalRequest?.expiresAt && convertToDate(
                            approvalRequest?.expiresAt
                        ).toLocaleTimeString()}
                    </Text>
                </VStack>
                <DetailItem title="Status" value={approvalRequest?.status} />                
            </VStack>
        </Show>
    );
};
