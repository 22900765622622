import { normalize } from "@bankingright-dashboard/utils";
import { Box, Card, CardBody, CardFooter, CardHeader, Flex, Heading, HStack, Spacer } from "@chakra-ui/react";
import {
    useTranslate,
    useRouterContext,
    ResourceRouterParams,
    useResource,
    userFriendlyResourceName,
} from "@refinedev/core";
import { BackButton } from "./back-button";
import { LoadingIndicator } from "./loading-indicator";
import { SaveButton, SaveButtonProps } from "./save-button";

interface CreateProps {
    isLoading: boolean
    saveButtonProps: SaveButtonProps
    children: any
}

export const Create: React.FC<CreateProps> = ({ isLoading, saveButtonProps, children }) => {

    const translate = useTranslate();
    const { resource } = useResource();

    return (
        <Card bg="defaults.bg-light">
            <CardHeader>
                <HStack spacing={2}>
                    <BackButton />
                    <Heading size="lg">
                        {translate(
                            `${normalize(resource!.name)}.titles.create`,
                            `Create ${userFriendlyResourceName(resource?.name, "singular",)}`
                        )}
                    </Heading>
                </HStack>
            </CardHeader>
            <CardBody>
                <Box opacity={isLoading ? 0.5 : undefined}>
                    {children}
                </Box>
                <LoadingIndicator isLoading={isLoading} />
            </CardBody>
            <CardFooter justify="flex-end">
                <SaveButton {...saveButtonProps}/>
            </CardFooter>
        </Card>
    )
};