import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import {
    AccessControlContext,
    useCan,
    useLink,
    useNavigation,
    useResource,
    useTranslate,
} from "@refinedev/core";
import React, { useContext } from "react";
import { FiPlus } from "react-icons/fi";

interface CreateButtonProps extends ButtonProps {
    icon?: React.ReactElement;
    hideText?: boolean;
    resourceNameOrRouteName?: string;
    accessControl?: {
        enabled?: boolean;
        hideIfUnauthorized?: boolean;
    };
}

export const CreateButton = ({
    icon = <FiPlus size="18px" />,
    hideText = false,
    resourceNameOrRouteName,
    accessControl,
    children,
    ...props
}: CreateButtonProps) => {
    const accessControlContext = useContext(AccessControlContext);

    const accessControlEnabled =
        accessControl?.enabled ??
        accessControlContext.options.buttons.enableAccessControl;

    const hideIfUnauthorized =
        accessControl?.hideIfUnauthorized ??
        accessControlContext.options.buttons.hideIfUnauthorized;

    const { resource } = useResource(resourceNameOrRouteName);

    const Link = useLink();
    const { createUrl: generateCreateUrl } = useNavigation();

    const translate = useTranslate();

    const { data } = useCan({
        resource: resource?.name,
        action: "create",
        queryOptions: {
            enabled: accessControlEnabled,
        },
        params: {
            resource,
        },
    });

    const disabledTitle = () => {
        if (data?.can) return "";
        else if (data?.reason) return data.reason;
        else
            return translate(
                "buttons.notAccessTitle",
                "You don't have permission to access"
            );
    };

    const createUrl = generateCreateUrl(resource!.name);

    if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
        return null;
    }

    return (
        <Link to={createUrl} replace={false}>
            {hideText ? (
                <IconButton
                    variant="primary"
                    aria-label={translate("buttons.create", "Create")}
                    title={disabledTitle()}
                    isDisabled={data?.can === false}
                    icon={icon}
                    {...props}
                />
            ) : (
                <Button
                    variant="primary"
                    isDisabled={data?.can === false}
                    leftIcon={icon}
                    title={disabledTitle()}
                    {...props}
                >
                    {children ?? translate("buttons.create", "Create")}
                </Button>
            )}
        </Link>
    );
};
