import { useMsal } from "@azure/msal-react";
import {
    BankingRightAuthProvider,
    BankingRightDataProvider,
    BankingRightAccessControlProvider,
} from "@bankingright-dashboard/providers";
import { notificationProvider } from "@bankingright-dashboard/ui";
import { bankingRightTheme as theme } from "@bankingright-dashboard/theme";
import { ChakraProvider } from "@chakra-ui/react";
import { I18nProvider, Refine } from "@refinedev/core";
import routerProvider from "@refinedev/react-router-v6";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { loginRequest } from "./msalConfig";
import { ShowcaseResources } from "./resources";
import { ShowcaseRoutes } from "./routes";
import axios from "axios";

const App: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { inProgress, instance } = useMsal();

    const i18nProvider: I18nProvider = {
        translate: (key: string, defaultMessage?: string) => {
            return t(key, defaultMessage ?? key);
        },
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const axiosInstance = axios.create();
    const apiUrl = process.env.REACT_APP_API_URL! + process.env.REACT_APP_API_BASE_PATH!;    

    const authProvider = BankingRightAuthProvider({
        tokenRequest: loginRequest,
        instance: instance,
    });

    const dataProvider = BankingRightDataProvider({
        apiUrl: apiUrl,
        httpClient: axiosInstance,
        authProvider: authProvider,
    });

    const accessControlProvider = BankingRightAccessControlProvider({
        apiUrl: apiUrl,
        httpClient: axiosInstance,
    });
    const accessControlEnabled =
        (process.env.REACT_APP_ACCESS_CONTROL_ENABLED ?? "true") === "true";

    if (inProgress === "login" || inProgress === "handleRedirect") {
        return <div>Loading...</div>;
    }

    return (
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <Refine
                    notificationProvider={notificationProvider}
                    routerProvider={{
                        ...routerProvider,
                    }}
                    dataProvider={dataProvider}
                    authProvider={authProvider}
                    i18nProvider={i18nProvider}
                    resources={ShowcaseResources}
                    accessControlProvider={
                        accessControlEnabled ? accessControlProvider : undefined
                    }
                    options={{
                        disableTelemetry: true,
                    }}
                >
                    <ShowcaseRoutes />
                </Refine>
            </BrowserRouter>
        </ChakraProvider>
    );
};

if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    const originalWarn = console.error;
    // eslint-disable-next-line no-console
    console.error = (...args) => {
        if (
            args && typeof args[0] === 'string' && args[0]?.includes(
                "Support for defaultProps will be removed from function components in a future major release."
            )
        ) {
            return;
        }
        originalWarn(...args);
    };
}

export default App;
