import { Option, Image } from "@bankingright-dashboard/interfaces";

export function covertToOptionArray(input: string[]): Option[] {
    return input.map((value) => {
        return { label: value, value: value };
    });
}

export function convertToDate(input: string): Date {
    if (!input || input === "") return new Date();
    return new Date(+input * 1000);
}

export function convertToUnixTimestamp(input: Date): string {
    return Math.floor(input.getTime() / 1000).toString();
}

export function isValidDate(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
}

export function capitalize(input: string): string {
    return input[0].toUpperCase() + input.slice(1).toLowerCase();
}

export function notEmpty<TValue>(
    value: TValue | null | undefined
): value is TValue {
    if (value === null || value === undefined) return false;
    const testDummy: TValue = value;
    return true;
}

export function normalize(value: string): string {
    return value?.replace("/", "");
}

export function formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
        "Bytes",
        "KiB",
        "MiB",
        "GiB",
        "TiB",
        "PiB",
        "EiB",
        "ZiB",
        "YiB",
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export type Valuable<T> = {
    [K in keyof T as T[K] extends null | undefined ? never : K]: T[K];
};

export function removeEmptyValues<
    // eslint-disable-next-line @typescript-eslint/ban-types
    T extends {},
    V = Valuable<T>
>(obj: T): V {
    return Object.fromEntries(
        Object.entries(obj).filter(
            ([, v]) =>
                !(
                    (typeof v === "string" && !v.length) ||
                    v === null ||
                    typeof v === "undefined"
                )
        )
    ) as V;
}

export const groupBy = <T, K extends keyof any>(
    list: T[],
    getKey: (item: T) => K
) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

export function colorOnBackground(
    bgColor: string,
    lightColor: string,
    darkColor: string
) {
    var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? darkColor : lightColor;
}

export const generateImageSubTitle = (image: Image) => {
    var subTitle = undefined;
    if (image.extension) {
        subTitle = `${image.extension.slice(1).toUpperCase()} - `;
    }
    if (image.imageDimensions) {
        subTitle =
            (subTitle ?? "") +
            `${image.imageDimensions.width}x${image.imageDimensions.height}`;
    }
    return subTitle;
};
