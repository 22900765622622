import {
    FormType,
    MessageBodyType,
    MessageStatus,
} from "@bankingright-dashboard/enums";
import {
    IMessage,
    IPushNotification,
    IUserSegment,
} from "@bankingright-dashboard/interfaces";
import {
    AttachmentWithFile,
    CreatableSelectFormControl,
    DatePickerFormControl,
    DropzoneFormControl,
    MarkdownEditorFormControl,
    InputFormControl,
    SelectFormControl,
    CreatableSelectType,
} from "@bankingright-dashboard/table";
import { Create, Edit } from "@bankingright-dashboard/ui";
import { covertToOptionArray } from "@bankingright-dashboard/utils";
import { Checkbox, FormControl, FormLabel, VStack } from "@chakra-ui/react";
import { useCreate, useSelect } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";

interface MessageFormProps {
    type: FormType;
}

type MessageWithAttachments = IMessage & {
    attachments: AttachmentWithFile[];
    schedulePushNotification: boolean;
};

export const MessageForm = ({ type }: MessageFormProps) => {
    const {
        getValues,
        control,
        refineCore: { formLoading, queryResult, onFinish },
        saveButtonProps,
        register,
        formState: { errors },
        resetField,
        handleSubmit,
    } = useForm<IMessage>();

    const { mutate } = useCreate<IPushNotification>();

    const convertToMultiPart = (message: MessageWithAttachments) => {
        const formData = new FormData();

        if (message?.attachments ?? 0 > 0) {
            message.attachments.forEach((attachment: AttachmentWithFile) => {
                if (!attachment.file) {
                    return;
                }
                formData.append(
                    attachment.id,
                    attachment.file,
                    attachment.filename
                );
            });
        }

        // remove the file from the attachments (to clean up the JSON)
        message.attachments = message.attachments?.map(
            (attachment: AttachmentWithFile) => {
                const { file, ...rest } = attachment;
                return { ...rest };
            }
        );

        // remove the schedulePushNotification from the message (to clean up the JSON)
        const { schedulePushNotification, ...body } = message;
        formData.append(
            "body",
            JSON.stringify({ ...body, bodyType: MessageBodyType.markdown })
        );

        return formData;
    };

    // Override the default save operation
    saveButtonProps.onClick = (e: any) => {
        e.preventDefault();

        // First convert the normal request to a multi-part and include attachments if provided (and submit it if valid)
        handleSubmit(
            () => {
                onFinish(
                    convertToMultiPart(getValues() as MessageWithAttachments)
                ).catch((e) => {
                    // don't do anything
                });
            },
            () => false
        )(e);

        // Then check if a push notification should be send along with it
        if (getValues("schedulePushNotification")) {
            mutate({
                resource: "pushnotifications",
                values: {
                    userId: getValues("userId"),
                    title: "You have a new message!",
                    body: getValues("title"),
                    date: getValues("date"),
                },
            });
        }
    };

    const segmentSelectProps = useSelect<IUserSegment>({
        resource: "segments",
        meta: {
            parent: "users",
        },
    });

    const form = () => {
        return (
            <VStack>
                {type == FormType.create && (
                    <>
                        <CreatableSelectFormControl
                            control={control}
                            id="userIds"
                            name="userIds"
                            label="User ID(s)"
                            placeholder="Keep empty to send to all users"
                            type={CreatableSelectType.array}
                        />
                        <SelectFormControl
                            control={control}
                            id="userSegments"
                            name="userSegments"
                            label="User Segments(s)"
                            isMulti={true}
                            placeholder="Keep empty to send to all users"
                            type={CreatableSelectType.array}
                            {...segmentSelectProps}
                        />
                    </>
                )}
                <SelectFormControl
                    control={control}
                    name="status"
                    label="Status"
                    options={covertToOptionArray(Object.values(MessageStatus))}
                    defaultValue={MessageStatus.active}
                    selectedOptionStyle="check"
                    isRequired={true}
                />
                <DatePickerFormControl
                    control={control}
                    id="date"
                    name="date"
                    label="Publish Date"
                    isRequired={false}
                    showTime={true}
                />
                <InputFormControl
                    name={"title"}
                    label={"Title"}
                    control={control}
                />
                <MarkdownEditorFormControl
                    control={control}
                    id="body"
                    name="body"
                    label="Body"
                />
                <InputFormControl
                    name={"sender"}
                    label={"Sender"}
                    control={control}
                />
                {type == FormType.create && (
                    <DropzoneFormControl
                        control={control}
                        id="attachments"
                        name="attachments"
                        label="Attachments"
                    />
                )}
                <FormControl mt={5} isInvalid={!!errors?.status}>
                    <FormLabel>Additional options:</FormLabel>
                    <VStack align="flex-start">
                        <Checkbox id="isRead" {...register("isRead")}>
                            Mark immediately as read
                        </Checkbox>
                        <Checkbox
                            id="schedulePushNotification"
                            {...register("schedulePushNotification")}
                        >
                            Notify User(s) by Push Notification
                        </Checkbox>
                    </VStack>
                </FormControl>
            </VStack>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
    }
};
