import { LoadingIndicator } from "@bankingright-dashboard/ui";
import {
    Box,
    Checkbox,
    Flex,
    SimpleGrid,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { BaseRecord } from "@refinedev/core";

import { flexRender, HeaderGroup, RowModel, Row } from "@tanstack/react-table";

interface GridListProps<T extends BaseRecord> {
    size?: "sm" | "md" | "lg";
    isFetching: boolean;
    isLoading: boolean;
    isError: boolean;
    pageSize: number;
    getHeaderGroups: () => HeaderGroup<T>[];
    getRowModel: () => RowModel<T>;
    showRow: (row: Row<T>, newPage: boolean) => void;
    hasShow: boolean;
    hasActions: boolean;
    hasSelection: boolean;
    minChildWidth?: string;
}

export const GridList = <T extends BaseRecord>({
    size,
    isFetching,
    isLoading,
    isError,
    pageSize,
    getHeaderGroups,
    getRowModel,
    showRow,
    hasShow,
    hasActions,
    hasSelection,
    minChildWidth = "350px",
}: GridListProps<T>) => {
    const skeletonArray = Array.from(Array(pageSize).keys());

    return (
        <SimpleGrid minChildWidth={minChildWidth} spacing={5}>
            {!isLoading &&
                getRowModel().rows.map((row) => {
                    const cells = row.getVisibleCells();
                    const cell = cells.at(hasSelection ? 1 : 0);
                    const actions = hasActions
                        ? cells.at(cells.length - 1)
                        : undefined;
                    const selection = hasSelection ? cells.at(0) : undefined;
                    if (cell) {
                        return (
                            <Box
                                position="relative"
                                key={row.id}
                                opacity={isFetching ? 0.5 : 1.0}
                            >
                                {actions && (
                                    <Box position="absolute" top={2} right={2}>
                                        {flexRender(
                                            actions.column.columnDef.cell,
                                            actions.getContext()
                                        )}
                                    </Box>
                                )}
                                {selection && (
                                    <Box position="absolute" top={2} left={2}>
                                        {flexRender(
                                            selection.column.columnDef.cell,
                                            selection.getContext()
                                        )}
                                    </Box>
                                )}
                                <Box
                                    onMouseUp={(event) => {
                                        if (event.button == 1) {
                                            showRow(row, true);
                                        }
                                    }}
                                    onClick={(event) => {
                                        if (hasShow && !event.shiftKey && !event.metaKey) {
                                            showRow(row, false);
                                        }
                                        else if (event.shiftKey || event.metaKey) {
                                            row.toggleSelected();
                                        }
                                    }}
                                    _hover={{
                                        background: hasShow
                                            ? "chakra-subtle-bg"
                                            : "unset",
                                        cursor: hasShow ? "pointer" : "unset",
                                    }}
                                    _active={{
                                        background: hasShow
                                            ? useColorModeValue(
                                                  "gray.200",
                                                  "gray.600"
                                              )
                                            : "unset",
                                    }}
                                >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </Box>
                            </Box>
                        );
                    } else {
                        return <></>;
                    }
                })}

            <LoadingIndicator isLoading={isFetching} />

            {isError && (
                <Flex minH={`${(pageSize ?? 10) * 50}px`} align="center">
                    <Text w="100%" textAlign="center" padding={5}>
                        Could not retrieve the data, there was an error!
                    </Text>
                </Flex>
            )}
        </SimpleGrid>
    );
};
