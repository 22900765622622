import { useForm } from "@refinedev/react-hook-form";
import {
    AttachmentWithFile,
    DropzoneFormControl,
    InputFormControl,
} from "@bankingright-dashboard/table";
import {
    FormType,
    PositionEnrichmentType,
} from "@bankingright-dashboard/enums";
import { IPositionEnrichment } from "@bankingright-dashboard/interfaces";
import { useCreate, useTranslate } from "@refinedev/core";
import { Create, Edit } from "@bankingright-dashboard/ui";
import {
    FormControl,
    FormLabel,
    Select,
    FormErrorMessage,
    Checkbox,
    VStack,
    Radio,
    RadioGroup,
    Stack,
    Box,
} from "@chakra-ui/react";
import React from "react";

interface PositionEnrichmentFormProps {
    type: FormType;
}

type PositionEnrichmenWithAttachment = IPositionEnrichment & {
    attachment: AttachmentWithFile;
};

const URL_REGEX = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
);

export const PositionEnrichmentForm = ({
    type,
}: PositionEnrichmentFormProps) => {
    const translate = useTranslate();

    const {
        getValues,
        control,
        refineCore: { formLoading, queryResult, onFinish },
        saveButtonProps,
        register,
        formState: { errors },
        resetField,
        handleSubmit,
        watch,
    } = useForm<IPositionEnrichment>();

    const enrichmentType = watch("type") ?? PositionEnrichmentType.url;
    const { mutate } = useCreate<IPositionEnrichment>();

    const convertToMultiPart = (
        enrichment: PositionEnrichmenWithAttachment
    ) => {
        const formData = new FormData();
        const attachment = enrichment.attachment;

        if (attachment && attachment.file) {
            formData.append(
                attachment.id,
                attachment.file,
                attachment.filename
            );
        }

        // remove the file from the attachments (to clean up the JSON)
        if (enrichment.attachment?.file) {
            delete enrichment.attachment.file;
        }

        formData.append("body", JSON.stringify(enrichment));

        return formData;
    };

    // Override the default save operation
    saveButtonProps.onClick = (e: any) => {
        e.preventDefault();

        // First convert the normal request to a multi-part and include attachments if provided (and submit it if valid)
        handleSubmit(
            () => {
                onFinish(
                    convertToMultiPart(
                        getValues() as PositionEnrichmenWithAttachment
                    )
                ).catch((e) => {
                    // don't do anything
                });
            },
            () => false
        )(e);
    };

    const form = () => {
        return (
            <VStack align="flex-start" spacing={3}>
                <InputFormControl name="isin" label="ISIN" control={control} />
                <FormControl isInvalid={!!errors?.status}>
                    <FormLabel>Type</FormLabel>
                    <RadioGroup id="type" value={enrichmentType}>
                        <Stack direction="row">
                            <Radio value="url" {...register("type")}>
                                URL
                            </Radio>
                            <Radio value="attachment" {...register("type")}>
                                Attachment
                            </Radio>
                        </Stack>
                    </RadioGroup>
                </FormControl>
                <InputFormControl
                    name="url"
                    label="URL"
                    control={control}
                    rules={{
                        pattern: {
                            value: URL_REGEX,
                            message: "Invalid URL",
                        },
                    }}
                    display={enrichmentType === "url" ? "contents" : "none"}
                    isRequired={enrichmentType === "url"}
                />
                <Box
                    display={
                        enrichmentType === "attachment" ? "contents" : "none"
                    }
                >
                    <DropzoneFormControl
                        control={control}
                        id="attachment"
                        name="attachment"
                        label="Attachment"
                        isRequired={enrichmentType === "attachment"}
                        multiple={false}
                    />
                </Box>
            </VStack>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
    }
};
