import {
    Checkbox,
    FormControl,
    FormControlProps,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Text,
    Textarea,
} from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";

import {
    FieldErrors,
    FieldError,
    FieldValues,
    UseFormRegister,
    FieldPath,
    ValidationRule,
    UseControllerProps,
    useController,
} from "react-hook-form";

type CheckboxControlProps<T extends FieldValues> = UseControllerProps<T> &
    FormControlProps & {
        label: string;
        helperText?: string;
        hideLabel?: boolean;
    };

export const CheckboxControl = <T extends FieldValues>({
    control,
    name,
    label,
    helperText,
    hideLabel = false,
    rules,
    isRequired = true,
    defaultValue,
    ...props
}: CheckboxControlProps<T>) => {
    const translate = useTranslate();
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: rules ?? {
            required: isRequired
                ? translate(
                      "validations.required",
                      { field: label },
                      `${label} is required`
                  )
                : undefined,
        },
        defaultValue,
    });

    return (
        <FormControl isInvalid={!!error} {...props}>
            {!hideLabel && <FormLabel>{label}</FormLabel>}
            <Checkbox
                name={name}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                isChecked={value}
            />
            {helperText && !error && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};
