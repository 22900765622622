import { FormType } from "@bankingright-dashboard/enums";

import { ContentTriggerForm } from "./form";

interface ContentTriggerCreateProps {
    suggestedColors?: string[];
    suggestedColorsDark?: string[];
}

export const ContentTriggerCreate = ({
    suggestedColors,
    suggestedColorsDark,
}: ContentTriggerCreateProps) => {
    return (
        <ContentTriggerForm
            type={FormType.create}
            suggestedColors={suggestedColors}
            suggestedColorsDark={suggestedColorsDark}
        />
    );
};
