import { normalize } from "@bankingright-dashboard/utils";
import {
    Box,
    Button,
    ButtonProps,
    Card,
    CardBody,
    CardHeader,
    Divider,
    Heading,
    HStack,
    IconButton,
    IconButtonProps,
    Menu,
    MenuButton,
    MenuList,
    Spacer,
    ThemingProps,
    VStack,
} from "@chakra-ui/react";
import {
    MetaQuery,
    useCan,
    useNavigation,
    useResource,
    useResourceParams,
    userFriendlyResourceName,
    useTranslate,
} from "@refinedev/core";
import { BackButton } from "./back-button";
import { DeleteButton } from "./delete-button";
import { EditButton } from "./edit-button";
import { ImportButton, ImportButtonProps } from "./import-button";
import { ListButton } from "./list-button";
import { LoadingIndicator } from "./loading-indicator";
import { RefreshButton } from "./refresh-button";
import { ExportButton, ExportButtonProps } from "./export-button";
import { FiMoreVertical } from "react-icons/fi";

export interface ButtonWithLabelProps
    extends ButtonProps,
        ThemingProps<"Button"> {
    label: string;
}

interface ShowProps {
    importButtonProps?: ImportButtonProps;
    exportButtonProps?: ExportButtonProps;
    additionalButtons?: ButtonWithLabelProps[];
    isLoading: boolean;
    meta?: MetaQuery;
    children: any;
}

export const Show: React.FC<ShowProps> = ({
    importButtonProps,
    exportButtonProps,
    additionalButtons,
    isLoading,
    meta,
    children,
}) => {
    const translate = useTranslate();
    const { id, resource } = useResourceParams();
    const { goBack, list } = useNavigation();

    const canImport = importButtonProps != undefined;
    const canExport = exportButtonProps != undefined;

    const numberOfButtons =
        [
            !!resource?.edit ?? false,
            resource?.meta?.canDelete ?? false,
            canImport,
            canExport,
        ].filter(Boolean).length + 2;

    const menuAttributes = {
        variant: "ghost",
        justifyContent: "flex-start",
        px: 2,
        py: 3,
        rounded: "none",
        size: "md",
    };

    const actionButtons = (inMenu: boolean = false) => {
        const menuAttributes = {
            variant: "ghost",
            justifyContent: "flex-start",
            px: 2,
            py: 3,
            rounded: "none",
            size: "md",
        };

        const actionButtons = [];
        if (id) {
            actionButtons.push(
                <ListButton
                    key="list"
                    {...(inMenu ? { ...menuAttributes } : {})}
                />
            );
        }
        if (additionalButtons) {
            actionButtons.push(
                ...additionalButtons.map((button, index) => (
                    <Button
                        key={`additional_button_${index}`}
                        {...button}
                        {...(inMenu ? { ...menuAttributes } : {})}
                    >
                        {button.label}
                    </Button>
                ))
            );
        }
        if (resource?.edit && id) {
            actionButtons.push(
                <EditButton
                    key="edit"
                    recordItemId={id}
                    {...(inMenu ? { ...menuAttributes } : {})}
                />
            );
        }
        if (canImport) {
            actionButtons.push(
                <ImportButton
                    key="import"
                    {...importButtonProps}
                    {...(inMenu ? { ...menuAttributes } : {})}
                />
            );
        }

        if (canExport) {
            actionButtons.push(
                <ExportButton
                    key="export"
                    {...exportButtonProps}
                    {...(inMenu ? { ...menuAttributes } : {})}
                />
            );
        }
        if (resource?.meta?.canDelete && id) {
            actionButtons.push(
                <DeleteButton
                    key="delete"
                    recordItemId={id}
                    color="button.destructive.fg"
                    onSuccess={() => list(resource!.name)}
                    meta={meta}
                    {...(inMenu ? { ...menuAttributes } : {})}
                />
            );
        }
        if (id) {
            actionButtons.push(
                <RefreshButton
                    key="refresh"
                    recordItemId={id}
                    meta={meta}
                    {...(inMenu ? { ...menuAttributes } : {})}
                />
            );
        }

        return actionButtons;
    };

    const renderMenu = () => {
        return (
            <Menu>
                <MenuButton
                    as={IconButton}
                    icon={<FiMoreVertical size={24} />}
                    variant="ghost"
                    onClick={(event) => event.stopPropagation()}
                />
                <MenuList minW="120px" py={0}>
                    <VStack align="stretch" spacing={0} divider={<Divider />}>
                        {actionButtons(true).map((button) => button)}
                    </VStack>
                </MenuList>
            </Menu>
        );
    };

    return (
        <Card bg="defaults.bg-light">
            <CardHeader>
                <HStack spacing={2}>
                    <BackButton />
                    <Heading size="lg">
                        {translate(
                            `${normalize(resource!.name)}.titles.show`,
                            `${userFriendlyResourceName(
                                resource!.name,
                                "singular"
                            )} Details`
                        )}
                    </Heading>
                    <Spacer />
                    <HStack
                        spacing={2}
                        rowGap={2}
                        wrap="wrap"
                        justifyContent="flex-end"
                        display={{
                            base: "none",
                            md: numberOfButtons <= 4 ? "flex" : "none",
                            lg: "flex",
                        }}
                    >
                        {actionButtons(false).map((button) => button)}
                    </HStack>
                    <Box
                        display={{
                            base: "flex",
                            md: numberOfButtons > 4 ? "flex" : "none",
                            lg: "none",
                        }}
                    >
                        {renderMenu()}
                    </Box>
                </HStack>
            </CardHeader>
            <CardBody>
                <Box opacity={isLoading ? 0.5 : undefined}>{children}</Box>
                <LoadingIndicator isLoading={isLoading} />
            </CardBody>
        </Card>
    );
};
