import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
} from "@chakra-ui/react";
import { Props, Select } from "chakra-react-select";
import {
    FieldValues,
    useController,
    UseControllerProps,
} from "react-hook-form";
import { CreatableSelectType } from "./creatable-select-form-control";

type SelectFormControlProps<T extends FieldValues> = UseControllerProps<T> &
    Props & {
        label: string;
        helperText?: string;
        type?: CreatableSelectType;
    };

export const SelectFormControl = <T extends FieldValues>({
    control,
    name,
    id,
    label,
    rules,
    helperText,
    options,
    defaultValue,
    isRequired = true,
    isMulti,
    type = CreatableSelectType.commaSeparated,
    ...props
}: SelectFormControlProps<T>) => {
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: rules ?? {
            required: isRequired ? `${label} is required` : undefined,
        },
        defaultValue,
    });

    if (!isMulti) {
        type = CreatableSelectType.commaSeparated
    }

    const determineValue = () => {
        switch (type) {
            case CreatableSelectType.array:
                return value?.length > 0
                    ? value?.map((val: any) =>
                          options?.find((option: any) => option?.value === val)
                      )
                    : value;
            case CreatableSelectType.commaSeparated:
                return value?.length > 0
                    ? options?.filter((val: any) =>
                          value?.split(",").includes(val.value)
                      )
                    : options?.find((val: any) => val.value === value);
        }
    };

    return (
        <FormControl isInvalid={!!error} id={id}>
            <FormLabel>{label}</FormLabel>
            <Select
                {...props}
                key={`${name}_${value}`}
                isMulti={isMulti}
                name={name}
                ref={ref}
                onChange={(val: any) => {
                    switch (type) {
                        case CreatableSelectType.array:
                            onChange(val.map((val: any) => val.value));
                            break;
                        case CreatableSelectType.commaSeparated:
                            val?.length > 0
                                ? onChange(
                                      String(val.map((val: any) => val.value))
                                  )
                                : onChange(val.value);
                    }
                }}
                onBlur={onBlur}
                value={determineValue()}
                options={options}
            />
            {helperText && !error && (
                <FormHelperText>{helperText}</FormHelperText>
            )}
            {error?.message && (
                <FormErrorMessage>{error?.message}</FormErrorMessage>
            )}
        </FormControl>
    );
};
