import {
    ResourceAction,
    ResourceListStyle,
} from "@bankingright-dashboard/enums";
import { Image } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { ImageCard } from "@bankingright-dashboard/ui";
import { convertToDate, generateImageSubTitle } from "@bankingright-dashboard/utils";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const ImageList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<Image>[]>(
        () => [
            {
                id: "directUrl",
                header: "Url",
                accessorKey: "directUrl",
                cell: function render({ row }) {
                    const filename = row.original.filename ?? "unknown";
                    const dateModified =
                        row.original.dateModified ?? row.original.dateCreated;

                    return (
                        <ImageCard
                            name={filename}
                            url={row.original.directUrl}
                            fallbackUrl="https://via.placeholder.com/300"
                            subTitle={generateImageSubTitle(row.original)}
                            date={convertToDate(dateModified)}
                        />
                    );
                },
            },
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: true,
            },
            {
                id: "filename",
                header: "Filename",
                accessorKey: "filename",
                enableColumnFilter: true,
            },
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            actions={[
                ResourceAction.edit,
                ResourceAction.delete,
                ResourceAction.show,
            ]}
            listStyle={ResourceListStyle.grid}
            hasSelection={true}
        />
    );
};
