import { Box, Button, HStack, IconButton, Select, Spacer, Text, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { usePagination } from "./use-pagination";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

type PaginationProps = {
    current: number;
    pageCount: number;
    pageSize: number;
    showPageSizeSelect?: boolean;
    setCurrent: (page: number) => void;
    setPageSize: (size: number) => void;
};

export const Pagination: FC<PaginationProps> = ({
    current,
    pageCount,
    pageSize,
    showPageSizeSelect = true,
    setCurrent,
    setPageSize,
}) => {
    const pagination = usePagination({
        current,
        pageCount,
    });

    return (
        <Flex w="100%" justify="space-between" wrap="wrap">
            {showPageSizeSelect && (
                <HStack spacing={3}>
                    <Text whiteSpace="nowrap">Page size:</Text>
                    <Select
                        defaultValue={pageSize}
                        onChange={(event) => setPageSize(+event.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </Select>
                </HStack>
            )}
            <HStack my="3" spacing="1">
                {pagination?.prev && (
                    <IconButton
                        aria-label="previous page"
                        onClick={() => setCurrent(current - 1)}
                        disabled={!pagination?.prev}
                        variant="action"
                    >
                        <FiChevronLeft size="18" />
                    </IconButton>
                )}

                {pagination?.items.map((page) => {
                    if (typeof page === "string")
                        return <span key={page}>...</span>;

                    return (
                        <Button
                            key={page}
                            onClick={() => setCurrent(page)}
                            variant={page === current ? "solid" : "outline"}
                        >
                            {page}
                        </Button>
                    );
                })}
                {pagination?.next && (
                    <IconButton
                        aria-label="next page"
                        onClick={() => setCurrent(current + 1)}
                        variant="action"
                    >
                        <FiChevronRight size="18" />
                    </IconButton>
                )}
            </HStack>
        </Flex>
    );
};
