import { ResourceAction } from "@bankingright-dashboard/enums";
import { IUserSegment } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const UserSegmentList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IUserSegment>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableColumnFilter: true,
            },
            {
                id: "title",
                header: "Title",
                accessorKey: "title",
                enableColumnFilter: true,
            },
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            actions={[
                ResourceAction.show,
                ResourceAction.edit,
                ResourceAction.delete,
            ]}
            hasSelection={true}
        />
    );
};
