import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import { MetaDataQuery, MetaQuery, useOne, useResource, useTranslate } from "@refinedev/core";
import React from "react";
import { FiRefreshCcw } from "react-icons/fi";

interface RefreshButtonProps extends ButtonProps {
    icon?: React.ReactElement;
    hideText?: boolean
    recordItemId?: number | string
    resourceNameOrRouteName?: string
    dataProviderName?: string
    meta?: MetaQuery
    onClick?: () => void
}

export const RefreshButton = ({
    icon = <FiRefreshCcw />,
    hideText = false,
    recordItemId,
    resourceNameOrRouteName,
    dataProviderName,
    meta,
    onClick,
    children,
    ...props
}: RefreshButtonProps) => {
    
    const { resource, id } = useResource(resourceNameOrRouteName)

    const translate = useTranslate();

    const { refetch, isFetching } = useOne({
        resource: resource!.name,
        id: id ?? "",
        queryOptions: {
            enabled: false,
        },
        meta: meta,
        liveMode: "off",
        dataProviderName,
    });

    const refresh = () => {
        if (onClick) {
            onClick()
        }
        else if (recordItemId) {
            refetch()
        } else {
            console.log("no record item id or onClick provided")
        }
    }

    return hideText ? (
        <IconButton
            icon={icon}
            variant="action"
            aria-label={translate("buttons.refresh", "Refresh")}
            onClick={refresh}
            isDisabled={isFetching}
            {...props}
        />
    ) : (
        <Button
            variant="action"
            leftIcon={icon}
            isLoading={isFetching}
            onClick={refresh}
            {...props}
        >
            {children ?? translate("buttons.refresh", "Refresh")}
        </Button>
    );
};
