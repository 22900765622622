import { AccountInfo, EventPayload, EventType, PublicClientApplication, SilentRequest } from "@azure/msal-browser";

interface BankingRightPublicClientApplicationProps {
    msalConfig: any,
    tokenRequest: any,
    tokenKey?: string
}

export const BankingRightPublicClientApplication = (
    { msalConfig, tokenRequest, tokenKey = "bankingright-auth" }: BankingRightPublicClientApplicationProps
): PublicClientApplication => {

    const msalInstance = new PublicClientApplication(msalConfig);

    msalInstance.addEventCallback(async (event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS) {
            const payload: EventPayload = event.payload;
            msalInstance.setActiveAccount(payload as AccountInfo);

            let account = msalInstance.getActiveAccount();

            const request: SilentRequest = {
                ...tokenRequest,
                account: account!,
            };
            try {
                // Silently acquires an access token which is then attached to a request for API access
                const response = await msalInstance.acquireTokenSilent(request);
                console.log("Fetching access token: success");
                console.log("Scopes", response.scopes);
                console.log("Token Type", response.tokenType);

                localStorage.setItem(tokenKey, response.accessToken);
            } catch (e) {
                msalInstance.acquireTokenPopup(request).then((response) => {
                    localStorage.setItem(tokenKey, response.accessToken);
                });
            }
        }
    });

    return msalInstance
};